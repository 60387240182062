// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input-container {
  position: relative;
}

.input-container input {
  width: 100%;
  padding-right: 30px;
}

.input-container .fa-eye, .input-container .fa-eye-slash {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  color: #000000;
}

.input-container .fa-eye.active, .input-container .fa-eye-slash.active {
  color: #000000;
}`, "",{"version":3,"sources":["webpack://./src/pages/user-settings/style.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;AACJ;;AAEE;EACE,WAAA;EACA,mBAAA;AACJ;;AAEE;EACE,kBAAA;EACA,WAAA;EACA,QAAA;EACA,2BAAA;EACA,eAAA;EACA,cAAA;AACJ;;AAEE;EACE,cAAA;AACJ","sourcesContent":[".input-container {\n    position: relative;\n  }\n  \n  .input-container input {\n    width: 100%;\n    padding-right: 30px; \n  }\n  \n  .input-container .fa-eye, .input-container .fa-eye-slash {\n    position: absolute;\n    right: 20px;\n    top: 50%;\n    transform: translateY(-50%);\n    cursor: pointer;\n    color: #000000;\n  }\n  \n  .input-container .fa-eye.active, .input-container .fa-eye-slash.active {\n    color: #000000;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
