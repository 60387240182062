import { useDispatch } from "react-redux";
import { adminAuthActions } from "../../store/admin-auth-slice";
import { useNavigate } from "react-router-dom";
import React from 'react'

const AdminApiErrors = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  if (
    typeof props.children === "string" &&
    props.children === "Unauthenticated"
  ) {
    dispatch(adminAuthActions.logoutAdmin());
    navigate("/admin-32423QeCFer/admin-login", { replace: true });
  }

  const errorData = JSON.parse(props.children);

  if (errorData.message && errorData.message === "Unauthenticated") {
    dispatch(adminAuthActions.logoutAdmin());
    navigate("/admin-32423QeCFer/admin-login", { replace: true });
  }
  let errorsArray = [];
  for (const error in errorData.errors) {
    errorsArray.push(
      <p className="error-msg" key={error}>
        {errorData.errors[error].toString()}
      </p>
    );
  }

  return <>{errorsArray}</>;
};

export default AdminApiErrors;
