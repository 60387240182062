import React from "react";
import "./error.scss";
import {Link} from "react-router-dom";

export const Error = () => {
    return (
        <>
            <div className="error-page flex flex-wrap w-100 items-center content-justify-center">
                <div className="container">
                    <div className="error-wrap">
                        <h1>404</h1>
                        <h2>Oops!</h2>
                        <p>We can't seem to find the page you are looking for.</p>
                        <Link to="/trip-plan" className="signup-btn">Go to homepage</Link>
                    </div>
                </div>
            </div>
        </>
    );
};
