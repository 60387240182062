import React, { useState, useEffect } from "react";
import useInput from "../../hooks/use-input";
import ApiErrors from "../../components/api-errors/ApiErrors";
import SpinnerButton from "../../components/ui/SpinnerButton";
import useHttp from "../../hooks/use-http";
import { changeUserPassword, validatePasswordFormat } from "../../lib/api";
import ModalWithInfo from "../../components/ui/ModalWithInfo";
import "./style.scss";

const UpdatePassword = ({ socialLogin }) => {
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showActionInfoModal, setShowActionInfoModal] = useState(false);

  const { sendRequest, status, error } = useHttp(changeUserPassword);

  const {
    value: enteredCurrentPassword,
    hasError: currentPasswordHasError,
    valueIsValid: currentPasswordIsValid,
    valueChangeHandler: currentPasswordChangeHandler,
    inputBlurHandler: currentPasswordBlurHandler,
    resetValue: resetCurrentPassword,
  } = useInput((value) => value.trim().length > 7);

  const {
    value: enteredNewPassword,
    hasError: newPasswordHasError,
    valueIsValid: newPasswordIsValid,
    valueChangeHandler: newPasswordChangeHandler,
    inputBlurHandler: newPasswordBlurHandler,
    resetValue: resetNewPassword,
  } = useInput((value) => validatePasswordFormat(value));

  const {
    value: enteredConfirmPassword,
    hasError: ConfirmPasswordHasError,
    valueIsValid: confirmPasswordIsValid,
    valueChangeHandler: confirmPasswordChangeHandler,
    inputBlurHandler: confirmPasswordBlurHandler,
    resetValue: resetConfirmPassword,
  } = useInput((value) => {
    return validatePasswordFormat(value) && value === enteredNewPassword;
  });

  const formIsValid =
    currentPasswordIsValid && newPasswordIsValid && confirmPasswordIsValid;

  const formSubmitHandler = () => {
    if (formIsValid) {
      const requestData = {
        old_password: enteredCurrentPassword,
        new_password: enteredNewPassword,
      };
      sendRequest(requestData);
    }
  };

  useEffect(() => {
    if (status === "completed" && error === null) {
      setShowActionInfoModal(true);
    }
  }, [status, error]);

  const toggleShowCurrentPasswordHandler = () => {
    setShowCurrentPassword((prevState) => !prevState);
  };

  const toggleShowNewPasswordHandler = () => {
    setShowNewPassword((prevState) => !prevState);
  };

  const toggleShowConfirmPasswordHandler = () => {
    setShowConfirmPassword((prevState) => !prevState);
  };

  const infoModalConfirmationHandler = () => {
    resetConfirmPassword();
    resetCurrentPassword();
    resetNewPassword();
    setShowActionInfoModal(false);
    setShowCurrentPassword(false);
    setShowNewPassword(false);
    setShowConfirmPassword(false);
  };

  return (
    <div className="col-6">
      <div className="profile-head-wrapper">
        <h2 className="section-title">Change Password</h2>
        {socialLogin ? (
          <p>You signed up with Google so password reset is not available.</p>
        ) : (
          <ul className="list-style-none">
            <li>
              <label htmlFor="current-password">Current Password</label>
              <div className="input-container">
                <input
                  type={showCurrentPassword ? "text" : "password"}
                  placeholder="Password"
                  className="number-list"
                  id="current-password"
                  value={enteredCurrentPassword}
                  onBlur={currentPasswordBlurHandler}
                  onChange={currentPasswordChangeHandler}
                />
                <i
                  className={`fa ${
                    showCurrentPassword ? "fa-eye-slash" : "fa-eye"
                  }`}
                  onClick={toggleShowCurrentPasswordHandler}
                ></i>
              </div>
              {currentPasswordHasError && (
                <p className="error-msg">
                  Please enter a password with at least 8 characters.
                </p>
              )}
            </li>
            <li>
              <label htmlFor="new-password">New Password</label>
              <div className="input-container">
                <input
                  type={showNewPassword ? "text" : "password"}
                  placeholder="Password"
                  className="number-list"
                  id="new-password"
                  value={enteredNewPassword}
                  onBlur={newPasswordBlurHandler}
                  onChange={newPasswordChangeHandler}
                />
                <i
                  className={`fa ${
                    showNewPassword ? "fa-eye-slash" : "fa-eye"
                  }`}
                  onClick={toggleShowNewPasswordHandler}
                ></i>
              </div>
              {newPasswordHasError && (
                <p className="error-msg">
                  Please enter a password with at least 8 characters, and
                  password must contain a number, a capital letter, a small
                  letter, and a special character.
                </p>
              )}
            </li>
            <li>
              <label htmlFor="confirm-password">Confirm New Password</label>
              <div className="input-container">
                <input
                  type={showConfirmPassword ? "text" : "password"}
                  placeholder="Confirm Password"
                  className="number-list"
                  id="confirm-password"
                  value={enteredConfirmPassword}
                  onBlur={confirmPasswordBlurHandler}
                  onChange={confirmPasswordChangeHandler}
                />
                <i
                  className={`fa ${
                    showConfirmPassword ? "fa-eye-slash" : "fa-eye"
                  }`}
                  onClick={toggleShowConfirmPasswordHandler}
                ></i>
              </div>
              {ConfirmPasswordHasError && (
                <p className="error-msg">
                  Please enter a password with at least 8 characters, and
                  password must contain a number, a capital letter, a small
                  letter, and a special character. New Password and Confirm
                  Password must match.
                </p>
              )}
            </li>
            {error && <ApiErrors>{error}</ApiErrors>}
            <li>
              <button
                onClick={formSubmitHandler}
                className={`signup-btn submit-btn ${
                  formIsValid ? "" : "disabled-link"
                } ${status === "pending" ? "disabled-link" : ""}`}
              >
                Change Password {status === "pending" && <SpinnerButton />}
              </button>
            </li>
          </ul>
        )}
        {showActionInfoModal && (
          <ModalWithInfo
            heading="Success"
            message="Your Password is successfully updated!"
            showModal={infoModalConfirmationHandler}
          />
        )}
      </div>
    </div>
  );
};

export default UpdatePassword;
