import React, { useEffect, useState } from "react";
import { adminFeatureToggle, adminGetAllFeaturesList } from "../../../lib/admin-api";
import { SpinnerModal } from "../../../components/ui/SpinnerModal";
import useHttp from "../../../hooks/use-http";
import { AdminFeaturesList } from "./AdminFeaturesList";
import AdminFeatureSearch from "./AdminFeatureSearch";
import AdminApiErrors from "../../../components/api-errors/AdminApiErrors";

export const AdminAllFeatures = () => {
    const [currentPage, setCurrentPage] = useState(1);  // Store the current page
    const [searchParams, setSearchParams] = useState({ featureName: "", selectedFeatureStatus: "" });  // Store search params

    const { sendRequest, status, error, data } = useHttp(adminGetAllFeaturesList);
    const { sendRequest: featureToggleReq, status: featureToggleStatus, error: featureToggleError, data: featureToggleData } = useHttp(adminFeatureToggle);

    useEffect(() => {
        let query = `?page=${currentPage}`;
        if (searchParams.featureName) {
            query += `&name=${encodeURIComponent(searchParams.featureName)}`;
        }
        if (searchParams.selectedFeatureStatus) {
            query += `&is_active=${searchParams.selectedFeatureStatus}`;
        }
        sendRequest(query);
    }, [sendRequest, currentPage, searchParams]);  // Add searchParams to dependency array

    const submitSearch = (data) => {
        setSearchParams({ featureName: data.featureName, selectedFeatureStatus: data.selectedFeatureStatus });
        setCurrentPage(1);  // Reset to page 1 when a new search is applied
    }

    // Reset search and current page
    const resetSearch = () => {
        setSearchParams({ featureName: '', selectedFeatureStatus: '' });  // Reset search params
        setCurrentPage(1);  // Reset page to 1
    }

    const featureAction = (item) => {
        featureToggleReq(item.id);
    }

    const pagination = (page) => {
        setCurrentPage(page);  // Set the current page in state
    }

    useEffect(() => {
        if (featureToggleStatus === 'completed' && featureToggleError === null) {
            let query = `?page=${currentPage}`;
            if (searchParams.featureName) {
                query += `&name=${encodeURIComponent(searchParams.featureName)}`;
            }
            if (searchParams.selectedFeatureStatus) {
                query += `&is_active=${searchParams.selectedFeatureStatus}`;
            }
            sendRequest(query);  // Maintain search and pagination when refreshing data
        }
    }, [featureToggleStatus, featureToggleError, featureToggleData, currentPage, searchParams]);

    return (
        <>
            {!error ? (
                <div className="admin-panel-wrap admin-all-trips flex flex-wrap w-100 content-justify-center">
                    <div className="container">
                        <h1>Features</h1>
                        <AdminFeatureSearch submitSearch={(data) => submitSearch(data)} resetSearch={resetSearch} />
                        {status === "pending" || status === null ? (
                            <SpinnerModal />
                        ) : (
                            <>
                                <h3>List</h3>
                                <div className="table-responsive table-responsive-feature">
                                    <AdminFeaturesList data={data?.data}
                                                       action={(item) => featureAction(item)}
                                                       disable={featureToggleStatus}
                                                       pagination={(page) => pagination(page)}
                                                       totalCount={data?.total}
                                                       currentPage={currentPage}  // Pass the current page
                                    />
                                </div>
                            </>
                        )}
                    </div>
                </div>
            ) : (
                <div className="main-profile-detail flex flex-wrap column-direction w-100">
                    <AdminApiErrors>{error}</AdminApiErrors>
                    <p className="no-trip">
                        An error occurred while fetching data from Backend. Please try again later.
                    </p>
                </div>
            )}
        </>
    );
};
