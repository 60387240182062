import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { newTripActions } from "../../store/new-trip-slice";
import "react-calendar/dist/Calendar.css";
import Calendar from "react-calendar";
import OutsideClickHandler from "react-outside-click-handler";
import { getDateWithoutTime } from "../../lib/api";
import { getDateToStringWithoutTime } from "../../lib/api";

export const DateCalendar = (props) => {
  const dispatch = useDispatch();
  const [date, setDate] = useState([new Date()]);
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);

  // Initializing date
  useEffect(() => {
    if (props.isEditing) {
      setDate(props.dateRange);
    } else {
      setDate([new Date()]);
    }
  }, []);

  const toggleCalendar = () => {
    setIsCalendarOpen(!isCalendarOpen);
  };

  const dateChangeHandler = (selectedDate) => {
    setDate(selectedDate);
    if (props.isEditing) {
      props.setDateRange(selectedDate);
    }
    setIsCalendarOpen(false);
    if (getDateWithoutTime(selectedDate[0]) < getDateWithoutTime(new Date())) {
      props.setDateHasError(true);
      return;
    }
    props.setDateHasError(false);
  };

  const displayDateAndStoreData = () => {
    if (date[1]) {
      if (
        getDateWithoutTime(date[0]).getTime() ===
        getDateWithoutTime(date[1]).getTime()
      ) {
        if (!props.dateHasError && !props.isEditing) {
          dispatch(
            newTripActions.updateDateRange([
              getDateToStringWithoutTime(date[0]),
            ])
          );
          //storing in local storage also in case of page reload
          localStorage.setItem(
            "date",
            JSON.stringify([getDateToStringWithoutTime(date[0])])
          );
        }
        return getDateToStringWithoutTime(date[0]);
      } else {
        if (!props.dateHasError && !props.isEditing) {
          dispatch(
            newTripActions.updateDateRange([
              getDateToStringWithoutTime(date[0]),
              getDateToStringWithoutTime(date[1]),
            ])
          );
          //storing in local storage also in case of page reload
          localStorage.setItem(
            "date",
            JSON.stringify([
              getDateToStringWithoutTime(date[0]),
              getDateToStringWithoutTime(date[1]),
            ])
          );
        }
        return (
          getDateToStringWithoutTime(date[0]) +
          " - " +
          getDateToStringWithoutTime(date[1])
        );
      }
    }
    if (!props.dateHasError && !props.isEditing) {
      dispatch(
        newTripActions.updateDateRange([getDateToStringWithoutTime(date[0])])
      );
      //storing in local storage also in case of page reload
      localStorage.setItem(
        "date",
        JSON.stringify([getDateToStringWithoutTime(date[0])])
      );
    }
    return getDateToStringWithoutTime(date[0]);
  };

  return (
    <>
      <OutsideClickHandler
        onOutsideClick={() => {
          setIsCalendarOpen(false);
        }}
      >
        <div className="field-wrapper">
          <button
            type="button"
            className={isCalendarOpen ? "expanded" : ""}
            onClick={toggleCalendar}
          >
            <strong>Date / Date Range</strong>
            <span>{displayDateAndStoreData()}</span>
          </button>
          <div className={`calender-wrap ${isCalendarOpen ? "show" : ""}`}>
            <Calendar
              onChange={dateChangeHandler}
              closeCalendar={true}
              selectRange={true}
            />
          </div>
        </div>
      </OutsideClickHandler>
    </>
  );
};
