import React, { useEffect } from "react";
import { AdminTripCover } from "./AdminTripCover";
import { AdminAttendingUsersList } from "./AdminAttendingUsersList";
import { useParams } from "react-router-dom";
import { adminGetSingleTripDetails } from "../../../lib/admin-api";
import useHttp from "../../../hooks/use-http";
import { SpinnerModal } from "../../../components/ui/SpinnerModal";
import { AdminTripGearList } from "./admin-trip-gear/AdminTripGearList";
import AdminApiErrors from "../../../components/api-errors/AdminApiErrors";

export const AdminViewTripDetails = () => {
  const params = useParams();
  const { sendRequest, status, error, data } = useHttp(
    adminGetSingleTripDetails
  );

  useEffect(() => {
    sendRequest(params.id);
  }, [sendRequest]);

  return (
    <>
      {!error ? (
        <>
          {status === "pending" || status === null ? (
            <SpinnerModal />
          ) : (
            <>
              {data.trip ? (
                <>
                  <div className="main-profile-detail flex flex-wrap column-direction w-100">
                    <AdminTripCover tripData={data.trip} />
                    <div className="container">
                      <div className="panel-wrap flex">
                        <div className="gear-wrapper admin-gear">
                          <AdminTripGearList tripId={params.id} />
                          <AdminAttendingUsersList
                            attendingUsers={data.trip.attending_user}
                            invitedUsers={data.trip.invitees}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <p className="no-trip">Sorry! This trip could not be found.</p>
              )}
            </>
          )}
        </>
      ) : (
        <div className="main-profile-detail flex flex-wrap column-direction w-100">
          <AdminApiErrors>{error}</AdminApiErrors>
          <p className="no-trip">
            An error occured while fetching data from Backend. Please try again
            later.
          </p>
        </div>
      )}
    </>
  );
};
