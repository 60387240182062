import React, {useEffect, useState} from "react";
import "./adminSubPlan.scss";
import useHttp from "../../../hooks/use-http";
import {getSubscriptionPlans, SubscribeToPlan} from "../../../lib/api";
import {SpinnerModal} from "../../../components/ui/SpinnerModal";
import SubscribeModal from "./SubscribeModal";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {authActions} from "../../../store/auth-slice";
import {useDispatch, useSelector} from "react-redux";
import ApiErrors from "../../../components/api-errors/ApiErrors";

export const SubscriptionPlans = () => {
    const dispatch = useDispatch();
    const {sendRequest, status, error, data} = useHttp(getSubscriptionPlans);
    const {sendRequest: subcribePlanReq, status: subcribePlanStatus, error: subcribePlanError, data: subcribePlanData} = useHttp(SubscribeToPlan);
    const [openModal, setOpenModal] = useState(false);
    const [modalData, setModalData] = useState(null);
    const userSubscription = useSelector((state) => state.auth.userSubscription);
    const [showApiErrors, setShowApiErrors] = useState(false);

    useEffect(() => {
        sendRequest();
    }, []);

    useEffect(() => {
        if (error) {
            setShowApiErrors(true);
        } else {
            setShowApiErrors(false);
        }
    }, [error]);

    useEffect(() => {
        if (subcribePlanStatus === 'completed' && subcribePlanError === null) {
            setOpenModal(false)
            setModalData(null)
            dispatch(authActions.updateFeatures(subcribePlanData));
            dispatch(authActions.updateSubscription(subcribePlanData));
            toast.success(`${subcribePlanData?.message}`);
        }
    }, [subcribePlanStatus, subcribePlanError, subcribePlanData, error]);

    const subscribe = (item) => {
        setOpenModal(true)
        setModalData(item)
    }

    return (
        <>
            {status === 'pending' ? (
                <SpinnerModal/>
            ) : (
                <>
                    {showApiErrors && (
                        <div style={{ textAlign: 'center' }}>
                            <ApiErrors>
                                {error}
                            </ApiErrors>
                        </div>
                    )}
                    <div className="admin-panel-wrap admin-all-trips flex flex-wrap w-100 content-justify-center">
                        <div className="container">
                            <h1 style={{textAlign: 'center'}}>Choose Plan</h1>
                            <div className="pricing-cards">
                                <div className="cards-container">
                                    <>
                                        {data?.subscription_plans.map((item, index) => (
                                            <div className="pricing-card" key={index}>
                                                <header className="pricing-header">
                                                    <h2>{item?.name}</h2>
                                                    <div className="price">
                                                        <span className="currency">$</span>
                                                        <span className="value">{item?.variations[0]?.price}</span>
                                                        <span className="duration">/ Month</span>
                                                    </div>
                                                </header>
                                                <div>
                                                    <button type='button'
                                                            onClick={() => subscribe(item)}
                                                            disabled={item.id === userSubscription}
                                                            className={`choose-plan-btn ${item.id === userSubscription ? 'disabled-link' : ''}`}>
                                                        Buy now
                                                    </button>
                                                </div>
                                                <ul className="features-list">
                                                    {item?.features.map((feature) => (
                                                        <li key={`feature-${feature.id}`}>{feature.name}</li>
                                                    ))}
                                                </ul>
                                            </div>
                                        ))}
                                    </>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}

            <SubscribeModal isOpen={openModal}
                            data={modalData}
                            onClose={() => {
                                setOpenModal(false)
                                setModalData(null)
                            }}
                            onSave={(data) => {
                                subcribePlanReq(data)
                            }}
                            loading={subcribePlanStatus}
            />
        </>
    );
};
