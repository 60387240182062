import { Location } from "./Location";
import { DateCalendar } from "./DateCalendar";
import { AddActivity } from "./AddActivity";
import { Link } from "react-router-dom";
import "./search.scss";
import React, { useState } from "react";

const SearchBar = () => {
  const [locationSelected, setLocationSelected] = useState(false);
  const [locationTouched, setLocationTouched] = useState(false);
  const [dateHasError, setDateHasError] = useState(false);
  const [emptyActivity, setEmptyActivity] = useState(false);

  return (
    <>
      <div className="container search">
        <div className="search-wrapper column-direction">
          <h1>
            Adventure planning, <strong>Simplified.</strong>
          </h1>
          <section>
            <ul className="list-style-none flex">
              <li>
                <Location
                  updateLocationSelected={setLocationSelected}
                  setLocationTouched={setLocationTouched}
                />
              </li>
              <li>
                <DateCalendar
                  setDateHasError={setDateHasError}
                  dateHasError={dateHasError}
                />
              </li>
              <li>
                <AddActivity setEmptyActivity={setEmptyActivity} />
              </li>
              <li>
                <Link
                  to="/trip-plan/confirm-trip-details"
                  className={`search-btn ${
                    locationSelected && !dateHasError && !emptyActivity
                      ? ""
                      : "disabled-link"
                  }`}
                >
                  Schedule
                </Link>
              </li>
            </ul>
          </section>
          {((locationTouched && !locationSelected) ||
            dateHasError ||
            emptyActivity) && (
            <div className="errors-wrapper">
              {locationTouched && !locationSelected && (
                <p>
                  <i className="fa fa-times"></i> Please Select Location
                </p>
              )}
              {dateHasError && (
                <p>
                  <i className="fa fa-times"></i> Please Select a Valid Date
                </p>
              )}
              {emptyActivity && (
                <p>
                  <i className="fa fa-times"></i> Please Enter an Activity
                </p>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default SearchBar;
