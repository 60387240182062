import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import ApiErrors from "../../components/api-errors/ApiErrors";
import useInput from "../../hooks/use-input";
import useHttp from "../../hooks/use-http";
import SpinnerButton from "../../components/ui/SpinnerButton";
import { sendResetEmail } from "../../lib/api";
import inspireLogo from "../../assets/images/inspirelogo.svg";
import "../../pages/signup/signUp.scss"

const ForgotPassword = () => {
  const { sendRequest, status, error } = useHttp(sendResetEmail);
  const navigate = useNavigate();

  const {
    value: enteredEmail,
    hasError: emailHasError,
    valueIsValid: emailIsValid,
    valueChangeHandler: emailChangeHandler,
    inputBlurHandler: emailBlurHandler,
  } = useInput((value) => value.includes("@"));

  const formSubmitHandler = (event) => {
    event.preventDefault();

    if (emailIsValid) {
      const userData = {
        email: enteredEmail,
      };
      sendRequest(userData, true);
    } else {
      return;
    }
  };

  useEffect(() => {
    if (status === "completed" && !error) {
      navigate("/reset-password", { replace: true });
    }
  }, [status, error, navigate]);

  return (
    <>
      <div className="main-trail-head sign-in main-signup flex flex-wrap w-100 content-justify-center">
        <div className="container">
          <div className="trail-head-wrapper signup-box">
          <Link to="/forgot-password" className="logoInspire">
              <img src={inspireLogo} alt="logo" />
            </Link>
            <h1 className="trail-title">Forgot Password</h1>
            <form onSubmit={formSubmitHandler}>
              <div className="phone-numbers">
                <ul className="list-style-none">
                  <li>
                  <div className="form-group">
                      <i className="fa fa-envelope emailIcon"></i>
                      <label>Email</label>
                      <div className="input-container">
                    <input
                      type="email"
                      placeholder="Account Email"
                      className="number-list"
                      id="email"
                      value={enteredEmail}
                      onBlur={emailBlurHandler}
                      onChange={emailChangeHandler}
                    />
                    </div>
                    </div>
                  </li>
                  {emailHasError && (
                    <p className="error-msg">Please enter a valid Email.</p>
                  )}
                  {error && <ApiErrors>{error}</ApiErrors>}
                  <li>
                    <button
                      type="submit"
                      className={`signup-btn submit-btn ${
                        emailIsValid ? "" : "disabled-link"
                      } ${status === "pending" ? "disabled-link" : ""}`}
                    >
                      Reset Password{status === "pending" && <SpinnerButton />}
                    </button>
                  </li>
                </ul>
              </div>
            </form>
            <small className="already-account">
              Don't have an account? <Link to="/signup">Sign Up</Link>
            </small>
            <small className="already-account">
              Already have an account? <Link to="/signin">Log In</Link>
            </small>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
