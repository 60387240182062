import "./admin-all-users.scss";
import React, { useEffect, useState } from "react";
import { SpinnerModal } from "../../../components/ui/SpinnerModal";
import useHttp from "../../../hooks/use-http";
import { getAllUsers } from "../../../lib/admin-api";
import { AdminUsersList } from "./AdminUsersList";
import AdminUsersSearch from "./AdminUsersSearch";
import AdminApiErrors from "../../../components/api-errors/AdminApiErrors";

export const AdminAllUsers = () => {
  const { sendRequest, status, error, data } = useHttp(getAllUsers);
  const [isSearching, setIsSearching] = useState(false);
  const [searchResults, setSearchResults] = useState(null);

  useEffect(() => {
    sendRequest();
  }, [sendRequest]);

  return (
    <>
      <div className="admin-panel-wrap admin-all-trips flex flex-wrap w-100 content-justify-center">
        <div className="container">
          <h1>Users List</h1>
          <AdminUsersSearch
            setIsSearching={setIsSearching}
            setSearchResults={setSearchResults}
          />
          {!error ? (
            <div className="table-responsive">
              {status === "pending" || status === null ? (
                <SpinnerModal />
              ) : isSearching ? (
                <AdminUsersList data={searchResults} />
              ) : (
                <AdminUsersList data={data.all_users} />
              )}
            </div>
          ) : (
            <>
              <AdminApiErrors>{error}</AdminApiErrors>
              <p className="no-trip">
                An error occured while fetching data from Backend. Please try
                again later.
              </p>
            </>
          )}
        </div>
      </div>
    </>
  );
};
