import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import React from 'react'

const AdminRoute = ({ children }) => {
  const isAdminLoggedIn = useSelector(
    (state) => state.adminAuth.isAdminLoggedIn
  );
  if (!isAdminLoggedIn) {
    return <Navigate to="/admin-32423QeCFer/admin-login" replace />;
  }
  return children;
};

export default AdminRoute;
