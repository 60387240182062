import React, {useState, useEffect} from 'react';
import useInput from '../../../hooks/use-input'; // Adjust the path as needed
import {
    adminSubscriptionPlanVariationCreate,
    adminFetchSubscriptionVariation,
    adminDeleteSubscriptionVariation
} from "../../../lib/admin-api"; // Import API functions
import useHttp from "../../../hooks/use-http";
import ApiErrors from "../../../components/api-errors/ApiErrors";
import "./adminSubPlan.scss";

const VariationModal = ({isOpen, onClose, planData}) => {
    const [offerPrice, setOfferPrice] = useState(0);
    const [showApiErrors, setShowApiErrors] = useState(false);
    const [variations, setVariations] = useState([]); 

    const {
        sendRequest: savePlanVeriationRequest,
        status: savePlanVeriationStatus,
        error: savePlanVeriationError,
        data: savePlanVeriationData
    } = useHttp(adminSubscriptionPlanVariationCreate);
    const {
        sendRequest: fetchVariationsRequest,
        status: fetchVariationsStatus,
        data: fetchedVariations
    } = useHttp(adminFetchSubscriptionVariation); // Fetch variations API
    const {
        sendRequest: deleteVariationsRequest,
        status: deleteVariationsStatus,
        error: deleteVariationsError,
        data: deleteVariationsData
    } = useHttp(adminDeleteSubscriptionVariation); // Fetch variations API

    const {
        value: duration,
        hasError: durationError,
        valueIsValid: durationIsValid,
        valueChangeHandler: durationChangeHandler,
        inputBlurHandler: durationBlurHandler,
    } = useInput(() => true, '1');

    const {
        value: price,
        hasError: priceError,
        valueIsValid: priceIsValid,
        valueChangeHandler: priceChangeHandler,
        inputBlurHandler: priceBlurHandler,
        resetValue: resetPrice,
    } = useInput(value => !isNaN(value) && value > 0); // Ensure price is a number and greater than 0

    const {
        value: discount,
        hasError: discountError,
        valueIsValid: discountIsValid,
        valueChangeHandler: discountChangeHandler,
        inputBlurHandler: discountBlurHandler,
        resetValue: resetDiscount,
    } = useInput((value) => !isNaN(value) && Number(value) >= 0, 0);

    useEffect(() => {
        const calculatedOfferPrice = priceIsValid && discountIsValid
            ? price - (price * discount / 100)
            : 0;
        setOfferPrice(calculatedOfferPrice.toFixed(2));
    }, [price, discount, priceIsValid, discountIsValid]);

    // Fetch existing variations when the modal opens
    useEffect(() => {
        if (isOpen && planData?.id) {
            setShowApiErrors(false);
            fetchVariationsRequest({planId: planData.id});
        }
    }, [isOpen, planData, fetchVariationsRequest]);

    useEffect(() => {
        if (fetchedVariations?.status) {
            setVariations(fetchedVariations?.data?.variations);
        }
    }, [fetchedVariations]);

    const handleSubmit = (event) => {
        event.preventDefault();
        if (!priceIsValid || !durationIsValid || !discountIsValid) return; // Prevent form submission if invalid
        setShowApiErrors(false);
        savePlanVeriationRequest({
            duration_type: duration,
            price: price,
            discount: discount,
            offerPrice: offerPrice,
            planId: planData?.id,
        });
    };

    useEffect(() => {
        if (deleteVariationsError) {
            setShowApiErrors(true);
        }
        if (deleteVariationsStatus === 'completed') {
            fetchVariationsRequest({planId: planData.id});
        }
    }, [deleteVariationsStatus, deleteVariationsError, savePlanVeriationData]);

    // Disable submit button if form is invalid
    const formIsValid = priceIsValid && durationIsValid && discountIsValid;

    const handleDelete = (variationId) => {
        deleteVariationsRequest({planId: planData.id, variationId: variationId});
    };

    useEffect(() => {
        if (savePlanVeriationError) {
            setShowApiErrors(true);
        }
        if (savePlanVeriationStatus === 'completed') {
            fetchVariationsRequest({planId: planData.id});
            resetPrice();
            resetDiscount(0);
        }
    }, [savePlanVeriationStatus, savePlanVeriationError, deleteVariationsData]);

    if (!isOpen) return null;

    return (
        <div className="confirm-modal">
            <div className="confirm-modal-content confirm-variation-modal">
                <div className="modal-header">
                    <h2>Variations</h2>
                </div>
                <div className="modal-body">
                    {showApiErrors && <ApiErrors>{savePlanVeriationError}</ApiErrors>}
                    {planData?.is_publish === 0 &&
                        <form onSubmit={handleSubmit}>
                            <ul className="list-style-none flex flex-wrap items-start variationModalUl">
                                <li>
                                    <label>Duration</label>
                                    <select
                                        className="number-list"
                                        value={duration}
                                        onChange={durationChangeHandler}
                                        onBlur={durationBlurHandler}
                                    >
                                        <option value="1">1 Month</option>
                                        <option value="12">12 Months</option>
                                        <option value="24">24 Months</option>
                                        <option value="36">36 Months</option>
                                    </select>
                                    {durationError && <p className="error-msg">Please select a plan duration.</p>}
                                </li>
                                <li>
                                    <label>Price</label>
                                    <input
                                        type="text"
                                        className="number-list"
                                        placeholder="Price"
                                        value={price}
                                        onChange={priceChangeHandler}
                                        onBlur={priceBlurHandler}
                                    />
                                    {priceError && <p className="error-msg">Please enter price. (Numbers only)</p>}
                                </li>
                                <li>
                                    <label>Discount (%)</label>
                                    <input
                                        type="text"
                                        className="number-list"
                                        placeholder="Discount"
                                        value={discount}
                                        onChange={discountChangeHandler}
                                        onBlur={discountBlurHandler}
                                    />
                                    {discountError &&
                                        <p className="error-msg">Please enter a valid discount. (Numbers only)</p>}
                                </li>
                                <li>
                                    <label>Offer Price</label>
                                    <input
                                        type="text"
                                        className="number-list"
                                        placeholder="Offer Price"
                                        value={offerPrice}
                                        readOnly
                                    />
                                </li>
                            </ul>
                            {/* <hr style={{margin:'14px 0px 14px 0px'}}/> */}
                            <div className="list-style-none flex flex-wrap items-start content-justify-end">
                                <div>
                                    <button
                                        type="submit"
                                        className={`signup-btn submit-btn saveVariationBtn ${!formIsValid ? 'disabled-link' : ''}`}
                                        disabled={!formIsValid}
                                    >
                                        Save
                                    </button>
                                </div>
                            </div>
                        </form>
                    }
                    <div className="existing-variations">
                        <h3>Existing Variations</h3>
                        {fetchVariationsStatus === 'pending' && <p>Loading variations...</p>}
                        {fetchVariationsStatus === 'completed' && variations.length > 0 ? (
                            <table className="variations-table">
                                <thead>
                                <tr>
                                    <th style={{color: 'black', padding:'6px'}}>Duration</th>
                                    <th style={{color: 'black', padding:'6px'}}>Price</th>
                                    <th style={{color: 'black', padding:'6px'}}>Discount (%)</th>
                                    <th style={{color: 'black', padding:'6px'}}>Offer Price</th>
                                    {planData?.is_publish === 0 &&
                                        <th style={{color: 'black'}}>Action</th>
                                    }
                                </tr>
                                </thead>
                                <tbody>
                                {variations.map((variation) => (
                                    <tr key={variation.id} className="tableEV">
                                        <td style={{color: 'black'}}>{variation.duration_type} Month</td>
                                        <td style={{color: 'black'}}>{variation.price}</td>
                                        <td style={{color: 'black'}}>{variation.discount}%</td>
                                        <td style={{color: 'black'}}>{variation.offer_price}</td>
                                        {planData?.is_publish === 0 &&
                                            <td style={{color: 'black'}} className="custom-td">
                                                <button
                                                    className="signup-btn deleteVariation"
                                                    onClick={() => handleDelete(variation.id)}
                                                >
                                                    Delete
                                                </button>
                                            </td>
                                        }
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        ) : (
                            <p style={{color: 'black'}}>No variations found.</p>
                        )}
                    </div>
                </div>
                <div className="modal-footer flex content-justify-end">
                    <button className="signup-btn variationModalBtn" onClick={onClose}>
                        Close
                    </button>
                </div>
            </div>
        </div>
    );
};

export default VariationModal;
