import {useSelector} from "react-redux";
import {Navigate} from "react-router-dom";
import React from 'react'

//This component ensures that a logged in user cannot access an only public route

const OnlyPublicRoute = ({children}) => {
    if (children) {
        const isUserLoggedIn = useSelector((state) => state.auth.isUserLoggedIn);
        const isSocialLoginWithoutPhone = useSelector(
            (state) => state.auth.socialLoginWithoutPhone
        );
        if (isUserLoggedIn && !isSocialLoginWithoutPhone) {
            return <Navigate to="/phone-number-required" replace/>;
        } else if (isUserLoggedIn) {
            if (!!localStorage.getItem("location")) {
                return <Navigate to="/trip-plan/confirm-trip-details" replace/>;
            } else {
                return <Navigate to="/trip-plan" replace/>;
                //return <Navigate to="/my-account" replace/>;
            }
        }
    }
    return children;
};

export default OnlyPublicRoute;
