// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.error-page {
  height: 100vh;
}
.error-page .error-wrap {
  text-align: center;
}
.error-page .error-wrap h1 {
  margin: 0 0 15px;
  font-size: 180px;
  line-height: 1;
}
.error-page .error-wrap h2 {
  font-size: 50px;
  margin: 0 0 30px;
  line-height: 1;
}
.error-page .error-wrap p {
  font-size: 18px;
  margin: 0 0 20px;
}
.error-page .error-wrap .signup-btn {
  margin: 0 auto;
  max-width: 200px;
}`, "",{"version":3,"sources":["webpack://./src/pages/error-page/error.scss"],"names":[],"mappings":"AACA;EACE,aAAA;AAAF;AACE;EACE,kBAAA;AACJ;AAAI;EACE,gBAAA;EACA,gBAAA;EACA,cAAA;AAEN;AAAI;EACE,eAAA;EACA,gBAAA;EACA,cAAA;AAEN;AAAI;EACE,eAAA;EACA,gBAAA;AAEN;AAAI;EAAc,cAAA;EAAgB,gBAAA;AAIlC","sourcesContent":["@import \"../../assets/scss/mixin\";\n.error-page {\n  height: 100vh;\n  .error-wrap {\n    text-align: center;\n    h1 {\n      margin: 0 0 15px;\n      font-size: 180px;\n      line-height: 1;\n    }\n    h2 {\n      font-size: 50px;\n      margin: 0 0 30px;\n      line-height: 1;\n    }\n    p {\n      font-size: 18px;\n      margin: 0 0 20px;\n    }\n    .signup-btn { margin: 0 auto; max-width: 200px;}\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
