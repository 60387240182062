import React, {useEffect, useState} from "react";
import useInput from "../../../hooks/use-input";
import useHttp from "../../../hooks/use-http";
import {adminSubscriptionPlanCreate, adminSubscriptionPlanFeatures} from "../../../lib/admin-api";
import ApiErrors from "../../../components/api-errors/ApiErrors";
import SpinnerButton from "../../../components/ui/SpinnerButton";
import "./adminSubPlan.scss";

const AdminSubscriptionPlansCreate = () => {
    const [selectedFeature, setSelectedFeature] = useState([]);
    const [showApiErrors, setShowApiErrors] = useState(false);
    const [showSuccess, setSuccess] = useState(false);
    const [offerPrice, setOfferPrice] = useState(0);

    const {
        value: planName,
        hasError: planNameError,
        valueIsValid: planNameIsValid,
        valueChangeHandler: planNameChangeHandler,
        inputBlurHandler: planNameBlurHandler,
        resetValue: resetPlanName,
    } = useInput((value) => value.trim().length > 0);

    const {
        value: price,
        hasError: priceError,
        valueIsValid: priceIsValid,
        valueChangeHandler: priceChangeHandler,
        inputBlurHandler: priceBlurHandler,
        resetValue: resetPrice,
    } = useInput((value) => value.trim().length > 0 && !isNaN(value));

    const {
        value: duration,
        hasError: durationError,
        valueIsValid: durationIsValid,
        valueChangeHandler: durationChangeHandler,
        inputBlurHandler: durationBlurHandler,
    } = useInput(() => true, '1');

    const {
        value: discount,
        hasError: discountError,
        valueIsValid: discountIsValid,
        valueChangeHandler: discountChangeHandler,
        inputBlurHandler: discountBlurHandler,
        resetValue: resetDiscount,
    } = useInput((value) => !isNaN(value) && Number(value) >= 0, 0);

    useEffect(() => {
        const calculatedOfferPrice = priceIsValid && discountIsValid
            ? price - (price * discount / 100)
            : 0;
        setOfferPrice(calculatedOfferPrice.toFixed(2));
    }, [price, discount, priceIsValid, discountIsValid]);

    const handleFeatures = (e) => {
        const value = parseInt(e.target.value);
        setSelectedFeature((prevItems) => {
            if (prevItems.includes(value)) {
                return prevItems.filter((item) => item !== value);
            } else {
                return [...prevItems, value];
            }
        });
    }

    const {sendRequest, data} = useHttp(adminSubscriptionPlanFeatures);
    const {sendRequest: planCreateReq, status: planCreateStatus, error: planCreateError, data: planCreateData} = useHttp(adminSubscriptionPlanCreate);

    const formSubmitHandler = (event) => {
        event.preventDefault();
        setShowApiErrors(false);
        planCreateReq({
            name: planName,
            price: price,
            duration: duration,
            discount: discount,
            features: selectedFeature,
            isDefault: false,
            isPublish: false,
            offerPrice: offerPrice
        });
    };

    useEffect(() => {
        sendRequest();
    }, []);

    useEffect(() => {
        if (planCreateError) {
            setShowApiErrors(true);
        }
        if (planCreateStatus === 'completed') {
            resetPlanName();
            resetPrice();
            resetDiscount(0);
            setSelectedFeature([]);
            setSuccess(true);
            setTimeout(() => setSuccess(false), 3000);
        }
    }, [planCreateStatus, planCreateError, planCreateData]);

    return (
        <div className="admin-panel-wrap admin-all-trips flex flex-wrap w-100 content-justify-center">
            <div className="container">
                <h1>Create</h1>
                <div className="admin-search-wrapper">
                    {/* <h2 className="search-title">Create</h2> */}
                    {showApiErrors && <ApiErrors>{planCreateError}</ApiErrors>}
                    {showSuccess && planCreateStatus === 'completed' && !planCreateError && (
                        <div style={{background: 'green', padding: '5px', marginBottom: '5px', textAlign: 'center'}}>Plan created successfully.</div>
                    )}
                    <form onSubmit={formSubmitHandler}>
                        <ul className="list-style-none flex flex-wrap items-start">
                            <li>
                                <label>Plan Name</label>
                                <input type="text"
                                       className="number-list"
                                       placeholder="Plan Name"
                                       value={planName}
                                       onChange={planNameChangeHandler}
                                       onBlur={planNameBlurHandler}
                                />
                                {planNameError && (
                                    <p className="error-msg">
                                        Please enter plan name.
                                    </p>
                                )}
                            </li>
                            <li>
                                <label>Price</label>
                                <input type="text"
                                       className="number-list"
                                       placeholder="Price"
                                       value={price}
                                       onChange={priceChangeHandler}
                                       onBlur={priceBlurHandler}
                                />
                                {priceError && (
                                    <p className="error-msg">
                                        Please enter price. (Numbers only)
                                    </p>
                                )}
                            </li>
                            <li>
                                <label>Duration</label>
                                <select
                                    className="number-list"
                                    value={duration}
                                    onChange={durationChangeHandler}
                                    onBlur={durationBlurHandler}
                                    disabled
                                >
                                    <option value="1">1 Month</option>
                                </select>
                                {durationError && (
                                    <p className="error-msg">
                                        Please select a plan duration.
                                    </p>
                                )}
                            </li>
                            <li>
                                <label>Discount (%)</label>
                                <input type="text"
                                       className="number-list"
                                       placeholder="Discount"
                                       value={discount}
                                       onChange={discountChangeHandler}
                                       onBlur={discountBlurHandler}
                                />
                                {discountError && (
                                    <p className="error-msg">
                                        Please enter a valid discount. (Numbers only)
                                    </p>
                                )}
                            </li>
                            <li>
                                <label>Offer Price</label>
                                <input type="text"
                                       className="number-list"
                                       placeholder="Offer Price"
                                       value={offerPrice}
                                       readOnly
                                />
                            </li>
                        </ul>
                        <h2 className="search-title">Select Features</h2>
                        <ul className="list-style-none flex flex-wrap items-start">
                            {data?.map((item) => (
                                <li key={item.id}>
                                    <div className="form-check feature-list-items">
                                        <input className="form-check-input"
                                               type="checkbox"
                                               value={item?.id}
                                               id={`feature-${item?.id}`}
                                               onChange={(e) => {
                                                   handleFeatures(e);
                                               }}
                                               checked={selectedFeature.includes(item?.id)}
                                        />
                                        <label className="form-check-label" htmlFor={`feature-${item.id}`}>
                                            {item.name}
                                        </label>
                                    </div>
                                </li>
                            ))}
                        </ul>
                        <hr/>
                        <br/>
                        <ul className="list-style-none flex flex-wrap items-start content-justify-end">
                            <li>
                                <button type="submit"
                                        className={`signup-btn submit-btn ${!planNameIsValid || !priceIsValid || !durationIsValid || !discountIsValid || (selectedFeature.length === 0) ? 'disabled-link' : ''}`}>
                                    Create Plan{planCreateStatus === "pending" && <SpinnerButton />}
                                </button>
                            </li>
                        </ul>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default AdminSubscriptionPlansCreate;
