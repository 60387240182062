import React, { useState, useEffect } from "react";
import modalCross from "../../../assets/images/cross.svg";
import NoGearImage from "../../../assets/images/no-gear-img.webp";
import useInput from "../../../hooks/use-input";
import useHttp from "../../../hooks/use-http";
import {
  addPersonalGearItem,
  addGroupGearItem,
  updatePersonalGearItem,
  updateGroupGearItem,
  validateImage,
} from "../../../lib/api";
import ApiErrors from "../../../components/api-errors/ApiErrors";
import ModalWithInfo from "../../../components/ui/ModalWithInfo";
import ImageInput from "../../../utilities/ImageInput";
import SpinnerButton from "../../../components/ui/SpinnerButton";

const AddGearItem = (props) => {
  const [selectedImage, setSelectedImage] = useState(
    props.gearItem
      ? props.gearItem.item_image
        ? props.gearItem.item_image
        : null
      : null
  );

  const [imageIsTouched, setImageIsTouched] = useState(false);
  const [imageIsValid, setImageIsValid] = useState(false);
  const [showGearAddedModal, setShowGearAddedModal] = useState(false);
  const [showGearUpdatedModal, setShowGearUpdatedModal] = useState(false);

  const { sendRequest, status, error } = useHttp(addPersonalGearItem);
  const {
    sendRequest: sendAddGroupGearRequest,
    status: addGroupGearStatus,
    error: addGroupGearError,
  } = useHttp(addGroupGearItem);
  const {
    sendRequest: sendPersonalGearUpdateRequest,
    status: personalGearUpdateStatus,
    error: personalGearUpdateError,
  } = useHttp(updatePersonalGearItem);
  const {
    sendRequest: sendGroupGearUpdateRequest,
    status: groupGearUpdateStatus,
    error: groupGearUpdateError,
  } = useHttp(updateGroupGearItem);

  const {
    value: enteredGearName,
    hasError: gearNameHasError,
    valueIsValid: gearNameIsValid,
    valueChangeHandler: gearNameChangeHandler,
    inputBlurHandler: gearNameBlurHandler,
  } = useInput(
    (value) => value.trim().length > 2 && value.trim().length < 51,
    props.gearItem && props.gearItem.item_name
  );

  const {
    value: enteredGearDescription,
    hasError: gearDescriptionHasError,
    valueIsValid: gearDescriptionIsValid,
    valueChangeHandler: gearDescriptionChangeHandler,
    inputBlurHandler: gearDescriptionBlurHandler,
  } = useInput(
    (value) => value.trim().length > 4 && value.trim().length < 201,
    props.gearItem && props.gearItem.item_description
  );

  const {
    value: enteredQuantity,
    hasError: quantityHasError,
    valueIsValid: quantityIsValid,
    valueChangeHandler: quantityChangeHandler,
    inputBlurHandler: quantityBlurHandler,
  } = useInput(
    (value) => value.trim() > 0 && value.trim() < 10000,
    props.gearItem && props.gearItem.item_quantity
  );

  const formIsValid =
    gearNameIsValid && gearDescriptionIsValid && quantityIsValid;

  //For image validation
  useEffect(() => {
    // If we do not check the image is touched or not, selected image can also contain an image URL
    //coming from DB in case the user already has an image and it causes an error. So, it is important
    //to have this check. Whenever the user touches the image, it is going to be a "File" so the
    //validate function won't crash.

    if (imageIsTouched && selectedImage) {
      if (!validateImage(selectedImage)) {
        setImageIsValid(false);
        setSelectedImage(null);
      } else {
        setImageIsValid(true);
      }
    }
  }, [selectedImage, imageIsTouched]);

  const formSubmitHandler = (event) => {
    event.preventDefault();

    if (formIsValid) {
      if (props.isEditing && props.isMyGroupGear) {
        //Updating Existing Gear in My Group Gear
        const formData = new FormData(this);
        formData.append("item_name", enteredGearName);
        formData.append("item_description", enteredGearDescription);
        formData.append("item_quantity", enteredQuantity);
        formData.append("my_trip_id", props.gearItem.my_trip_id);
        formData.append("_method", "PATCH");
        //Only update image if user touched it while editing.
        if (imageIsTouched) {
          formData.append("item_image", selectedImage);
        }
        const gearData = { gearId: props.gearItem.id, data: formData };
        sendGroupGearUpdateRequest(gearData);
      } else if (props.isEditing) {
        //Updating Existing Personal Gear
        const formData = new FormData(this);
        formData.append("item_name", enteredGearName);
        formData.append("item_description", enteredGearDescription);
        formData.append("item_quantity", enteredQuantity);
        formData.append("_method", "PATCH");
        //Only update image if user touched it while editing.
        if (imageIsTouched) {
          formData.append("item_image", selectedImage);
        }
        const gearData = { gearId: props.gearItem.id, data: formData };
        sendPersonalGearUpdateRequest(gearData);
      } else if (props.isMyGroupGear) {
        //Adding New Group Gear
        const formData = new FormData(this);
        formData.append("item_name", enteredGearName);
        formData.append("item_description", enteredGearDescription);
        formData.append("item_quantity", enteredQuantity);
        formData.append("my_trip_id", props.tripId);
        if (selectedImage) {
          formData.append("item_image", selectedImage);
        }
        sendAddGroupGearRequest(formData, true);
      } else {
        //Adding New Personal Gear
        const formData = new FormData(this);
        formData.append("item_name", enteredGearName);
        formData.append("item_description", enteredGearDescription);
        formData.append("item_quantity", enteredQuantity);
        formData.append("my_trip_id", props.tripId);
        if (selectedImage) {
          formData.append("item_image", selectedImage);
        }
        sendRequest(formData, true);
      }
    } else {
      return;
    }
  };

  useEffect(() => {
    if (status === "completed" && error === null) {
      setShowGearAddedModal(true);
    }
    if (addGroupGearStatus === "completed" && addGroupGearError === null) {
      setShowGearAddedModal(true);
    }
    if (
      personalGearUpdateStatus === "completed" &&
      personalGearUpdateError === null
    ) {
      setShowGearUpdatedModal(true);
    }
    if (
      groupGearUpdateStatus === "completed" &&
      groupGearUpdateError === null
    ) {
      setShowGearUpdatedModal(true);
    }
  }, [
    status,
    error,
    personalGearUpdateStatus,
    personalGearUpdateError,
    addGroupGearStatus,
    addGroupGearError,
    groupGearUpdateStatus,
    groupGearUpdateError,
  ]);

  const infoModalHandler = () => {
    setShowGearAddedModal(false);
    setShowGearUpdatedModal(false);
    props.refreshHandler();
    props.showAddGearForm(false);
  };

  return (
    <div className={"confirmation-modal modal-wrap active"}>
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-text">
            <img
              src={modalCross}
              onClick={() => {
                props.showAddGearForm(false);
              }}
              alt="modalCross"
            />
            {props.isEditing ? <h2>Edit Gear Item</h2> : <h2>Add Gear Item</h2>}
            <form
              id="gearItemForm"
              className="search-filed"
              onSubmit={formSubmitHandler}
              encType="multipart/form-data"
            >
              <ul className="list-style-none flex column-direction">
                <li>
                  <input
                    type="text"
                    id="gearName"
                    name="item_name"
                    value={enteredGearName}
                    onChange={gearNameChangeHandler}
                    onBlur={gearNameBlurHandler}
                    placeholder="Add Gear Name..."
                  />
                  {gearNameHasError && (
                    <p className="error-msg">
                      Please enter a gear name within 3-50 characters.
                    </p>
                  )}
                </li>
                <li>
                  <input
                    type="number"
                    id="gearQuantity"
                    name="item_quantity"
                    value={enteredQuantity}
                    onChange={quantityChangeHandler}
                    onBlur={quantityBlurHandler}
                    placeholder="Add Gear Quantity"
                  />
                  {quantityHasError && (
                    <p className="error-msg">
                      Please enter a valid quantity (1-9999).
                    </p>
                  )}
                </li>
                <li>
                  <textarea
                    id="gearDescription"
                    name="item_description"
                    value={enteredGearDescription}
                    onChange={gearDescriptionChangeHandler}
                    onBlur={gearDescriptionBlurHandler}
                    placeholder="Add Gear Description..."
                  ></textarea>
                  {gearDescriptionHasError && (
                    <p className="error-msg">
                      Please enter a gear description with 5-200 characters.
                    </p>
                  )}
                </li>
              </ul>
              <p>Gear Image (Optional)</p>
              <div className="gare-image">
                {/* On initial load, when there is no gear image from db */}
                {!imageIsTouched && !selectedImage && (
                  <img alt="Not Found" width={"650px"} src={NoGearImage} />
                )}
                {/* On initial load, but when there is a gear image from db */}
                {!imageIsTouched && selectedImage && (
                  <img alt="Not Found" width={"650px"} src={selectedImage} />
                )}
                {/* When user has removed the selected image. */}
                {imageIsTouched && !selectedImage && (
                  <img alt="Not Found" width={"650px"} src={NoGearImage} />
                )}
                {/* When user has selected a new image. */}
                {imageIsTouched && selectedImage && (
                  <img
                    alt="Not Found"
                    width={"650px"}
                    src={URL.createObjectURL(selectedImage)}
                  />
                )}
                <span className="side-btn">
                  <ImageInput
                    selectedImage={selectedImage}
                    setSelectedImage={setSelectedImage}
                    setImageIsTouched={setImageIsTouched}
                  />
                  {!selectedImage && <small>Select Image</small>}
                </span>
              </div>
              {!imageIsValid && imageIsTouched && (
                <p className="error-msg">
                  Please select an image (PNG, JPG, JPEG), and maximum file size
                  can be 1 MB.
                </p>
              )}
              {/* Handling Errors */}
              {error && <ApiErrors>{error}</ApiErrors>}
              {addGroupGearError && <ApiErrors>{addGroupGearError}</ApiErrors>}
              {personalGearUpdateError && (
                <ApiErrors>{personalGearUpdateError}</ApiErrors>
              )}
              {groupGearUpdateError && (
                <ApiErrors>{groupGearUpdateError}</ApiErrors>
              )}
              <button
                type="submit"
                className={`signup-btn addGearItem ${
                  gearNameIsValid && gearDescriptionIsValid && quantityIsValid
                    ? ""
                    : "disabled-link"
                } ${status === "pending" ? "disabled-link" : ""} `}
              >
                {props.isEditing ? "Edit Gear Item" : "Add Gear Item"}
                {status === "pending" && <SpinnerButton />}
              </button>
            </form>
          </div>
        </div>
      </div>
      {showGearAddedModal && (
        <ModalWithInfo
          heading="Success"
          message="Gear item is added successfully!"
          showModal={infoModalHandler}
        />
      )}
      {showGearUpdatedModal && (
        <ModalWithInfo
          heading="Success"
          message="Gear item is updated successfully!"
          showModal={infoModalHandler}
        />
      )}
    </div>
  );
};

export default AddGearItem;
