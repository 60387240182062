import React, { useRef } from "react";
import { useDispatch } from "react-redux";
import { newTripActions } from "../../store/new-trip-slice";
import crossImage from "../../assets/images/cross.svg";
import validator from "validator";

const PhoneNumbers = (props) => {
  const dispatch = useDispatch();
  const phoneRef = useRef();

  const setPhoneNumberError = props.setPhoneNumberError;
  const phoneNumbersList = props.phoneNumbersList;

  if (!phoneNumbersList[0] && localStorage.getItem("invitees")) {
    JSON.parse(localStorage.getItem("invitees")).map((invitee) =>
      dispatch(newTripActions.addInvitee(invitee))
    );
  } else {
    localStorage.setItem("invitees", JSON.stringify(phoneNumbersList));
  }

  const addPhoneHandler = (event) => {
    event.preventDefault();
    const newPhone = phoneRef.current.value;
    if (validator.isMobilePhone(newPhone + "", "en-US")) {
      setPhoneNumberError(false);
      if (phoneNumbersList[0]) {
        const phoneId = phoneNumbersList[phoneNumbersList.length - 1].id + 1;
        dispatch(newTripActions.addInvitee({ id: phoneId, number: newPhone }));
      } else {
        dispatch(newTripActions.addInvitee({ id: 0, number: newPhone }));
      }
      phoneRef.current.value = "";
    } else {
      setPhoneNumberError(true);
    }
  };

  const removePhoneHandler = (event) => {
    const idToRemove = +event.target.id;
    if (phoneNumbersList.length === 1) {
      localStorage.removeItem("invitees");
    }
    dispatch(newTripActions.removeInvitee(idToRemove));
  };
  return (
    <>
      <ul className="list-style-none phone-numbers">
        {phoneNumbersList.map(({ id, number }) => {
          return (
            <li key={`phone-${id}`}>
              <span>
                {number}
                <img
                  id={id}
                  src={crossImage}
                  alt="crossImage"
                  onClick={removePhoneHandler}
                />
              </span>
            </li>
          );
        })}
      </ul>
      <form className="add-number flex" onSubmit={addPhoneHandler}>
        <input
          ref={phoneRef}
          type="text"
          className="number-list"
          placeholder="Number"
        />
        <input type="submit" className="signup-btn addNumber" value="Add Number" />
      </form>
    </>
  );
};

export default PhoneNumbers;
