import "../../user-login/signIn.scss";
import React, { useState, useEffect } from "react";
import useInput from "../../../hooks/use-input";
import SpinnerButton from "../../../components/ui/SpinnerButton";
import useHttp from "../../../hooks/use-http";
import { validatePasswordFormat } from "../../../lib/api";
import ModalWithInfo from "../../../components/ui/ModalWithInfo";
import { changeAdminPassword } from "../../../lib/admin-api";
import AdminApiErrors from "../../../components/api-errors/AdminApiErrors";

const AdminUpdatePassword = () => {
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showActionInfoModal, setShowActionInfoModal] = useState(false);

  const { sendRequest, status, error } = useHttp(changeAdminPassword);

  const {
    value: enteredCurrentPassword,
    hasError: currentPasswordHasError,
    valueIsValid: currentPasswordIsValid,
    valueChangeHandler: currentPasswordChangeHandler,
    inputBlurHandler: currentPasswordBlurHandler,
    resetValue: resetCurrentPassword,
  } = useInput((value) => value.trim().length > 7);

  const {
    value: enteredNewPassword,
    hasError: newPasswordHasError,
    valueIsValid: newPasswordIsValid,
    valueChangeHandler: newPasswordChangeHandler,
    inputBlurHandler: newPasswordBlurHandler,
    resetValue: resetNewPassword,
  } = useInput((value) => validatePasswordFormat(value));

  const {
    value: enteredConfirmPassword,
    hasError: ConfirmPasswordHasError,
    valueIsValid: confirmPasswordIsValid,
    valueChangeHandler: confirmPasswordChangeHandler,
    inputBlurHandler: confirmPasswordBlurHandler,
    resetValue: resetConfirmPassword,
  } = useInput((value) => {
    return validatePasswordFormat(value) && value === enteredNewPassword;
  });

  const formIsValid =
    currentPasswordIsValid && newPasswordIsValid && confirmPasswordIsValid;

  const formSubmitHandler = () => {
    if (formIsValid) {
      const requestData = {
        old_password: enteredCurrentPassword,
        new_password: enteredNewPassword,
      };
      sendRequest(requestData);
    }
  };

  useEffect(() => {
    if (status === "completed" && error === null) {
      setShowActionInfoModal(true);
    }
  }, [status, error]);

  // const toggleShowPasswordHandler = () => {
  //   setShowPassword((showPassword) => !showPassword);
  // };
  const toggleShowCurrentPasswordHandler = () => {
    setShowCurrentPassword((prevState) => !prevState);
  };

  const toggleShowNewPasswordHandler = () => {
    setShowNewPassword((prevState) => !prevState);
  };

  const toggleShowConfirmPasswordHandler = () => {
    setShowConfirmPassword((prevState) => !prevState);
  };

  const infoModalConfirmationHandler = () => {
    resetConfirmPassword();
    resetCurrentPassword();
    resetNewPassword();
    setShowActionInfoModal(false);
    setShowCurrentPassword(false);
    setShowNewPassword(false);
    setShowConfirmPassword(false);
  };

  return (
    <div className="main-trail-head sign-in signin-wrapper flex flex-wrap w-100 content-justify-center">
      <div className="container adminUpdatePassword">
        <div className="trail-head-wrapper">
          <h1 className="trail-title">Update Password</h1>
          <div className="phone-numbers">
            <ul className="list-style-none">
              <li>
              <label htmlFor="current-password">Current Password</label>
              <div className="input-container">
                <input
                  type={showCurrentPassword ? "text" : "password"}
                  placeholder="Password"
                  className="number-list"
                  id="current-password"
                  value={enteredCurrentPassword}
                  onBlur={currentPasswordBlurHandler}
                  onChange={currentPasswordChangeHandler}
                />
                <i
                  className={`fa ${
                    showCurrentPassword ? "fa-eye-slash" : "fa-eye"
                  }`}
                  onClick={toggleShowCurrentPasswordHandler}
                ></i>
              </div>
                {currentPasswordHasError && (
                  <p className="error-msg">
                    Please enter a password with at least 8 characters.
                  </p>
                )}
              </li>
              <li>
              <label htmlFor="new-password">New Password</label>
              <div className="input-container">
                <input
                  type={showNewPassword ? "text" : "password"}
                  placeholder="Password"
                  className="number-list"
                  id="new-password"
                  value={enteredNewPassword}
                  onBlur={newPasswordBlurHandler}
                  onChange={newPasswordChangeHandler}
                />
                <i
                  className={`fa ${
                    showNewPassword ? "fa-eye-slash" : "fa-eye"
                  }`}
                  onClick={toggleShowNewPasswordHandler}
                ></i>
              </div>
                {newPasswordHasError && (
                  <p className="error-msg">
                    Please enter a password with at least 8 characters, and
                    password must contain a number, a capital letter, a small
                    letter, and a special character.
                  </p>
                )}
              </li>
              <li>
              <label htmlFor="confirm-password">Confirm New Password</label>
              <div className="input-container">
                <input
                  type={showConfirmPassword ? "text" : "password"}
                  placeholder="Confirm Password"
                  className="number-list"
                  id="confirm-password"
                  value={enteredConfirmPassword}
                  onBlur={confirmPasswordBlurHandler}
                  onChange={confirmPasswordChangeHandler}
                />
                <i
                  className={`fa ${
                    showConfirmPassword ? "fa-eye-slash" : "fa-eye"
                  }`}
                  onClick={toggleShowConfirmPasswordHandler}
                ></i>
              </div>
                {ConfirmPasswordHasError && (
                  <p className="error-msg">
                    Please enter a password with at least 8 characters, and
                    password must contain a number, a capital letter, a small
                    letter, and a special character. New Password and Confirm
                    Password must match.
                  </p>
                )}
              </li>
              {error && <AdminApiErrors>{error}</AdminApiErrors>}
              <li>
                <button
                  onClick={formSubmitHandler}
                  className={`signup-btn submit-btn ${
                    formIsValid ? "" : "disabled-link"
                  } ${status === "pending" ? "disabled-link" : ""}`}
                >
                  Change Password {status === "pending" && <SpinnerButton />}
                </button>
              </li>
            </ul>
          </div>
          {showActionInfoModal && (
            <ModalWithInfo
              heading="Success"
              message="Your Password is successfully updated!"
              showModal={infoModalConfirmationHandler}
            />
          )}
        </div>
      </div>
    </div>
  );
};
export default AdminUpdatePassword;
