import { Outlet } from "react-router-dom";
import { AdminHeader } from "../../components/header/AdminHeader";
import React from "react";
import AdminSidebar from "../../pages/admin/admin-sidebar/AdminSidebar";
import "./AdminLayout.scss";

export const AdminLayout = () => {
  return (
    <>
      <div className="login-layout admin-layout">
        <AdminHeader />
        <div className="admin-content">
                <AdminSidebar />
                <main className="main-content w-100">
                    <Outlet />
                </main>
            </div>
      </div>
    </>
  );
};
