import React, { useState, useEffect } from "react";
import useInput from "../../hooks/use-input";
import useHttp from "../../hooks/use-http";
import SpinnerButton from "../../components/ui/SpinnerButton";
import { submitFeedbackForm } from "../../lib/api";
import ApiErrors from "../../components/api-errors/ApiErrors";
import ModalWithInfo from "../../components/ui/ModalWithInfo";

export const FeedBack = () => {
  const [selectedType, setSelectedType] = useState("Bug/Issue");
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [showApiErrors, setShowApiErrors] = useState(false);
  const { sendRequest, status, error } = useHttp(submitFeedbackForm);

  const {
    value: enteredFeedback,
    hasError: feedbackHasError,
    valueIsValid: feedbackIsValid,
    valueChangeHandler: feedbackChangeHandler,
    inputBlurHandler: feedbackBlurHandler,
    resetValue: resetFeedback,
  } = useInput((value) => value.trim().length > 9 && value.trim().length < 501);

  const formSubmitHandler = (event) => {
    event.preventDefault();
    if (feedbackIsValid) {
      const feedbackData = {
        feedback: selectedType,
        description: enteredFeedback,
      };
      sendRequest(feedbackData);
    }
  };

  useEffect(() => {
    if (error) {
      setShowApiErrors(true);
    }
    if (status === "completed" && error === null) {
      setShowInfoModal(true);
    }
  }, [status, error]);

  const infoModalHandler = () => {
    setShowInfoModal(false);
    resetFeedback();
  };

  return (
    <>
      <div className="main-trail-head sign-in flex flex-wrap w-100 content-justify-center">
        <div className="container">
          <div className="trail-head-wrapper">
            <form onSubmit={formSubmitHandler}>
              <h1 className="trail-title">
                Feedback Form{" "}
                <span>
                  Provide your feedback for bugs, issues, suggestions for
                  improvements, or anything else you would like to say for our
                  platform!
                </span>
              </h1>
              <div className="phone-numbers">
                <ul className="list-style-none">
                  <li>
                    <label>Feedback Type</label>
                    <select
                      onChange={(event) => {
                        setSelectedType(event.target.value);
                      }}
                      className="number-list"
                    >
                      <option selected value="Bug/Issue">
                        Bug/Issue
                      </option>
                      <option value="Suggestion">Suggestion</option>
                      <option value="General Feedback">General Feedback</option>
                    </select>
                  </li>
                  <li>
                    <textarea
                      placeholder="Description"
                      className="number-list"
                      value={enteredFeedback}
                      onChange={feedbackChangeHandler}
                      onBlur={feedbackBlurHandler}
                    ></textarea>
                  </li>
                  {feedbackHasError && (
                    <p className="error-msg">
                      Feecback must be within (10-500) characters.
                    </p>
                  )}
                </ul>
              </div>
              <button
                className={`signup-btn submit-btn ${
                  feedbackIsValid ? "" : "disabled-link"
                } ${status === "pending" ? "disabled-link" : ""}`}
              >
                Submit {status === "pending" && <SpinnerButton />}
              </button>
            </form>
            {showApiErrors && <ApiErrors>{error}</ApiErrors>}
            {showInfoModal && (
              <ModalWithInfo
                heading="Thank you!"
                message="Thank you for taking the time to provided your valuable feedback."
                showModal={infoModalHandler}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};
