import React, {useEffect, useState} from "react";

import {Link, useNavigate} from "react-router-dom";
import headerLogo from "../../assets/images/header-logo.svg";
import avatarSmall from "../../assets/images/avatar-small.png";
import OutsideClickHandler from "react-outside-click-handler";
import {useDispatch, useSelector} from "react-redux";
import {authActions} from "../../store/auth-slice";
import {getNearToExpiry, logoutUser} from "../../lib/api";
import useHttp from "../../hooks/use-http";
import "./header.scss";
import {useFeature} from "../../layout/Permissions";
import {getInitials} from "../../utilities/NameInitials";

export const UserHeader = () => {
    const isViewTripDashboard = useFeature('view_trip_dashboard');
    const [isProfileDropdownOpen, setIsProfileDropdownOpen] = useState(false);
    const [isLoggingOut, setIsLoggingOut] = useState(false);
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {sendRequest, status, error} = useHttp(logoutUser);
    const {sendRequest: reqExpiry, data: dataExpiry} = useHttp(getNearToExpiry);

    const isLoggedIn = useSelector((state) => state.auth.isUserLoggedIn);
    const userImage = useSelector((state) => state.auth.userImage);
    const userName = useSelector((state) => state.auth.userName);
    const toggleProfileDropdown = () => {
        setIsProfileDropdownOpen(!isProfileDropdownOpen);
    };

    const logOutHandler = () => {
        setIsLoggingOut(true);
        sendRequest();
        dispatch(authActions.logoutUser());
    };

    useEffect(() => {
        if (isLoggingOut && status === "completed" && error === null) {
            setIsLoggingOut(false);
            navigate("/signin", {replace: true});
        }
    }, [status, error, dispatch, navigate]);

    useEffect(() => {
        reqExpiry();
    }, [])

    const handleError = (e) => {
        e.target.src = avatarSmall;
    };

    // Toggle mobile menu visibility
    const toggleMobileMenu = () => {
        setIsMobileMenuOpen(!isMobileMenuOpen);
    };

    // Function to call reqExpiry and navigate
    const handleLinkClick = () => {
        reqExpiry();
    };

    return (
        <>
            {dataExpiry?.about_to_expire && (
                <div className="notification-top-bar">
                    <p>
                        Your subscription is about to expire. Please renew your plan to
                        maintain access to our features. See the{" "}
                        <small>
                            <Link to="/my-account/choose-plan">
                                Plan
                            </Link>
                        </small>
                    </p>
                </div>
            )}
            <header className="header-wrapper">
                <div className="container flex items-center">
                    <div className="logo">
                        <Link to="/trip-plan">
                            <img src={headerLogo} alt="logo"/>
                        </Link>
                    </div>

                    {/* Hamburger Menu for Mobile */}
                    <div className="hamburgerMobile ml-auto" onClick={toggleMobileMenu}>
                        <span className="bar"></span>
                        <span className="bar"></span>
                        <span className="bar"></span>
                    </div>

                    {/* Mobile Navigation */}
                    <nav className={`mobile-menu  ${isMobileMenuOpen ? "open" : ""}`}>
                        <ul className="list-style-none">
                            {(isLoggedIn && isViewTripDashboard) && (
                                <li>
                                    <Link to="/my-account/choose-plan" onClick={() => {handleLinkClick(); toggleMobileMenu();}}>Pricing</Link>
                                </li>
                            )}
                            <li>
                                <Link to="/trip-plan" onClick={() => {handleLinkClick(); toggleMobileMenu();}}>
                                    Create Trip
                                </Link>
                            </li>
                            <li>
                                <Link to="/trip-plan" onClick={() => {handleLinkClick(); toggleMobileMenu();}}>
                                    About
                                </Link>
                            </li>
                            {isLoggedIn && isViewTripDashboard && (
                                <li>
                                    <Link to="/my-account" onClick={() => {handleLinkClick(); toggleMobileMenu();}}>
                                        My trips
                                    </Link>
                                </li>
                            )}
                        </ul>
                    </nav>
                    <div className="navigation-wrap flex">
                        <ul className="list-style-none flex items-center navigation navDeskop">
                            {(isLoggedIn && isViewTripDashboard) && (
                                <li>
                                    <Link to="/my-account/choose-plan" onClick={handleLinkClick}>Pricing</Link>
                                </li>
                            )}
                            <li>
                                <Link to="/trip-plan" onClick={handleLinkClick}>Create Trip</Link>
                            </li>
                            <li>
                                <Link to="/trip-plan" onClick={handleLinkClick}>About</Link>
                            </li>
                            {(isLoggedIn && isViewTripDashboard) ? (
                                <li>
                                    <Link to="/my-account" onClick={handleLinkClick}>My trips</Link>
                                </li>
                            ) : (
                                ""
                            )}
                        </ul>
                        {isLoggedIn ? (
                            <OutsideClickHandler
                                onOutsideClick={() => {
                                    setIsProfileDropdownOpen(false);
                                }}
                            >
                                <figure onClick={toggleProfileDropdown} className="HeaderPImage">
                                    {userImage !== "null" && userImage !== null ? (
                                        <img
                                            className="head-profile"
                                            src={userImage}
                                            alt="profile"
                                            onError={handleError}
                                            style={{
                                                width: "45px",
                                                height: "45px",
                                                objectFit: "cover",
                                            }}
                                        />
                                    ) : (
                                        <div
                                            className="initials-avatar"
                                            style={{
                                                width: "45px",
                                                height: "45px",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                backgroundColor: "#ccc",
                                                color: "#fff",
                                                borderRadius: "100%",
                                                fontSize: "12px",
                                            }}
                                        >
                                            {getInitials(userName)}
                                        </div>
                                    )}
                                    <i className="fa fa-angle-down"></i>
                                    <figcaption
                                        className={`dropdown ${
                                            isProfileDropdownOpen ? "show" : ""
                                        }`}
                                    >
                                        <Link to="/trip-plan" onClick={() => {handleLinkClick(); toggleProfileDropdown();}}>
                                            Create New Trip
                                        </Link>
                                        {isViewTripDashboard && (
                                            <Link to="/my-account" onClick={() => {handleLinkClick(); toggleProfileDropdown();}}>
                                                My trips
                                            </Link>
                                        )}
                                        <Link
                                            to="/my-account/settings"
                                            onClick={() => {handleLinkClick(); toggleProfileDropdown();}}
                                        >
                                            Settings
                                        </Link>
                                        <Link to="/signin" onClick={logOutHandler}>
                                            Logout
                                        </Link>
                                    </figcaption>
                                </figure>
                            </OutsideClickHandler>
                        ) : (
                            <Link className="header-btn" to="/signin">
                                Login
                            </Link>
                        )}
                    </div>
                </div>
            </header>
        </>
    );
};
