import React, { useEffect, useState } from "react";
import { adminGetAllTrips } from "../../../lib/admin-api";
import { SpinnerModal } from "../../../components/ui/SpinnerModal";
import useHttp from "../../../hooks/use-http";
import { AdminTripsList } from "./AdminTripsList";
import AdminTripsSearch from "./AdminTripsSearch";
import AdminApiErrors from "../../../components/api-errors/AdminApiErrors";

export const AdminAllTrips = () => {
  const [isSearching, setIsSearching] = useState(false);
  const [searchResults, setSearchResults] = useState(null);
  const { sendRequest, status, error, data } = useHttp(adminGetAllTrips);

  useEffect(() => {
    sendRequest();
  }, [sendRequest]);

  return (
    <>
      {!error ? (
        <>
          {status === "pending" || status === null ? (
            <SpinnerModal />
          ) : (
            <>
              {/* {data.allTrips ? ( */}
              <>
                <div className="admin-panel-wrap admin-all-trips flex flex-wrap w-100 content-justify-center">
                  <div className="container">
                    <h1>All Trips</h1>
                    <AdminTripsSearch
                      setIsSearching={setIsSearching}
                      setSearchResults={setSearchResults}
                    />
                    <h3>Active Trips</h3>
                    <div className="table-responsive">
                      <AdminTripsList
                        tripData={
                          isSearching
                            ? searchResults.allTrips.active
                            : data.allTrips.active
                        }
                      />
                    </div>
                    <h3>Completed Trips</h3>
                    <div className="table-responsive">
                      <AdminTripsList
                        tripData={
                          isSearching
                            ? searchResults.allTrips.completed
                            : data.allTrips.completed
                        }
                      />
                    </div>
                    <h3>Canceled Trips</h3>
                    <div className="table-responsive">
                      <AdminTripsList
                        tripData={
                          isSearching
                            ? searchResults.allTrips.canceled
                            : data.allTrips.canceled
                        }
                      />
                    </div>
                  </div>
                </div>
              </>
              {/* ) : (
                <p className="no-trip">Sorry! This trip could not be found.</p>
              )} */}
            </>
          )}
        </>
      ) : (
        <div className="main-profile-detail flex flex-wrap column-direction w-100">
          <AdminApiErrors>{error}</AdminApiErrors>
          <p className="no-trip">
            An error occured while fetching data from Backend. Please try again
            later.
          </p>
        </div>
      )}
    </>
  );
};
