import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ApiErrors from "../../components/api-errors/ApiErrors";
import useInput from "../../hooks/use-input";
import useHttp from "../../hooks/use-http";
import SpinnerButton from "../../components/ui/SpinnerButton";
import { validatePasswordFormat, resetPassword } from "../../lib/api";
import inspireLogo from "../../assets/images/inspirelogo.svg";
import "../../pages/signup/signUp.scss";

const ResetPassword = () => {
  const { sendRequest, status, error } = useHttp(resetPassword);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [successfulReset, setSuccessfulReset] = useState(false);

  const {
    value: enteredCode,
    hasError: codeHasError,
    valueIsValid: codeIsValid,
    valueChangeHandler: codeChangeHandler,
    inputBlurHandler: codeBlurHandler,
  } = useInput((value) => value.trim().length === 6);

  const {
    value: enteredPassword,
    hasError: passwordHasError,
    valueIsValid: passwordIsValid,
    valueChangeHandler: passwordChangeHandler,
    inputBlurHandler: passwordBlurHandler,
  } = useInput((value) => validatePasswordFormat(value));

  const {
    value: enteredConfirmPassword,
    hasError: ConfirmPasswordHasError,
    valueIsValid: confirmPasswordIsValid,
    valueChangeHandler: confirmPasswordChangeHandler,
    inputBlurHandler: confirmPasswordBlurHandler,
  } = useInput((value) => {
    return validatePasswordFormat(value) && value === enteredPassword;
  });

  const formIsValid = codeIsValid && passwordIsValid && confirmPasswordIsValid;

  const formSubmitHandler = (event) => {
    event.preventDefault();

    if (formIsValid) {
      const userData = {
        code: enteredCode,
        password: enteredPassword,
      };
      sendRequest(userData, true);
    } else {
      return;
    }
  };

  useEffect(() => {
    if (status === "completed" && !error) {
      setSuccessfulReset(true);
    }
    // console.log(error);
  }, [status, error]);

  const toggleShowPasswordHandler = () => {
    setShowPassword((showPassword) => !showPassword);
  };
  const toggleShowConfirmPasswordHandler = () => {
    setShowConfirmPassword((prevState) => !prevState);
  };

  return (
    <>
      <div className="main-trail-head sign-in main-signup flex flex-wrap w-100 content-justify-center">
        <div className="container">
          <div className="trail-head-wrapper signup-box">
            <Link to="/reset-password" className="logoInspire">
              <img src={inspireLogo} alt="logo" />
            </Link>
            <h1 className="trail-title">Reset Password</h1>
            <p>
              An email is sent with a reset password code, please enter the code
              from email and your new password.
            </p>
            <form onSubmit={formSubmitHandler}>
              <div className="phone-numbers">
                <ul className="list-style-none">
                  <li>
                    <div className="form-group verifyCode">
                      <i className="fa fa-calculator userIcon"></i>
                      <label>Verification Code</label>
                      <div className="input-container">
                        <input
                          type="number"
                          placeholder="6 Digit Code"
                          className="number-list"
                          id="email"
                          value={enteredCode}
                          onBlur={codeBlurHandler}
                          onChange={codeChangeHandler}
                        />
                      </div>
                    </div>
                    {codeHasError && (
                      <p className="error-msg">
                        Please enter a 6 digit code from password reset email.
                      </p>
                    )}
                  </li>
                  <li>
                    <div className="form-group">
                      <i className="fa fa-lock keyIcon"></i>
                      <label>New Password</label>
                      <div className="input-container">
                        <input
                          type={showPassword ? "text" : "password"}
                          placeholder="New Password"
                          className="number-list"
                          id="password"
                          value={enteredPassword}
                          onBlur={passwordBlurHandler}
                          onChange={passwordChangeHandler}
                        />
                        <i
                          className={`fa ${
                            showPassword ? "fa-eye-slash" : "fa-eye"
                          }`}
                          onClick={toggleShowPasswordHandler}
                        ></i>
                      </div>
                    </div>
                    {passwordHasError && (
                      <p className="error-msg">
                        Please enter a password with at least 8 characters, and
                        password must contain a number, a capital letter, a
                        small letter, and a special character.
                      </p>
                    )}
                  </li>
                  <li>
                    <div className="form-group">
                      <i className="fa fa-lock keyIcon"></i>
                      <label>Confirm New Password</label>
                      <div className="input-container">
                        <input
                          type={showConfirmPassword ? "text" : "password"}
                          placeholder="Confirm New Password"
                          className="number-list"
                          id="confirm-password"
                          value={enteredConfirmPassword}
                          onBlur={confirmPasswordBlurHandler}
                          onChange={confirmPasswordChangeHandler}
                        />
                        <i
                          className={`fa ${
                            showConfirmPassword ? "fa-eye-slash" : "fa-eye"
                          }`}
                          onClick={toggleShowConfirmPasswordHandler}
                        ></i>
                      </div>
                    </div>
                    {ConfirmPasswordHasError && (
                      <p className="error-msg">
                        Please enter a password with at least 8 characters, and
                        password must contain a number, a capital letter, a
                        small letter, and a special character. New Password and
                        Confirm Password must match.
                      </p>
                    )}
                  </li>
                  {error && <ApiErrors>{error}</ApiErrors>}
                  {error && (
                    <li>
                      <Link
                        to="/forgot-password"
                        style={{ color: "#E5B021", textDecoration: "underline" }}
                      >
                        Back To Forget Password
                      </Link>
                    </li>
                  )}
                  <li>
                    <button
                      type="submit"
                      className={`signup-btn submit-btn ${
                        formIsValid ? "" : "disabled-link"
                      } ${status === "pending" ? "disabled-link" : ""}`}
                    >
                      Reset Password{status === "pending" && <SpinnerButton />}
                    </button>
                  </li>
                </ul>
              </div>
            </form>
            {successfulReset && (
              <div className="confirm-modal">
                <div className="confirm-modal-content">
                  <h2>
                    Your password is successfully updated. Please{" "}
                    <Link to={"/signin"}>Login</Link> to your account with new
                    password.
                  </h2>
                  <section>
                    <Link to={"/signin"} className="signup-btn">
                      Login
                    </Link>
                  </section>
                </div>
              </div>
            )}
            <small className="already-account">
              Don't have an account? <Link to="/signup">Sign Up</Link>
            </small>
            <small className="already-account">
              Already have an account? <Link to="/signin">Log In</Link>
            </small>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
