import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isAdminLoggedIn: !!localStorage.getItem("admin-token"),
  adminAuthToken: localStorage.getItem("admin-token"),
};

const adminAuthSlice = createSlice({
  name: "adminAuth",
  initialState,
  reducers: {
    updateAdminAuthToken(state, action) {
      localStorage.setItem("admin-token", action.payload);
      state.adminAuthToken = action.payload;
      state.isAdminLoggedIn = true;
    },
    logoutAdmin(state) {
      localStorage.removeItem("admin-token");
      state.adminAuthToken = null;
      state.isAdminLoggedIn = false;
    },
  },
});

export const adminAuthActions = adminAuthSlice.actions;
export default adminAuthSlice.reducer;
