import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import OutsideClickHandler from "react-outside-click-handler";
import info from "../../assets/images/dots.png";
import cancelIcon from "../../assets/images/cancel.png";
import ModalWithState from "../../components/ui/ModalWithState";
import ModalWithInfo from "../../components/ui/ModalWithInfo";
import { acceptTripInvite, declineOrLeaveInvite } from "../../lib/api";
import useHttp from "../../hooks/use-http";
import ApiErrors from "../../components/api-errors/ApiErrors";

const InvitedTripCardDropdown = (props) => {
  const navigate = useNavigate();
  const inviteId = props.inviteId;
  const inviteToken = props.inviteToken;

  const {
    sendRequest: sendAcceptTripInviteRequest,
    status: acceptTripInviteStatus,
    error: acceptTripInviteError,
  } = useHttp(acceptTripInvite);
  const {
    sendRequest: sendDeclineOrLeaveRequest,
    status: declineOrLeaveRequestStatus,
    error: declineOrLeaveRequestError,
  } = useHttp(declineOrLeaveInvite);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const [actionType, setActionType] = useState("");
  const [showActionModal, setShowActionModal] = useState(false);
  const [actionConfirmation, setActionConfirmation] = useState(false);
  const [showActionInfoModal, setShowActionInfoModal] = useState(false);

  const dropdownHandler = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  //This effect handles actions related to invited trips.
  useEffect(() => {
    //Because effect runs for the first time also
    if (actionConfirmation) {
      if (actionType === "accept") {
        const token = {
          token: inviteToken,
        };
        sendAcceptTripInviteRequest(token);
      } else if (actionType === "decline" || actionType === "leave") {
        sendDeclineOrLeaveRequest(inviteId);
      }
    }
    setActionConfirmation(false);
  }, [actionConfirmation]);

  useEffect(() => {
    if (
      acceptTripInviteStatus === "completed" &&
      acceptTripInviteError === null
    ) {
      setShowActionInfoModal(true);
    }
    if (
      declineOrLeaveRequestStatus === "completed" &&
      declineOrLeaveRequestError === null
    ) {
      setShowActionInfoModal(true);
    }
  }, [
    acceptTripInviteStatus,
    acceptTripInviteError,
    declineOrLeaveRequestStatus,
    declineOrLeaveRequestError,
  ]);

  //Hide the info modal and re-render the listing.
  const infoModalConfirmationHandler = () => {
    setShowActionInfoModal(false);
    if (actionType === "leave" || actionType === "decline") {
      navigate("/my-account", { replace: true });
    }
    props.refreshHandler();
  };

  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        setIsDropdownOpen(false);
      }}
    >
      <div className="info" onClick={dropdownHandler}>
        <img src={info} alt="info" />
        <div className={`info-detail ${isDropdownOpen ? "show" : ""}`}>
          <ul className="list-style-none">
            {/* It means the invite is pending */}
            {inviteToken ? (
              <>
                <li
                  onClick={() => {
                    setActionType("accept");
                    setShowActionModal(true);
                  }}
                >
                  <img src={cancelIcon} alt="" />
                  Accept Invite
                </li>
                <li
                  onClick={() => {
                    setActionType("decline");
                    setShowActionModal(true);
                  }}
                >
                  <img src={cancelIcon} alt="" />
                  Decline Invite
                </li>
              </>
            ) : (
              <li
                onClick={() => {
                  setActionType("leave");
                  setShowActionModal(true);
                }}
              >
                <img src={cancelIcon} alt="" />
                Leave Trip
              </li>
            )}
          </ul>
        </div>
      </div>
      {showActionModal && (
        <ModalWithState
          showModal={setShowActionModal}
          onConfirmModal={setActionConfirmation}
          message={
            (actionType === "accept" &&
              "Are you sure you would like to accept this trip invite?") ||
            (actionType === "decline" &&
              "Are you sure you would like to decline this trip invite?") ||
            (actionType === "leave" &&
              "Are you sure you would like to leave this trip?")
          }
        />
      )}
      {showActionInfoModal && (
        <ModalWithInfo
          showModal={infoModalConfirmationHandler}
          heading={"Success"}
          message={
            (actionType === "accept" &&
              "You have accepted this trip invite successfully.") ||
            (actionType === "decline" && "The trip invite is declined.") ||
            (actionType === "leave" &&
              "You are no longer an attendee on this trip.")
          }
        />
      )}
      {/* Handling errors */}
      {declineOrLeaveRequestError && (
        <ApiErrors>{declineOrLeaveRequestError}</ApiErrors>
      )}
      {acceptTripInviteError && <ApiErrors>{acceptTripInviteError}</ApiErrors>}
    </OutsideClickHandler>
  );
};

export default InvitedTripCardDropdown;
