import React, { useState } from "react";
import NoGearImage from "../../../assets/images/no-gear-img.webp";
import AddGearItem from "./AddGearItem";
import AddPagination from "../../../components/ui/AddPagination";
import { getDateToStringWithoutTime } from "../../../lib/api";

import GearDropDownList from "./GearDropDownList";

export const GroupGear = (props) => {
  const [openAddGearForm, setOpenAddGearForm] = useState(false);
  const allGroupGear = [...props.myGroupGear, ...props.othersGroupGear];
  const myId = localStorage.getItem("user-id");
  const [paginatedData, setPaginatedData] = useState(allGroupGear);
  const handleError = (e) => {
    e.target.src = NoGearImage;
  };

  return (
    <>
      <div className="side-cards-wrapper">
        <ul className="list-style-none flex column-direction flex-wrap">
          {/* Printing My Group Gear */}
          {allGroupGear.length > 0 && (
            <>
              {paginatedData.map((gearItem) => (
                <li key={gearItem.id}>
                  <div className="side-cards-wrap">
                    <img
                      src={gearItem.item_image || NoGearImage}
                      alt="img"
                      onError={handleError}
                      style={{
                        width: "80px",
                        height: "80px",
                        objectFit: "cover",
                      }}
                    />
                    <section>
                      <h3>{gearItem.item_name}</h3>
                      <p>{gearItem.item_description}</p>
                      <div className="flex gear-items">
                        <p>
                          <strong>
                            <i className="fa fa-bar-chart"></i> Quantity:
                          </strong>{" "}
                          {gearItem.item_quantity}
                        </p>
                        <p>
                          <i className="fa fa-user"></i> {gearItem.username}{" "}
                        </p>
                        <p>
                          <strong>
                            <i className="fa fa-clock-o"></i> Date:
                          </strong>{" "}
                          {getDateToStringWithoutTime(
                            new Date(gearItem.created_at)
                          )}{" "}
                        </p>
                      </div>
                    </section>
                  </div>
                  {gearItem.user_id.toString() === myId ? (
                    <GearDropDownList
                      gearItem={gearItem}
                      refreshHandler={props.refreshHandler}
                      isMyGroupGear={true}
                    />
                  ) : (
                    <GearDropDownList
                      gearItem={gearItem}
                      refreshHandler={props.refreshHandler}
                      isOtherGroupGear={true}
                    />
                  )}
                </li>
              ))}
            </>
          )}
          {allGroupGear.length === 0 && (
            <li className="no-gear">No group gear is added.</li>
          )}
        </ul>
        <AddPagination
          allData={allGroupGear}
          setPaginatedData={setPaginatedData}
          pageSize={5}
        />
        <button
          className={`signup-btn addItemsGGear ${
            props.tripAccess !== "owner" &&
            props.tripAccess !== "accepted" &&
            "disabled-link"
          }`}
          onClick={() => {
            setOpenAddGearForm(true);
          }}
        >
          {props.tripAccess !== "owner" && props.tripAccess !== "accepted"
            ? "Accept Trip Invite to Add Gear"
            : "Add Items to Group Gear"}
        </button>
      </div>
      {openAddGearForm && (
        <AddGearItem
          showAddGearForm={setOpenAddGearForm}
          tripId={props.tripId}
          isMyGroupGear={true}
          refreshHandler={props.refreshHandler}
        />
      )}
    </>
  );
};
