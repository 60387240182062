import {
    Route,
    Navigate,
    RouterProvider,
    createRoutesFromElements,
    createHashRouter,
} from "react-router-dom";
import React from 'react'
import SearchBar from "../pages/create-trip/SearchBar";
import TripInvitees from "../pages/create-trip/TripInvitees";
import {SignUp} from "../pages/signup/SignUp";
import UserDashboard from "../pages/user-dashboard/UserDashboard";
import {SingleTripDetails} from "../pages/trip-details/SingleTripDetails";
import {UserLayout} from "./user-layout/UserLayout";
import SignIn from "../pages/user-login/SignIn";
import {ContactUs} from "../pages/informational-pages/ContactUs";
import {SignUpEmail} from "../pages/signup/SignUpEmail";
import UserSettings from "../pages/user-settings/UserSettings";
import {VerificationCode} from "../pages/signup/VerificationCode";
import {AdminLayout} from "./admin-layout/AdminLayout";
import {AdminAllUsers} from "../pages/admin/admin-users/AdminAllUsers";
import {AdminAllTrips} from "../pages/admin/admin-trips/AdminAllTrips";
import {AdminViewTripDetails} from "../pages/admin/admin-trips/AdminViewTripDetails";
import {AdminViewUserDetails} from "../pages/admin/admin-users/AdminViewUserDetails";
import AdminUpdatePassword from "../pages/admin/admin-settings/AdminUpdatePassword";
import {Error} from "../pages/error-page/Error";
import {AdminEditUser} from "../pages/admin/admin-users/AdminEditUser";
import {UpdateTrip} from "../pages/update-trip/UpdateTrip";
import {SplashPage} from "../pages/splash-page/SplashPage";
import {PrivacyPolicy} from "../pages/informational-pages/PrivacyPolicy";
import {FeedBack} from "../pages/informational-pages/FeedBack";
import {useSelector} from "react-redux";
import AdminLogin from "../pages/admin/admin-login/AdminLogin";
import ForgotPassword from "../pages/user-login/ForgotPassword";
import ResetPassword from "../pages/user-login/ResetPassword";
import SocialLoginPhoneUpdate from "../pages/user-login/SocialLoginPhoneUpdate";
import ProtectedRoute from "./ProtectedRoute";
import OnlyPublicRoute from "./OnlyPublicRoute";
import AdminRoute from "./AdminRoute";
import CheckSocialLoginWithoutPhone from "./CheckSocialLoginWithoutPhone";
import {AdminDashboard} from "../pages/admin/admin-dashboard/index";

export const Main = () => {
    const isAdminLoggedIn = useSelector(
        (state) => state.adminAuth.isAdminLoggedIn
    );
    const allRoutes = (
        <>
            <Route
                path="/"
                element={
                    <CheckSocialLoginWithoutPhone>
                        <SplashPage/>
                    </CheckSocialLoginWithoutPhone>
                }
            />
            <Route path="/privacy-policy" element={<UserLayout/>}>
                <Route
                    index
                    element={
                        <CheckSocialLoginWithoutPhone>
                            <PrivacyPolicy/>
                        </CheckSocialLoginWithoutPhone>
                    }
                />
            </Route>

            <Route path="/signin" >
                <Route
                    index
                    element={
                        <OnlyPublicRoute>
                            <SignIn/>
                        </OnlyPublicRoute>
                    }
                />
            </Route>
            <Route path="/phone-number-required" element={<UserLayout/>}>
                <Route index element={<SocialLoginPhoneUpdate/>}/>
            </Route>
            <Route path="/forgot-password" >
                <Route
                    index
                    element={
                        <OnlyPublicRoute>
                            <ForgotPassword/>
                        </OnlyPublicRoute>
                    }
                />
            </Route>
            <Route path="/reset-password" >
                <Route
                    index
                    element={
                        <OnlyPublicRoute>
                            <ResetPassword/>
                        </OnlyPublicRoute>
                    }
                />
            </Route>
            <Route path="/contact" element={<UserLayout/>}>
                <Route
                    index
                    element={
                        <CheckSocialLoginWithoutPhone>
                            <ContactUs/>
                        </CheckSocialLoginWithoutPhone>
                    }
                />
            </Route>
            <Route path="/feedback" element={<UserLayout/>}>
                <Route
                    index
                    element={
                        <ProtectedRoute>
                            <FeedBack/>
                        </ProtectedRoute>
                    }
                />
            </Route>
            <Route path="/signup" >
                <Route
                    index
                    element={
                        <OnlyPublicRoute>
                            <SignUp/>
                        </OnlyPublicRoute>
                    }
                />
                <Route
                    path="/signup/email"
                    element={
                        <OnlyPublicRoute>
                            <SignUpEmail/>
                        </OnlyPublicRoute>
                    }
                />
                <Route
                    path="/signup/verification"
                    element={
                        <OnlyPublicRoute>
                            <VerificationCode/>
                        </OnlyPublicRoute>
                    }
                />
            </Route>
            <Route
                path="/trip-plan"
                element={<UserLayout/>}
                errorElement={<SearchBar/>}
            >
                <Route
                    index
                    element={
                        <CheckSocialLoginWithoutPhone>
                            <SearchBar/>
                        </CheckSocialLoginWithoutPhone>
                    }
                />
                <Route
                    path="/trip-plan/confirm-trip-details"
                    element={
                        <CheckSocialLoginWithoutPhone>
                            <TripInvitees/>
                        </CheckSocialLoginWithoutPhone>
                    }
                />
            </Route>
            <Route path="/my-account" element={<UserLayout/>}>
                <Route
                    index
                    element={
                        <ProtectedRoute>
                            <UserDashboard/>
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="trip-details/:id"
                    element={
                        <ProtectedRoute>
                            <SingleTripDetails/>
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/my-account/settings"
                    element={
                        <ProtectedRoute>
                            <UserSettings/>
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="update-trip/:id"
                    element={
                        <ProtectedRoute>
                            <UpdateTrip/>
                        </ProtectedRoute>
                    }
                />
            </Route>

            {/* Routes for admin side */}

            <Route
                path="/admin-32423QeCFer/admin-login"
                element={
                    !isAdminLoggedIn ? (
                        <AdminLogin/>
                    ) : (
                        <Navigate to="/admin-32423QeCFer" replace/>
                    )
                }
            />

            <Route path="/admin-32423QeCFer" element={<AdminLayout/>}>
                <Route
                    index
                    element={
                        <AdminRoute>
                            <AdminDashboard/>
                        </AdminRoute>
                    }
                />
                <Route
                    path="/admin-32423QeCFer/all-users"
                    element={
                        <AdminRoute>
                            <AdminAllUsers/>
                        </AdminRoute>
                    }
                />
                <Route
                    path="/admin-32423QeCFer/all-trips"
                    element={
                        <AdminRoute>
                            <AdminAllTrips/>
                        </AdminRoute>
                    }
                />
                <Route
                    path="user-details/:id"
                    element={
                        <AdminRoute>
                            <AdminViewUserDetails/>
                        </AdminRoute>
                    }
                />
                <Route
                    path="trip-details/:id"
                    element={
                        <AdminRoute>
                            <AdminViewTripDetails/>
                        </AdminRoute>
                    }
                />
                <Route
                    path="/admin-32423QeCFer/update-password"
                    element={
                        <AdminRoute>
                            <AdminUpdatePassword/>
                        </AdminRoute>
                    }
                />
                <Route
                    path="edit-user-details/:id"
                    element={
                        <AdminRoute>
                            <AdminEditUser/>
                        </AdminRoute>
                    }
                />
            </Route>
            <Route path="*" element={<Error/>}/>
        </>
    );
    const router = createHashRouter(createRoutesFromElements(allRoutes));
    return <RouterProvider router={router}/>;
};

// export const Main = () => {
//   const isUserLoggedIn = useSelector((state) => state.auth.isUserLoggedIn);
//   const isAdminLoggedIn = useSelector(
//     (state) => state.adminAuth.isAdminLoggedIn
//   );

//   const router = createHashRouter(
//     createRoutesFromElements(
//       <>
//         <Route path="/" element={<SplashPage />} />
//         <Route path="/privacy-policy" element={<UserLayout />}>
//           <Route index element={<PrivacyPolicy />} />
//         </Route>

//         <Route path="/signin" element={<UserLayout />}>
//           <Route
//             index
//             element={
//               !isUserLoggedIn ? (
//                 <SignIn />
//               ) : (
//                 <Navigate to="/my-account" replace />
//               )
//             }
//           />
//         </Route>
//         <Route path="/phone-number-required" element={<UserLayout />}>
//           <Route index element={<SocialLoginPhoneUpdate />} />
//         </Route>
//         <Route path="/forgot-password" element={<UserLayout />}>
//           <Route
//             index
//             element={
//               !isUserLoggedIn ? (
//                 <ForgotPassword />
//               ) : (
//                 <Navigate to="/my-account" replace />
//               )
//             }
//           />
//         </Route>
//         <Route path="/reset-password" element={<UserLayout />}>
//           <Route
//             index
//             element={
//               !isUserLoggedIn ? (
//                 <ResetPassword />
//               ) : (
//                 <Navigate to="/my-account" replace />
//               )
//             }
//           />
//         </Route>
//         <Route path="/contact" element={<UserLayout />}>
//           <Route index element={<ContactUs />} />
//         </Route>
//         <Route path="/feedback" element={<UserLayout />}>
//           <Route
//             index
//             element={
//               isUserLoggedIn ? <FeedBack /> : <Navigate to="/signin" replace />
//             }
//           />
//         </Route>
//         <Route path="/signup" element={<UserLayout />}>
//           <Route
//             index
//             element={
//               !isUserLoggedIn ? (
//                 <SignUp />
//               ) : (
//                 <Navigate to="/my-account" replace />
//               )
//             }
//           />
//           <Route
//             path="/signup/email"
//             element={
//               !isUserLoggedIn ? (
//                 <SignUpEmail />
//               ) : (
//                 <Navigate to="/my-account" replace />
//               )
//             }
//           />
//           <Route
//             path="/signup/verification"
//             element={
//               !isUserLoggedIn ? (
//                 <VerificationCode />
//               ) : (
//                 <Navigate to="/my-account" replace />
//               )
//             }
//           />
//         </Route>
//         <Route
//           path="/trip-plan"
//           element={<UserLayout />}
//           errorElement={<SearchBar />}
//         >
//           <Route index element={<SearchBar />} />
//           <Route
//             path="/trip-plan/confirm-trip-details"
//             element={<TripInvitees />}
//           />
//         </Route>
//         <Route path="/my-account" element={<UserLayout />}>
//           <Route
//             index
//             element={
//               isUserLoggedIn ? (
//                 <UserDashboard />
//               ) : (
//                 <Navigate to="/signin" replace />
//               )
//             }
//           />
//           <Route
//             path="trip-details/:id"
//             element={
//               isUserLoggedIn ? (
//                 <SingleTripDetails />
//               ) : (
//                 <Navigate to="/signin" replace />
//               )
//             }
//           />
//           <Route
//             path="/my-account/settings"
//             element={
//               isUserLoggedIn ? (
//                 <UserSettings />
//               ) : (
//                 <Navigate to="/signin" replace />
//               )
//             }
//           />
//           <Route
//             path="update-trip/:id"
//             element={
//               isUserLoggedIn ? (
//                 <UpdateTrip />
//               ) : (
//                 <Navigate to="/signin" replace />
//               )
//             }
//           />
//         </Route>
//         <Route path="/admin-32423QeCFer" element={<AdminLayout />}>
//           <Route
//             index
//             element={
//               isAdminLoggedIn ? (
//                 <AdminAllUsers />
//               ) : (
//                 <Navigate to="/admin-32423QeCFer/admin-login" replace />
//               )
//             }
//           />
//           <Route
//             path="/admin-32423QeCFer/admin-login"
//             element={
//               !isAdminLoggedIn ? (
//                 <AdminLogin />
//               ) : (
//                 <Navigate to="/admin-32423QeCFer" replace />
//               )
//             }
//           />
//           <Route
//             path="/admin-32423QeCFer/all-trips"
//             element={
//               isAdminLoggedIn ? (
//                 <AdminAllTrips />
//               ) : (
//                 <Navigate to="/admin-32423QeCFer/admin-login" replace />
//               )
//             }
//           />
//           <Route
//             path="user-details/:id"
//             element={
//               isAdminLoggedIn ? (
//                 <AdminViewUserDetails />
//               ) : (
//                 <Navigate to="/admin-32423QeCFer/admin-login" replace />
//               )
//             }
//           />
//           <Route
//             path="trip-details/:id"
//             element={
//               isAdminLoggedIn ? (
//                 <AdminViewTripDetails />
//               ) : (
//                 <Navigate to="/admin-32423QeCFer/admin-login" replace />
//               )
//             }
//           />
//           <Route
//             path="/admin-32423QeCFer/update-password"
//             element={
//               isAdminLoggedIn ? (
//                 <AdminUpdatePassword />
//               ) : (
//                 <Navigate to="/admin-32423QeCFer/admin-login" replace />
//               )
//             }
//           />
//           <Route
//             path="edit-user-details/:id"
//             element={
//               isAdminLoggedIn ? (
//                 <AdminEditUser />
//               ) : (
//                 <Navigate to="/admin-32423QeCFer/admin-login" replace />
//               )
//             }
//           />
//         </Route>
//         <Route path="*" element={<Error />} />
//       </>
//     )
//   );
//   return <RouterProvider router={router} />;
// };
