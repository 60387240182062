import React, { useEffect, useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import checkIcon from "../../../assets/images/checked.png";
import info from "../../../assets/images/dots.png";
import { deletePersonalGearItem, deleteGroupGearItem } from "../../../lib/api";
import useHttp from "../../../hooks/use-http";
import ModalWithState from "../../../components/ui/ModalWithState";
import EditGearItem from "./EditGearItem";
import ApiErrors from "../../../components/api-errors/ApiErrors";

const GearDropDownList = (props) => {
  const gearItem = props.gearItem;
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [editGearItem, setEditGearItem] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [actionConfirmation, setActionConfirmation] = useState(false);

  const { sendRequest, status, error } = useHttp(deletePersonalGearItem);
  const {
    sendRequest: sendDeleteGroupGearRequest,
    status: deleteGroupGearStatus,
    error: deleteGroupGearError,
  } = useHttp(deleteGroupGearItem);

  const dropdownHandler = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  useEffect(() => {
    if (actionConfirmation) {
      if (props.isMyGroupGear || props.isOtherGroupGear) {
        const gearData = {
          gearId: gearItem.id,
          data: {
            my_trip_id: gearItem.my_trip_id,
          },
        };
        sendDeleteGroupGearRequest(gearData);
      } else {
        sendRequest(gearItem.id);
      }
    }
  }, [actionConfirmation]);

  useEffect(() => {
    if (status === "completed" && error === null) {
      props.refreshHandler();
    }
    if (
      deleteGroupGearStatus === "completed" &&
      deleteGroupGearError === null
    ) {
      props.refreshHandler();
    }
  }, [status, error, deleteGroupGearStatus, deleteGroupGearError]);

  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        setIsDropdownOpen(false);
      }}
    >
      <div className="info" onClick={dropdownHandler}>
        <img src={info} alt="info" />
        <div className={`info-detail ${isDropdownOpen ? "show" : ""}`}>
          {/* Only show edit option if it is user's own gear item */}
          {!props.isOtherGroupGear && (
            <span
              onClick={() => {
                setEditGearItem(true);
              }}
            >
              <img src={checkIcon} alt="" />
              Edit Item
            </span>
          )}
          <span
            onClick={() => {
              setShowDeleteModal(true);
            }}
          >
            <img src={checkIcon} alt="" />
            Delete Item
          </span>
        </div>
      </div>
      {editGearItem && (
        <EditGearItem
          showEditGearForm={setEditGearItem}
          gearItem={gearItem}
          refreshHandler={props.refreshHandler}
          isMyGroupGear={props.isMyGroupGear}
        />
      )}
      {showDeleteModal && (
        <ModalWithState
          message={"Are you sure you would like to delete this Gear Item?"}
          onConfirmModal={setActionConfirmation}
          showModal={setShowDeleteModal}
        />
      )}
      {error && <ApiErrors>{error}</ApiErrors>}
      {deleteGroupGearError && <ApiErrors>{deleteGroupGearError}</ApiErrors>}
    </OutsideClickHandler>
  );
};

export default GearDropDownList;
