import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./admin-signin.scss";
import useInput from "../../../hooks/use-input";
import useHttp from "../../../hooks/use-http";
import { loginAdmin } from "../../../lib/admin-api";
import SpinnerButton from "../../../components/ui/SpinnerButton";
import { useDispatch } from "react-redux";
import { adminAuthActions } from "../../../store/admin-auth-slice";
import AdminApiErrors from "../../../components/api-errors/AdminApiErrors";
import inspireLogo from "../../../assets/images/inspirelogo.svg";

const AdminLogin = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [showApiErrors, setShowApiErrors] = useState(false);
  const { sendRequest, status, error, data } = useHttp(loginAdmin);

  const {
    value: enteredEmail,
    hasError: emailHasError,
    valueIsValid: emailIsValid,
    valueChangeHandler: emailChangeHandler,
    inputBlurHandler: emailBlurHandler,
  } = useInput((value) => value.includes("@"));

  const {
    value: enteredPassword,
    hasError: passwordHasError,
    valueIsValid: passwordIsValid,
    valueChangeHandler: passwordChangeHandler,
    inputBlurHandler: passwordBlurHandler,
  } = useInput((value) => value.trim().length > 7);

  const formIsValid = emailIsValid && passwordIsValid;

  const formSubmitHandler = (event) => {
    event.preventDefault();
    setShowApiErrors(false);

    if (formIsValid) {
      const adminData = {
        email: enteredEmail,
        password: enteredPassword,
      };
      sendRequest(adminData, true);
    } else {
      return;
    }
  };

  useEffect(() => {
    if (error) {
      setShowApiErrors(true);
    }

    if (status === "completed" && error === null) {
      dispatch(adminAuthActions.updateAdminAuthToken(data));
      //Where to redirect?
      navigate("/admin-32423QeCFer", { replace: true });
    }
  }, [status, error, data]);

  // const toggleShowPasswordHandler = () => {
  //   setShowPassword((showPassword) => !showPassword);
  // };
  const toggleShowPasswordHandler = () => {
    setShowPassword((showPassword) => !showPassword);
  };

  return (
    <>
      <div className="main-trail-head sign-in main-signup admin-login-screen flex flex-wrap w-100 content-justify-center">
        <div className="container">
          <div className="trail-head-wrapper signup-box">
            <Link to="/admin-32423QeCFer" className="logoInspire">
              <img src={inspireLogo} alt="logo" />
            </Link>
            <h1 className="trail-title">Admin Login</h1>
            <form onSubmit={formSubmitHandler}>
              <div className="phone-numbers">
                <ul className="list-style-none">
                  <li>
                    <div className="form-group">
                      <i className="fa fa-envelope emailIcon"></i>
                      <label>Enter Email</label>
                      <div className="input-container">
                        <input
                          type="email"
                          placeholder="Enter Email Address"
                          className="number-list"
                          id="email"
                          value={enteredEmail}
                          onBlur={emailBlurHandler}
                          onChange={emailChangeHandler}
                        />
                      </div>
                    </div>
                    {emailHasError && (
                      <p className="error-msg">Please enter a valid Email.</p>
                    )}
                  </li>

                  <li>
                    <div className="form-group">
                      <i className="fa fa-lock keyIcon"></i>
                      <label>Password</label>
                      <div className="input-container">
                        <input
                          type={showPassword ? "text" : "password"}
                          placeholder="Password"
                          className="number-list"
                          id="password"
                          value={enteredPassword}
                          onBlur={passwordBlurHandler}
                          onChange={passwordChangeHandler}
                        />
                        <i
                          className={`fa ${
                            showPassword ? "fa-eye-slash" : "fa-eye"
                          }`}
                          onClick={toggleShowPasswordHandler}
                        ></i>
                      </div>
                    </div>
                    {passwordHasError && (
                      <p className="error-msg">
                        Please enter a password with at least 8 characters.
                      </p>
                    )}
                  </li>
                  {showApiErrors && <AdminApiErrors>{error}</AdminApiErrors>}
                  <li>
                    <button
                      type="submit"
                      className={`signup-btn submit-btn ${
                        formIsValid ? "" : "disabled-link"
                      } ${status === "pending" ? "disabled-link" : ""}`}
                    >
                      Login{status === "pending" && <SpinnerButton />}
                    </button>
                  </li>
                </ul>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminLogin;
