import spinnerIcon from "../../assets/images/loader1.gif"
import React from 'react'

const SpinnerButton = () => {
    return(
        <img className="spinner-btn" src={spinnerIcon} alt={spinnerIcon}/>
    )
}

export default SpinnerButton;
