import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import GoogleMapReact from "google-map-react";
import hikingIcon from "../../assets/images/hiking.svg";
import { useNavigate } from "react-router-dom";
import "./tripInvitees.scss";
import { newTripActions } from "../../store/new-trip-slice";
import useHttp from "../../hooks/use-http";
import { addNewTrip } from "../../lib/api";
import SpinnerButton from "../../components/ui/SpinnerButton";
import ApiErrors from "../../components/api-errors/ApiErrors";
import PhoneNumbers from "./PhoneNumbers";

const MapPin = () => <div className="map-pin-text" />;

const TripInvitees = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { sendRequest, status, error } = useHttp(addNewTrip);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [phoneNumberError, setPhoneNumberError] = useState(false);
  const [showApiErrors, setShowApiErrors] = useState(false);
  const [publicTrip, setPublicTrip] = useState("true");
  const location = useSelector((state) => state.newTrip.location);
  const activity = useSelector((state) => state.newTrip.activity);
  const dateRange = useSelector((state) => state.newTrip.dateRange);
  const isLoggedIn = useSelector((state) => state.auth.isUserLoggedIn);
  const phoneNumbersList = useSelector((state) => state.newTrip.invitees);
  // console.log('exacet after init', location)
  let displayDate;
  if (dateRange[1]) {
    displayDate = `${dateRange[0]} - ${dateRange[1]}`;
  } else {
    displayDate = dateRange[0];
  }

  //If user is not loggedin
  const signupClickHandler = () => {
    setShowApiErrors(false);
    if (phoneNumbersList.length === 0) {
      setShowErrorModal(true);
    } else {
      createTripHandlerFromModal();
    }
  };

  //If no invitees are added, proceed to signup or create trip
  const createTripHandlerFromModal = () => {
    setShowErrorModal(false);
    setShowApiErrors(false);
    if (!isLoggedIn) {
      navigate("/signup");
    } else {
      createTripHandler();
    }
  };

  //Create Trip
  const createTripHandler = () => {
    const phoneNumbersWithoutIDs = phoneNumbersList.map(
      (phone) => phone.number
    );
    const tripData = {
      activity,
      phone_numbers: phoneNumbersWithoutIDs,
      location: location?.placeName,
      start_date: dateRange[0],
      end_date: dateRange[1] ? dateRange[1] : null,
      latitude: location?.lat,
      longitude: location?.lng,
      public: publicTrip,
    };
    sendRequest(tripData, true);
  };

  useEffect(() => {
    if (error) {
      setShowApiErrors(true);
    }
    //If the page is loaded with a link and the location is not selected on the first page then navigate user to the first step.
    if (location?.placeName === null) {
      // console.log('I am here', location);
      navigate("/trip-plan", { replace: true });
    }
    if (status === "completed" && error === null) {
      dispatch(newTripActions.resetAllTripData());
      navigate("/my-account", { replace: true });
    }
  }, [status, error]);

  return (
    <>
      <div className="main-trail-head confirm-trip flex flex-wrap w-100 content-justify-center">
        <div className="container">
          <div className="trail-head-wrapper">
            <h1 className="trail-title">Your "{activity}" Trip</h1>
            <section className="trip-date">
              <span>
                <i className="fa fa-calendar"></i> {displayDate}
              </span>
              <span>
                <i className="fa fa-map-marker"></i> {location?.placeName}
              </span>
            </section>
            <div className="map-wrapper">
              <GoogleMapReact
                bootstrapURLKeys={{
                  key: "AIzaSyCOkkRRCQ2CF7KvrvLLm9P4mc_hQEmHk8w",

                }}
                center={{
                  lat: location?.lat,
                  lng: location?.lng,
                }}
                defaultZoom={11}
              >
                <MapPin lat={location?.lat} lng={location?.lng} pinText="Map" />
              </GoogleMapReact>
              <div className="notification">
                <img src={hikingIcon} alt="Hiking" />
                <span>{activity}</span>
              </div>
            </div>
            <label htmlFor="active_private" className="switch">
              <input type="checkbox" id="active_private"/>
              <div className="slider round"></div>
              {publicTrip === "true" ? (
                  <span
                      onClick={() => {
                        setPublicTrip("false");
                      }}
                  >
                      Trip is public
                    </span>
              ) : (
                  <span
                      onClick={() => {
                        setPublicTrip("true");
                      }}
                  >
                      Trip is private
                    </span>
              )}
            </label>
            <h2 className="trail-title">who's joining?</h2>
            <PhoneNumbers
              setPhoneNumberError={setPhoneNumberError}
              phoneNumbersList={phoneNumbersList}
            />
            {showApiErrors && <ApiErrors>{error}</ApiErrors>}
            {phoneNumberError && (
              <span className="error-msg">
                Please enter a valid phone number with 10 digits.
              </span>
            )}
            <p>
              You can optionally add/remove phone numbers here to send trip
              invites!
            </p>
            {showErrorModal && (
              <div className="confirm-modal">
                <div className="confirm-modal-content">
                  <h2>
                    Are you sure you would not like to invite anyone for the
                    trip?
                  </h2>
                  <section>
                    <button
                      className="signup-btn hover-btn"
                      onClick={() => {
                        setShowErrorModal(false);
                      }}
                    >
                      Invite Friends
                    </button>
                    <button
                      className="signup-btn"
                      onClick={createTripHandlerFromModal}
                    >
                      YES
                    </button>{" "}
                  </section>
                </div>
              </div>
            )}
            {isLoggedIn ? (
              <button
                className={`signup-btn addCreateTrip ${
                  status === "pending" ? "disabled-link" : ""
                }`}
                onClick={signupClickHandler}
              >
                Create Trip{status === "pending" && <SpinnerButton />}
              </button>
            ) : (
              <button className="signup-btn addCreateTrip" onClick={signupClickHandler}>
                Sign Up / Login To Create Trip
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default TripInvitees;
