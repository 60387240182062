// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.googleLogin {
  display: flex;
  justify-content: space-between;
  background: none;
  padding: 10px;
  border: 1px solid #bec0c5;
  border-radius: 5px;
  align-items: center;
  width: 100%;
}
.googleLogin span {
  width: 100%;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  color: #3c4043;
  letter-spacing: 0.25px;
  font-size: 14px;
}`, "",{"version":3,"sources":["webpack://./src/pages/google/style.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,8BAAA;EACA,gBAAA;EACA,aAAA;EACA,yBAAA;EACA,kBAAA;EACA,mBAAA;EACA,WAAA;AACF;AAAE;EACE,WAAA;EACA,iCAAA;EACA,gBAAA;EACA,cAAA;EACA,sBAAA;EACA,eAAA;AAEJ","sourcesContent":[".googleLogin {\n  display: flex;\n  justify-content: space-between;\n  background: none;\n  padding: 10px;\n  border: 1px solid #bec0c5;\n  border-radius: 5px;\n  align-items: center;\n  width: 100%;\n  span {\n    width: 100%;\n    font-family: \"Roboto\", sans-serif;\n    font-weight: 500;\n    color: #3c4043;\n    letter-spacing: 0.25px;\n    font-size: 14px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
