// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.update-trip {
  min-height: calc(100vh - 159px);
}
.update-trip .field-wrapper > button {
  padding: 0;
  border-radius: 0;
}
.update-trip .field-wrapper > button.expanded {
  background: transparent;
}
.update-trip .field-wrapper .search-option-drop {
  z-index: 1;
  width: 100%;
  left: 0;
  padding: 12px 18px 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}
.update-trip .field-wrapper .search-option-drop li {
  margin-bottom: 0;
  text-align: left;
  font-size: 14px;
}
.update-trip .field-wrapper .guest-list li {
  margin: 5px 0;
}
.update-trip .items-wrapper {
  z-index: 999;
  width: 100%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}
.update-trip .trail-head-wrapper .phone-numbers > ul > li {
  margin-bottom: 10px;
}
.update-trip .trail-head-wrapper .phone-numbers > ul > li span {
  padding: 15px 16px;
}
.update-trip .trail-head-wrapper .phone-numbers > ul > li strong {
  text-align: left;
  display: block;
}
.update-trip .trail-head-wrapper .phone-numbers > ul > li:last-child {
  margin-top: 20px;
}
.update-trip .react-calendar {
  z-index: 9;
}
.update-trip .react-calendar__navigation__label__labelText {
  padding: 0 !important;
  border: none !important;
}

.update-trip {
  padding: 30px 0;
}`, "",{"version":3,"sources":["webpack://./src/pages/update-trip/updateTrip.scss"],"names":[],"mappings":"AACA;EACE,+BAAA;AAAF;AAEI;EACE,UAAA;EACA,gBAAA;AAAN;AACM;EAAa,uBAAA;AAEnB;AAAI;EACE,UAAA;EACA,WAAA;EACA,OAAA;EACA,sBAAA;EACA,uCAAA;AAEN;AADM;EACE,gBAAA;EACA,gBAAA;EACA,eAAA;AAGR;AACM;EAAK,aAAA;AAEX;AACE;EACE,YAAA;EACA,WAAA;EACA,uCAAA;AACJ;AAIQ;EACE,mBAAA;AAFV;AAGU;EAAM,kBAAA;AAAhB;AACU;EACE,gBAAA;EACA,cAAA;AACZ;AACU;EAAe,gBAAA;AAEzB;AAGE;EAAkB,UAAA;AAApB;AACE;EAAgD,qBAAA;EAAuB,uBAAA;AAGzE;;AADA;EAAe,eAAA;AAKf","sourcesContent":["@import \"../../assets/scss/mixin\";\n.update-trip {\n  min-height: calc(100vh - 159px);\n  .field-wrapper {\n    & > button {\n      padding: 0;\n      border-radius: 0;\n      &.expanded { background: transparent;}\n    }\n    .search-option-drop {\n      z-index: 1;\n      width: 100%;\n      left: 0;\n      padding: 12px 18px 8px;\n      box-shadow: 0 0 10px rgba(0,0,0,0.2);\n      li {\n        margin-bottom: 0;\n        text-align: left;\n        font-size: 14px;\n      }\n    }\n    .guest-list {\n      li { margin: 5px 0;}\n    }\n  }\n  .items-wrapper {\n    z-index: 999;\n    width: 100%;\n    box-shadow: 0 0 10px rgba(0,0,0,0.2);\n  }\n  .trail-head-wrapper {\n    .phone-numbers {\n      &>ul {\n        & > li {\n          margin-bottom: 10px;\n          span {padding: 15px 16px}\n          strong {\n            text-align: left;\n            display: block;\n          }\n          &:last-child { margin-top: 20px;}\n        }\n      }\n    }\n  }\n  .react-calendar { z-index: 9;}\n  .react-calendar__navigation__label__labelText { padding: 0 !important; border: none!important;}\n}\n.update-trip { padding: 30px 0}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
