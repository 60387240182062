import { useDispatch } from "react-redux";
import { newTripActions } from "../../store/new-trip-slice";
import { usePlacesWidget } from "react-google-autocomplete";
import React from 'react'

export const Location = (props) => {
  const dispatch = useDispatch();

  const { ref } = usePlacesWidget({
    apiKey: "AIzaSyCOkkRRCQ2CF7KvrvLLm9P4mc_hQEmHk8w",
    onPlaceSelected: (place) => {
      //rounding offf the lat/lng to 4 decimal places.
      const lat = Math.round(place.geometry.location.lat() * 10000) / 10000;
      const lng = Math.round(place.geometry.location.lng() * 10000) / 10000;

      const selectedPlace = {
        lat: lat,
        lng: lng,
        placeName: place.formatted_address,
      };

      if (props.isEditing) {
        props.setLocationHasError(false);
        props.setLocation(selectedPlace);
      } else {
        dispatch(newTripActions.updateLocation(selectedPlace));
        //storing in local storage also in case of page reload
        localStorage.setItem("lat", selectedPlace.lat);
        localStorage.setItem("lng", selectedPlace.lng);
        localStorage.setItem("location", selectedPlace.placeName);
        props.updateLocationSelected(true);
      }
    },
  });

  const locationChangeHandler = () => {
    props.setLocationTouched(true);
    if (ref.current.value.trim() === "") {
      {
        props.isEditing
          ? props.setLocationHasError(true)
          : props.updateLocationSelected(false);
      }
    }
  };

  return (
    <>
      <div className="field-wrapper">
        <form>
          <strong>Location</strong>
          <input
            type="text"
            ref={ref}
            color="red"
            defaultValue={props.location && props.location.placeName} //Only works when editing.
            placeholder="Type Location..."
            className="number-list"
            onChange={locationChangeHandler}
            onBlur={locationChangeHandler}
          />
        </form>
      </div>
    </>
  );
};
