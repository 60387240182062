import React, { useState } from "react";
import modalCross from "../../../assets/images/cross.svg";
import MyTripCardDropdown from "../../user-dashboard/MyTripCardDropdown";
import { getDateToStringWithoutTime } from "../../../lib/api";
import GoogleMapReact from "google-map-react";
import InvitedTripCardDropdown from "../../user-dashboard/InvitedTripCardDropdown";
import AttendingUsersAvatars from "../../user-dashboard/AttendingUsersAvatars";

const MapPin = () => <div className="map-pin-text" />;

export const TripDetailsCover = (props) => {
  const tripData = props.tripData;

  const [modalOpen, setModalOpen] = useState(false);
  const openModal = () => {
    setModalOpen(!modalOpen);
  };
  return (
    <>
      <div className="profile-cover flex flex-wrap w-100">
        <div className="google-map">
          <GoogleMapReact
            bootstrapURLKeys={{
              key: "AIzaSyCOkkRRCQ2CF7KvrvLLm9P4mc_hQEmHk8w",
            }}
            center={{
              lat: +tripData.latitude,
              lng: +tripData.longitude,
            }}
            defaultZoom={12}
          >
            <MapPin
              lat={+tripData.latitude}
              lng={+tripData.longitude}
              pinText="Map"
            />
          </GoogleMapReact>
        </div>
        <div className="container">
          <div className="trip-detail-wrapper">
            <h1>
              {tripData.trip_access === "owner"
                ? "Your "
                : `${
                    tripData.invite_sender_name
                      ? tripData.invite_sender_name
                      : // In case a user who declined a trip is viewing it by URL
                        tripData.user_name
                  }'s `}
              {tripData.activity} Trip
            </h1>
            <span className="location-wrap">
              <i className="fa fa-map-marker"></i> {tripData.location}
            </span>
            <p>
              <i className="fa fa-calendar-o"></i>{" "}
              {getDateToStringWithoutTime(new Date(tripData.start_date))}{" "}
              {tripData.end_date
                ? `- ${getDateToStringWithoutTime(new Date(tripData.end_date))}`
                : ""}
            </p>
            <AttendingUsersAvatars attendingUsers={props.attendingUsers} />
            <p>
              {tripData.created_at && (
                <span className="created-date">
                  <i className="fa fa-calendar"></i> {" Created: "}
                  {getDateToStringWithoutTime(new Date(tripData.created_at))}
                </span>
              )}
            </p>
          </div>
          <section className="status_wrapper">
            <span className={`status status-${tripData.status}`}>
              {tripData.status.charAt(0).toUpperCase() +
                tripData.status.slice(1)}
            </span>
            {tripData.public === "true" ? (
              <span>
                <i className="fa fa-eye"></i> Public
              </span>
            ) : (
              <span>
                <i className="fa fa-eye-slash"></i> Private
              </span>
            )}
          </section>
          {tripData.trip_access === "owner" && (
            <MyTripCardDropdown
              tripId={tripData.trip_id}
              tripStatus={tripData.status}
              tripPublic={tripData.public}
              refreshHandler={props.refreshHandler}
            />
          )}
          {tripData.trip_access === "accepted" && (
            <InvitedTripCardDropdown
              tripId={tripData.trip_id}
              inviteId={tripData.invited_id}
              refreshHandler={props.refreshHandler}
            />
          )}
          {tripData.trip_access === "invited" && (
            <InvitedTripCardDropdown
              tripId={tripData.trip_id}
              inviteId={tripData.invited_id}
              inviteToken={tripData.token}
              refreshHandler={props.refreshHandler}
            />
          )}
        </div>
      </div>
      <div
        className={`confirmation-modal modal-wrap ${modalOpen ? "active" : ""}`}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-text">
              <img src={modalCross} onClick={openModal} alt="modalCross" />
              <h2>Are you sure?</h2>
              <div className="button-wrapper">
                <button className="signup-btn no" onClick={openModal}>
                  No
                </button>
                <button className="signup-btn yes" onClick={openModal}>
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
