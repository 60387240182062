import React, { useEffect, useState } from "react";
import userAvatar from "../../assets/images/avatar.png";
import ImageInput from "../../utilities/ImageInput";
import { updateUserProfileImage } from "../../lib/api";
import useHttp from "../../hooks/use-http";
import ModalWithInfo from "../../components/ui/ModalWithInfo";
import { useDispatch } from "react-redux";
import { authActions } from "../../store/auth-slice";
import { validateImage } from "../../lib/api";
import ApiErrors from "../../components/api-errors/ApiErrors";
import SpinnerButton from "../../components/ui/SpinnerButton";
import { resizeImage } from "../../lib/api";

const UpdateProfileImage = (props) => {
  const dispatch = useDispatch();
  const userImage = props.userImage;
  const [selectedImage, setSelectedImage] = useState(
    userImage ? userImage : null
  );
  const [imageIsTouched, setImageIsTouched] = useState(false);
  const [imageIsValid, setImageIsValid] = useState(true);
  const [showApiErrors, setShowApiErrors] = useState(false);
  const [showGearAddedModal, setShowGearAddedModal] = useState(false);
  const [resizingImage, setResizingImage] = useState(false);
  const { sendRequest, status, error, data } = useHttp(updateUserProfileImage);

  //Validate the image whenever a new image is selected
  useEffect(() => {
    // If we do not check the image is touched or not, selected image can also contain an image URL
    //coming from DB in case the user already has an image and it causes an error. So, it is important
    //to have this check. Whenever the user touches the image, it is going to be a "File" so the
    //validate function won't crash.

    if (imageIsTouched && selectedImage) {
      if (!validateImage(selectedImage, "10000001")) {
        setImageIsValid(false);
        setSelectedImage(null);
      } else {
        setImageIsValid(true);
      }
    }
  }, [selectedImage, imageIsTouched]);

  const handleError = (e) => {
    e.target.src = userAvatar;
  };

  const formSubmitHandler = (event) => {
    event.preventDefault();
    setShowApiErrors(false);
    //Resize the image if user has selected an image, wait for promise to resolve, and then send the API call.
    if (imageIsTouched && selectedImage) {
      setResizingImage(true);
      resizeImage(selectedImage).then((resizedImage) => {
        setResizingImage(false);
        const userImage = new FormData();
        userImage.append("image", resizedImage);
        userImage.append("_method", "PUT");
        sendRequest(userImage);
      });
    } else {
      const userImage = new FormData();
      userImage.append("image", selectedImage);
      userImage.append("_method", "PUT");
      sendRequest(userImage);
    }
  };

  useEffect(() => {
    if (error) {
      setShowApiErrors(true);
    }
    if (status === "completed" && error === null) {
      setShowGearAddedModal(true);
    }
    if (data) {
      //Updating in store so that all subscribed components including the header can display new image.
      dispatch(authActions.updateUserProfileImage(data.new_image));
    }
  }, [status, error, data, dispatch]);

  const infoModalHandler = () => {
    setShowGearAddedModal(false);
    props.refreshHandler();
  };

  return (
    <div className="col-6">
      <div className="profile-head-wrapper">
        <h2 className="section-title">Update Profile Picture</h2>
        <ul className="list-style-none">
          <form
            id="gearItemForm"
            className={`search-filed ${props.profileClass}`}
            onSubmit={formSubmitHandler}
            encType="multipart/form-data"
          >
            <li className="img-wrap">
              <div className="gare-image">
                {/* On initial load, when there is no user image from db */}
                {!imageIsTouched && !selectedImage && (
                  <img
                    className="profile-image"
                    alt="Not Found"
                    width={"650px"}
                    src={userAvatar}
                  />
                )}
                {/* On initial load, but when there is a user image from db */}
                {!imageIsTouched && selectedImage && (
                  <img
                    className="profile-image"
                    alt="Not Found"
                    width={"650px"}
                    src={selectedImage}
                    onError={handleError}
                  />
                )}
                {/* When user has removed the selected image. */}
                {imageIsTouched && !selectedImage && (
                  <img
                    className="profile-image"
                    alt="Not Found"
                    width={"650px"}
                    src={userAvatar}
                  />
                )}
                {/* When user has selected a new image. */}
                {imageIsTouched && selectedImage && (
                  <img
                    className="profile-image"
                    alt="Not Found"
                    width={"650px"}
                    src={URL.createObjectURL(selectedImage)}
                    onError={handleError}
                  />
                )}

                <div className="profile-button-wrap">
                  <span className="side-btn">
                    <ImageInput
                      selectedImage={selectedImage}
                      setSelectedImage={setSelectedImage}
                      setImageIsTouched={setImageIsTouched}
                    />
                    {!selectedImage && <small>Select Image</small>}
                  </span>
                </div>
              </div>
            </li>
            {!imageIsValid && imageIsTouched && (
              <p className="error-msg">
                Please select an image (PNG, JPG, JPEG), and maximum file size
                can be 10 MB.
              </p>
            )}
            <li>
              <button
                type="submit"
                className={`signup-btn ${
                  imageIsTouched && imageIsValid ? "" : "disabled-link"
                } ${
                  status === "pending" || resizingImage ? "disabled-link" : ""
                }`}
              >
                Update Profile Picture
                {(status === "pending" || resizingImage) && <SpinnerButton />}
              </button>
            </li>
          </form>
        </ul>
        {showApiErrors && <ApiErrors>{error}</ApiErrors>}
      </div>
      {showGearAddedModal && (
        <ModalWithInfo
          heading="Success"
          message="Your profile picture is successfully updated!"
          showModal={infoModalHandler}
        />
      )}
    </div>
  );
};

export default UpdateProfileImage;
