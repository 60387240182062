import "./splashPage.scss";
import { Link } from "react-router-dom";
import React from 'react'

export const SplashPage = () => {
  return (
    <div className="cal-to-action flex flex-wrap column-direction content-justify-center">
      <div className="container">
        <h1>
          Adventure planning, <strong>Simplified.</strong>
        </h1>
        <Link className="signup-btn" to="/trip-plan">
          Let’s Plan Your Adventure!
        </Link>
      </div>
    </div>
  );
};
