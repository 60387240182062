// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin-content {
  display: flex;
}

.main-content {
  height: 100vh;
  overflow-y: scroll;
}

@media (max-width: 800px) {
  .admin-content {
    display: unset;
  }
}`, "",{"version":3,"sources":["webpack://./src/layout/admin-layout/AdminLayout.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;AACJ;;AACA;EACI,aAAA;EACA,kBAAA;AAEJ;;AACA;EACI;IACI,cAAA;EAEN;AACF","sourcesContent":[".admin-content{\n    display: flex;\n}\n.main-content{\n    height: 100vh;\n    overflow-y: scroll;\n}\n\n@media (max-width:800px) {\n    .admin-content {\n        display: unset;\n    }\n    \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
