import React, { useEffect, useState } from "react";
import { getUserDetails } from "../../lib/api";
import useHttp from "../../hooks/use-http";
import UpdatePassword from "./UpdatePassword";
import PersonalSettings from "./PersonalSettings";

import { SpinnerModal } from "../../components/ui/SpinnerModal";
import UpdateProfileImage from "./UpdateProfileImage";
import ApiErrors from "../../components/api-errors/ApiErrors";

const UserSettings = () => {
  const { sendRequest, status, error, data } = useHttp(getUserDetails);
  const [refreshComponent, setRefreshComponent] = useState(false);

  useEffect(() => {
    sendRequest();
  }, [refreshComponent, sendRequest]);

  const refreshHandler = () => {
    setRefreshComponent(!refreshComponent);
  };

  return (
    <>
      {!error ? (
        <>
          {status === "pending" || status === null ? (
            <SpinnerModal />
          ) : (
            <>
              {data.user ? (
                <>
                  <div className="main-trail-head profile-settings flex flex-wrap w-100 content-justify-center">
                    <div className="container">
                      <div className="row flex flex-wrap">
                        <UpdateProfileImage
                          profileClass="user-profile-img"
                          userImage={data.user.image}
                          refreshHandler={refreshHandler}
                        />
                        <UpdatePassword socialLogin={data.user.social_login} />
                        <PersonalSettings
                          userData={data.user}
                          refreshHandler={refreshHandler}
                        />
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <p className="no-trip">
                  Sorry! User details could not be found.
                </p>
              )}
            </>
          )}
        </>
      ) : (
        <div className="main-profile-detail flex flex-wrap column-direction w-100">
          <ApiErrors>{error}</ApiErrors>
          <p className="no-trip">
            An error occured while fetching data from Backend. Please try again
            later.
          </p>
        </div>
      )}
    </>
  );
};

export default UserSettings;
