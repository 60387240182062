import { Link } from "react-router-dom";
import useInput from "../../hooks/use-input";
import SpinnerButton from "../../components/ui/SpinnerButton";
import useHttp from "../../hooks/use-http";
import { getNewCode, verifyUser } from "../../lib/api";
import React, { useEffect, useState } from "react";
import ApiErrors from "../../components/api-errors/ApiErrors";
import inspireLogo from "../../../src/assets/images/inspirelogo.svg";
import "./signUp.scss";

export const VerificationCode = () => {
  const [showApiErrors, setShowApiErrors] = useState(false);
  const [verificationCodeIsValid, setVerificationCodeIsValid] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [countdown, setCountdown] = useState(60);
  const { sendRequest, status, error } = useHttp(verifyUser);
  const {
    sendRequest: codeRequest,
    status: codeStatus,
    data,
    error: codeError,
  } = useHttp(getNewCode);
  const {
    value: enteredCode,
    hasError: codeHasError,
    valueIsValid: codeIsValid,
    valueChangeHandler: codeChangeHandler,
    inputBlurHandler: codeBlurHandler,
    resetValue: resetCode,
  } = useInput((value) => value.trim().length > 0);

  const formSubmitHandler = (event) => {
    setShowApiErrors(false);
    event.preventDefault();
    if (codeIsValid) {
      sendRequest(enteredCode, true);
    }
  };

  useEffect(() => {
    if (error) {
      setShowApiErrors(true);
    }
    if (status === "completed" && error === null) {
      resetCode();
      setVerificationCodeIsValid(true);
      localStorage.removeItem("verify_email");
    }
  }, [status, error]);

  useEffect(() => {
    if (codeStatus === "completed" && codeError === null) {
      setIsDisabled(true);
      setCountdown(60);
      const timer = setInterval(() => {
        setCountdown((prev) => prev - 1);
      }, 1000);
      setTimeout(() => {
        clearInterval(timer);
        setIsDisabled(false);
        setCountdown(60); // Reset the countdown
      }, 60000); // 60 seconds
    }
  }, [codeStatus, codeError]);

  const getNewCodeHandle = () => {
    const email = localStorage.getItem("verify_email");
    codeRequest(email);
  };

  useEffect(() => {
    const url = window.location.href;
    if (url.includes('status=unverified')) {
      setShowModal(true);
    }
  }, []);

  return (
    <>
      <div className="main-trail-head sign-in main-signup flex flex-wrap w-100 content-justify-center">
        <div className="container">
          <div className="trail-head-wrapper verification signup-box">

            {showModal && (
                <div className="confirm-modal">
                  <div className="confirm-modal-content">
                    <h2>Alert!</h2>
                    <h2>
                      It appears that you have attempted to sign up again using an email address that is still unverified.
                      Please verify your email to continue, and rest assured that your previous information remains unchanged.
                    </h2>
                    <section>
                      <button className="signup-btn" onClick={() => setShowModal(false)}>
                        Got it
                      </button>
                    </section>
                  </div>
                </div>
            )}

            <Link to="/signin" className="logoInspire">
              <img src={inspireLogo} alt="logo" />
            </Link>
            <h1 className="trail-title">Verification Code</h1>
            <p>
              A verification code has been sent to your email, please add that
              code here to verify your account and complete signup.
            </p>
            <form onSubmit={formSubmitHandler} className="verificationOR">
              <div className="phone-numbers">
                <ul className="list-style-none">
                  <li>
                  <div className="form-group form-group-verification">
                      <i className="fa fa-keyboard-o userIcon"></i>
                      <label>Verification Code</label>
                      <div className="input-container">
                      <input
                      type="number"
                      placeholder="Enter Verification Code"
                      className="number-list"
                      id="code"
                      value={enteredCode}
                      onBlur={codeBlurHandler}
                      onChange={codeChangeHandler}
                    />
                      </div>
                    </div>
                    {codeHasError && (
                      <p className="error-msg">
                        Please enter verification code.
                      </p>
                    )}
                    {verificationCodeIsValid && (
                      <div className="confirm-modal">
                        <div className="confirm-modal-content">
                          <h2>
                            Thank you for verification, your account is now
                            activated. Please <Link to={"/signin"}>Login</Link>{" "}
                            to your account.
                          </h2>
                          <section>
                            <Link to={"/signin"} className="signup-btn">
                              Login
                            </Link>
                          </section>
                        </div>
                      </div>
                    )}
                  </li>
                  {showApiErrors && <ApiErrors>{error}</ApiErrors>}
                  <li>
                    <button
                      type="submit"
                      className={`signup-btn submit-btn ${
                        codeIsValid ? "" : "disabled-link"
                      } ${status === "pending" ? "disabled-link" : ""}`}
                    >
                      Verify Account{status === "pending" && <SpinnerButton />}
                    </button>
                  </li>

                    <span className="sign-divider">
                      <small>OR</small>
                    </span>

                  <li>
                    <button
                      type="button"
                      className="signup-btn submit-btn"
                      onClick={() => getNewCodeHandle()}
                      disabled={
                        codeStatus === "pending" ||
                        status === "pending" ||
                        isDisabled
                      }
                    >
                      Get New Code{" "}
                      {codeStatus === "pending" && <SpinnerButton />}
                      {isDisabled && `Retry in (${countdown} seconds)`}
                    </button>
                  </li>
                  {codeStatus === "completed" &&
                    isDisabled === true &&
                    data?.message && <li>{data?.message}</li>}
                </ul>
              </div>
            </form>
            <small className="already-account">
              Already Have An Account? <Link to="/signin">Login</Link>
            </small>
          </div>
        </div>
      </div>
    </>
  );
};
