//This component is built so that pagination code that needs to be written in each
//component where pagination is needed, can be minimized, and we can generalize the
//pagination use in multiple places

import React, { useState, useEffect } from "react";
import Pagination from "./Pagination";

const AddPagination = ({ allData, setPaginatedData, pageSize = 6 }) => {
  const [currentPage, setCurrentPage] = useState(1);

  const updatePaginatedData = () => {
    const firstPageIndex = (currentPage - 1) * pageSize;
    const lastPageIndex = firstPageIndex + pageSize;
    return allData.slice(firstPageIndex, lastPageIndex);
  };

  useEffect(() => {
    setPaginatedData(updatePaginatedData());
  }, [currentPage]);

  return (
    <Pagination
      className="pagination-bar"
      currentPage={currentPage}
      totalCount={allData.length}
      pageSize={pageSize}
      onPageChange={(page) => setCurrentPage(page)}
    />
  );
};

export default AddPagination;
