import React from 'react'

const ImageInput = (props) => {

  const fileChangeHandler = (event) => {
    props.setImageIsTouched(true);
    props.setSelectedImage(event.target.files[0]);
  };
  return (
    <>
      <input type="file" name="item_image" onChange={fileChangeHandler} />
      {props.selectedImage && (
        <small
          className="remove-image"
          onClick={() => {
            props.setSelectedImage(null);
            props.setImageIsTouched(true);
          }}
        >
          Remove Image
        </small>
      )}
    </>
  );
};
export default ImageInput;
