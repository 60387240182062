import React, { useState } from "react";
import SingleTripCard from "./SingleTripCard";
import AddPagination from "../../components/ui/AddPagination";

const MyTrips = (props) => {
  const myActiveTrips = props.myTrips.active;
  const myCompletedTrips = props.myTrips.completed;
  const myCanceledTrips = props.myTrips.canceled;
  const allTrips = [...myActiveTrips, ...myCompletedTrips, ...myCanceledTrips];

  const [paginatedData, setPaginatedData] = useState(allTrips);

  return (
    <>
      <h1 className="trail-title">My Trips</h1>
      <ul className="list-style-none cards-wrapper flex flex-wrap">
        {allTrips.length === 0 ? (
          <li className="no-trip">No Trips Found</li>
        ) : (
          paginatedData.map((trip) => (
            <SingleTripCard
              key={trip.trip_id}
              singleTripData={trip}
              refreshHandler={props.refreshHandler}
            />
          ))
        )}
        <AddPagination allData={allTrips} setPaginatedData={setPaginatedData} />
      </ul>
    </>
  );
};

export default MyTrips;
