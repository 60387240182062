import AddGearItem from "./AddGearItem";
import React from 'react'

const EditGearItem = (props) => {
  return (
    <AddGearItem
      showAddGearForm={props.showEditGearForm}
      isEditing={true}
      gearItem={props.gearItem}
      refreshHandler={props.refreshHandler}
      isMyGroupGear={props.isMyGroupGear}
    />
  );
};

export default EditGearItem;
