import React, { useState } from "react";
import NoGearImage from "../../../assets/images/no-gear-img.webp";
import AddGearItem from "./AddGearItem";
import AddPagination from "../../../components/ui/AddPagination";
import { getDateToStringWithoutTime } from "../../../lib/api";

import GearDropDownList from "./GearDropDownList";

export const MyGear = (props) => {
  const [openAddGearForm, setOpenAddGearForm] = useState(false);
  const personalGear = props.personalGear;

  const [paginatedData, setPaginatedData] = useState(personalGear);
  const handleError = (e) => {
    e.target.src = NoGearImage;
};


  return (
    <>
      <div className="side-cards-wrapper">
        <ul className="list-style-none flex column-direction flex-wrap">
          {paginatedData.map((gearItem) => (
            <li key={gearItem.id}>
              <div className="side-cards-wrap">
                <img src={gearItem.item_image || NoGearImage} alt="img"
                onError={handleError} 
                style={{ width: '80px', height: '80px', objectFit: 'cover' }}  
                />
                <section>
                  <h3>{gearItem.item_name}</h3>
                  <p>{gearItem.item_description}</p>
                  <div className="flex gear-items">
                    <p>
                      <strong>
                        <i className="fa fa-bar-chart"></i> Quantity:
                      </strong>{" "}
                      {gearItem.item_quantity}
                    </p>
                    <p>
                      <strong>
                        {" "}
                        <i className="fa fa-clock-o"></i>{" "}
                      </strong>{" "}
                      {getDateToStringWithoutTime(
                        new Date(gearItem.created_at)
                      )}
                    </p>
                  </div>
                </section>
              </div>
              <GearDropDownList
                gearItem={gearItem}
                refreshHandler={props.refreshHandler}
              />
            </li>
          ))}
          {personalGear.length === 0 && (
            <li className="no-gear">No personal gear is added.</li>
          )}
        </ul>
          <AddPagination
              allData={personalGear}
              setPaginatedData={setPaginatedData}
              pageSize={5}
          />
        <button
          className={`signup-btn addItemsGear ${
            props.tripAccess !== "owner" &&
            props.tripAccess !== "accepted" &&
            "disabled-link"
          }`}
          onClick={() => {
            setOpenAddGearForm(true);
          }}
        >
          {props.tripAccess !== "owner" && props.tripAccess !== "accepted"
            ? "Accept Trip Invite to Add Gear"
            : "Add Items to Your Gear"}
        </button>
      </div>
      {openAddGearForm && (
        <AddGearItem
          showAddGearForm={setOpenAddGearForm}
          tripId={props.tripId}
          refreshHandler={props.refreshHandler}
        />
      )}
    </>
  );
};
