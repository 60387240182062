import { Link } from "react-router-dom";
import userAvatar from "../../../assets/images/avatar-small.png";
import multiUserAvatar from "../../../assets/images/multi-users.png";
import React from 'react'

const AdminAttendingUsersAvatars = (props) => {
  let attendingUsers = props.attendingUsers;
  //console.log(attendingUsers);
  //attendingUsers = [...attendingUsers, ...attendingUsers];
  return (
    <ul className="list-style-none flex">
      {attendingUsers.map((user, index) => {
        //Only display first 5 attendees.
        let returnValue;
        if (index < 5) {
          returnValue = (
            <li key={index}>
              <Link to={`/admin-32423QeCFer/user-details/${user.user_id}`}>
                <span className="tooltip">
                  <small>{user.attending_user_name}</small>
                  <img
                    src={
                      user.attending_user_image
                        ? user.attending_user_image
                        : userAvatar
                    }
                    alt="Not Found"
                  />
                </span>
              </Link>
            </li>
          );
        } else {
          returnValue = null;
        }
        return returnValue;
      })}
      {attendingUsers.length > 5 && (
        <li key={5}>
          <span className="tooltip">
            <small>{attendingUsers.length - 5} Others</small>
            <img src={multiUserAvatar} alt="Not Found" />
          </span>
        </li>
      )}
    </ul>
  );
};

export default AdminAttendingUsersAvatars;
