import GoogleMapReact from "google-map-react";
import { Link } from "react-router-dom";
import { getDateToStringWithoutTime } from "../../../lib/api";
import AdminTripCardDropdown from "./AdminTripCardDropdown";
import React from 'react'

const MapPin = () => <div className="map-pin-text" />;

const AdminTripCard = (props) => {
  const singleTripData = props.singleTripData;
  return (
    <li key={singleTripData.id}>
      <div className="cards-wrap">
        <div className="card-map">
          <GoogleMapReact
            bootstrapURLKeys={{
              key: "AIzaSyCOkkRRCQ2CF7KvrvLLm9P4mc_hQEmHk8w",
            }}
            center={{
              lat: +singleTripData.latitude,
              lng: +singleTripData.longitude,
            }}
            defaultZoom={11}
          >
            <MapPin
              lat={+singleTripData.latitude}
              lng={+singleTripData.longitude}
              pinText="Map"
            />
          </GoogleMapReact>
        </div>
        <section>
          <AdminTripCardDropdown
            tripId={singleTripData.id}
            userId={singleTripData.user_id}
            refreshHandler={props.refreshHandler}
          />
          <Link to={`/admin-32423QeCFer/trip-details/${singleTripData.id}`}>
            <h2>{singleTripData.activity}</h2>
            <p>
              <i className="fa fa-map-marker"></i>
              {singleTripData.location}
            </p>
            <p>
              <i className="fa fa-calendar-o"></i>
              {getDateToStringWithoutTime(
                new Date(singleTripData.start_date)
              )}{" "}
              {singleTripData.end_date
                ? `- ${getDateToStringWithoutTime(
                    new Date(singleTripData.end_date)
                  )}`
                : ""}
            </p>
            <p>
              {singleTripData.public === "true" ? (
                <span>
                  <i className="fa fa-eye"></i>Public
                </span>
              ) : (
                <span>
                  <i className="fa fa-eye-slash"></i>Private
                </span>
              )}
            </p>
            <span className={`status status-${singleTripData.status}`}>
              {singleTripData.status.charAt(0).toUpperCase() +
                singleTripData.status.slice(1)}
            </span>
          </Link>
        </section>
      </div>
    </li>
  );
};

export default AdminTripCard;
