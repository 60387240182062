import React from 'react';


const ConfirmationModal = ({ isOpen, onClose, onConfirm, message }) => {
    if (!isOpen) return null;

    return (
        <div className="confirm-modal">
            <div className="confirm-modal-content">
                <h2>Confirm Action</h2>
                <h2>{message}</h2>
                <section>
                    <button className="signup-btn" onClick={onConfirm}>
                        Confirm
                    </button>
                    <button className="signup-btn" onClick={onClose}>
                        Cancel
                    </button>
                </section>
            </div>
        </div>
    );
};

export default ConfirmationModal;
