import { Link } from "react-router-dom";
import userEditImage from "../../../assets/images/user.jpg";
import crossImage from "../../../assets/images/cross.svg";
import React from 'react'

export const AdminEditUser = () => {
  return (
    <>
      <div className="main-trail-head profile-settings flex flex-wrap w-100 content-justify-center">
        <div className="container">
          <div className="row flex flex-wrap">
            <div className="col-6">
              <div className="profile-head-wrapper">
                <h2 className="section-title">Edit Profile Image</h2>
                <ul className="list-style-none">
                  <li>
                    <figure>
                      <img
                        src={crossImage}
                        className="remove-image"
                        alt="crossImage"
                      />
                      <img
                        className="profile-image"
                        src={userEditImage}
                        alt="userEditImage"
                      />
                    </figure>
                    <input type="file" className="number-list" />
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-6">
              <div className="profile-head-wrapper">
                <h2 className="section-title">Edit Personal Details</h2>
                <ul className="list-style-none">
                  <li>
                    <label htmlFor="">Name</label>
                    <input
                      type="email"
                      placeholder="Type here..."
                      className="number-list"
                    />
                  </li>
                  <li>
                    <label htmlFor="">Email</label>
                    <input
                      type="email"
                      placeholder="Type here..."
                      className="number-list"
                    />
                  </li>
                  <li>
                    <label htmlFor="">Phone</label>
                    <input
                      type="text"
                      placeholder="Type here..."
                      className="number-list"
                    />
                  </li>
                  <li>
                    <Link to="/my-account" className="signup-btn">
                      Submit
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
