import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { socialLogin } from "../../lib/api";
import useHttp from "../../hooks/use-http";
import { useDispatch } from "react-redux";
import { authActions } from "../../store/auth-slice";
import LoginWithGoogle from "../google/LoginWithGoogle";
import inspireLogo from "../../../src/assets/images/inspirelogo.svg";
import "../../../src/pages/create-trip/tripInvitees.scss";
import "./signUp.scss";

export const SignUp = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { sendRequest, status, error, data } = useHttp(socialLogin);

  const handleCallbackResponse = (response) => {
    //const userObj = jwt_decode(response.credential);
    sendRequest({ provider: "google", provider_token: response.credential });
  };

  useEffect(() => {
    window.google.accounts.id.initialize({
      client_id:
        "40510753212-14ial3vt452scftu63488bkisvubhvtd.apps.googleusercontent.com",
      callback: handleCallbackResponse,
    });
    window.google.accounts.id.renderButton(
      document.getElementById("googleSignIn"),
      {
        theme: "outline",
        size: "large",
        width: 400,
        height: 50,
      }
    );
  }, []);

  useEffect(() => {
    if (status === "completed" && !error) {
      //Signin the user
      dispatch(authActions.updateAuthToken(data));
      if (data.phone_number === null) {
        //But keep a flag that his phone is not set
        dispatch(authActions.updateSocialLoginWithoutPhone(true));
        navigate("/phone-number-required", { replace: true });
      }
      //If phone is found, redirect the user to logged in pages
      else if (!!localStorage.getItem("location")) {
        navigate("/trip-plan/confirm-trip-details", { replace: true });
      } else {
        navigate("/trip-plan", { replace: true });
        //navigate("/my-account", { replace: true });
      }
    }
  }, [status, error]);

  return (
    <>
      <div className="main-signup flex flex-wrap w-100 content-justify-center">
        <div className="container">
          <div className="signup-box">
            <Link to="/signup" className="logoInspire">
              <img src={inspireLogo} alt="logo" />
            </Link>
            <h1 className="trail-title">Create An Account</h1>
            <div className="signup-wrap">
              <LoginWithGoogle />
              <span className="sign-divider">
                <small>OR</small>
              </span>
              <Link to="/signup/email">Sign Up with Email</Link>
              <br />
              <p>
                <strong>NOTE:</strong> The platform is in the Beta stages, and
                by signing up, you are becoming a part of the Beta program.
              </p>
              <span className="sign-divider">
                <small>OR</small>
              </span>
            </div>
            <strong>Already have an account?</strong>
            <Link to="/signin" className="loginButton">
              Log In
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};
