import React, { useEffect, useState } from "react";
import useHttp from "../../../hooks/use-http";
import useInput from "../../../hooks/use-input";
import validator from "validator";
import SpinnerButton from "../../../components/ui/SpinnerButton";
import { searchUsers } from "../../../lib/admin-api";
import AdminApiErrors from "../../../components/api-errors/AdminApiErrors";

const AdminUsersSearch = (props) => {
  const [showApiErrors, setShowApiErrors] = useState(false);
  const { sendRequest, status, error, data } = useHttp(searchUsers);
  const {
    value: enteredName,
    valueIsValid: nameIsValid,
    valueChangeHandler: nameChangeHandler,
    inputBlurHandler: nameBlurHandler,
    resetValue: resetName,
  } = useInput((value) => value.trim().length > 0 && value.trim().length < 99);

  const {
    value: enteredEmail,
    hasError: emailHasError,
    valueIsValid: emailIsValid,
    valueChangeHandler: emailChangeHandler,
    inputBlurHandler: emailBlurHandler,
    resetValue: resetEmail,
  } = useInput((value) => value.trim().includes("@"));

  const {
    value: enteredPhone,
    hasError: phoneHasError,
    valueIsValid: phoneIsValid,
    valueChangeHandler: phoneChangeHandler,
    inputBlurHandler: phoneBlurHandler,
    resetValue: resetPhone,
  } = useInput((value) => validator.isMobilePhone(value + "", "en-US"));

  const formIsInvalid =
    (enteredEmail !== "" && emailHasError) ||
    (enteredPhone !== "" && phoneHasError) ||
    (!nameIsValid && !emailIsValid && !phoneIsValid);

  const formSubmitHandler = (event) => {
    event.preventDefault();
    if (!formIsInvalid) {
      const searchData = {
        name: enteredName,
        email: enteredEmail,
        phone: enteredPhone,
      };
      sendRequest(searchData);
    }
  };

  useEffect(() => {
    if (status === "completed" && error === null) {
      props.setSearchResults(data.all_users);
      props.setIsSearching(true);
    }
    if (status === "completed" && error) {
      setShowApiErrors(true);
    }
  }, [status, data, error]);

  return (
    <div className="admin-search-wrapper">
      <h2 className="search-title">Search</h2>
      <form onSubmit={formSubmitHandler}>
        <ul className="list-style-none flex flex-wrap items-start">
          <li>
            <input
              type="text"
              className="number-list"
              placeholder="Name"
              value={enteredName}
              onChange={nameChangeHandler}
              onBlur={nameBlurHandler}
            />
          </li>
          <li>
            <input
              type="email"
              className="number-list"
              placeholder="Email"
              value={enteredEmail}
              onChange={emailChangeHandler}
              onBlur={emailBlurHandler}
            />
            {enteredEmail !== "" && emailHasError && (
              <p className="error-msg">Please enter a valid email.</p>
            )}
          </li>
          <li>
            <input
              type="text"
              className="number-list"
              placeholder="Phone"
              value={enteredPhone}
              onChange={phoneChangeHandler}
              onBlur={phoneBlurHandler}
            />
            {enteredPhone !== "" && phoneHasError && (
              <p className="error-msg">Please enter a valid phone.</p>
            )}
          </li>
          <li>
            <button
              type="submit"
              className={`signup-btn submit-btn ${
                formIsInvalid ? "disabled-link" : ""
              } ${status === "pending" ? "disabled-link" : ""}`}
            >
              Search{status === "pending" && <SpinnerButton />}
            </button>
          </li>
          <li>
            <button
              onClick={(event) => {
                event.preventDefault();
                resetName();
                resetEmail();
                resetPhone();
                props.setIsSearching(false);
              }}
              className={"signup-btn submit-btn"}
            >
              Reset
            </button>
          </li>
        </ul>
      </form>
      {showApiErrors && <AdminApiErrors>{error}</AdminApiErrors>}
    </div>
  );
};

export default AdminUsersSearch;
