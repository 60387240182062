import React, { useEffect, useState } from "react";
import { AdminMyGear } from "./AdminMyGear";
import { AdminGroupGear } from "./AdminGroupGear";
import { adminGetAllGearItems } from "../../../../lib/admin-api";
import useHttp from "../../../../hooks/use-http";
import { SpinnerModal } from "../../../../components/ui/SpinnerModal";
import AdminApiErrors from "../../../../components/api-errors/AdminApiErrors";

export const AdminTripGearList = (props) => {
  const [activeTab, setActiveTab] = useState("tab1");
  const { sendRequest, status, error, data } = useHttp(adminGetAllGearItems);
  const [refreshComponent, setRefreshComponent] = useState(false);

  const refreshHandler = () => {
    setRefreshComponent(!refreshComponent);
  };

  useEffect(() => {
    sendRequest(props.tripId);
  }, [sendRequest, refreshComponent]);

  return (
    <>
      {!error ? (
        <>
          {status === "pending" || status === null ? (
            <SpinnerModal />
          ) : (
            <>
              {data.gears ? (
                <>
                  <div className="gear-wrapper flex column-direction flex-wrap w-100">
                    <div className="flex">
                      <section className="ml-auto">
                        <button
                          onClick={() => {
                            setActiveTab("tab1");
                          }}
                          className={`tabs-btn ${
                            activeTab === "tab1" ? "active" : ""
                          }`}
                        >
                          User's Gear
                        </button>
                        <button
                          onClick={() => {
                            setActiveTab("tab2");
                          }}
                          className={`tabs-btn ${
                            activeTab === "tab2" ? "active" : ""
                          }`}
                        >
                          Group Gear
                        </button>
                      </section>
                    </div>
                    {activeTab === "tab1" ? (
                      <AdminMyGear
                        personalGear={data.gears.personal_gear}
                        refreshHandler={refreshHandler}
                      />
                    ) : (
                      <AdminGroupGear
                        groupGear={data.gears.my_group_gears}
                        refreshHandler={refreshHandler}
                      />
                    )}
                  </div>
                </>
              ) : (
                <p className="no-trip">Sorry! Trip gear could not be found.</p>
              )}
            </>
          )}
        </>
      ) : (
        <div className="main-profile-detail flex flex-wrap column-direction w-100">
          <AdminApiErrors>{error}</AdminApiErrors>
          <p className="no-trip">
            An error occured while fetching data from Backend. Please try again
            later.
          </p>
        </div>
      )}
    </>
  );
};
