import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import useInput from "../../hooks/use-input";
import { registerUser, validatePasswordFormat } from "../../lib/api";
import useHttp from "../../hooks/use-http";
import ApiErrors from "../../components/api-errors/ApiErrors";
import SpinnerButton from "../../components/ui/SpinnerButton";
import validator from "validator";
import inspireLogo from "../../../src/assets/images/inspirelogo.svg";
import "./signUp.scss";

export const SignUpEmail = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showApiErrors, setShowApiErrors] = useState(false);
  const { sendRequest, status, error } = useHttp(registerUser);

  const {
    value: enteredName,
    hasError: nameHasError,
    valueIsValid: nameIsValid,
    valueChangeHandler: nameChangeHandler,
    inputBlurHandler: nameBlurHandler,
  } = useInput((value) => value.trim().length > 2);

  const {
    value: enteredEmail,
    hasError: emailHasError,
    valueIsValid: emailIsValid,
    valueChangeHandler: emailChangeHandler,
    inputBlurHandler: emailBlurHandler,
  } = useInput((value) => value.includes("@"));

  const {
    value: enteredPhone,
    hasError: phoneHasError,
    valueIsValid: phoneIsValid,
    valueChangeHandler: phoneChangeHandler,
    inputBlurHandler: phoneBlurHandler,
  } = useInput((value) => validator.isMobilePhone(value + "", "en-US"));

  const {
    value: enteredPassword,
    hasError: passwordHasError,
    valueIsValid: passwordIsValid,
    valueChangeHandler: passwordChangeHandler,
    inputBlurHandler: passwordBlurHandler,
  } = useInput((value) => validatePasswordFormat(value));

  const {
    value: enteredConfirmPassword,
    hasError: ConfirmPasswordHasError,
    valueIsValid: confirmPasswordIsValid,
    valueChangeHandler: confirmPasswordChangeHandler,
    inputBlurHandler: confirmPasswordBlurHandler,
  } = useInput((value) => {
    return validatePasswordFormat(value) && value === enteredPassword;
  });

  const formIsValid =
    phoneIsValid &&
    nameIsValid &&
    emailIsValid &&
    passwordIsValid &&
    confirmPasswordIsValid;

  const formSubmitHandler = (event) => {
    event.preventDefault();
    setShowApiErrors(false);
    if (formIsValid) {
      const userData = {
        name: enteredName,
        email: enteredEmail,
        phone_number: enteredPhone,
        password: enteredPassword,
      };
      sendRequest(userData, true);
    } else {
      return;
    }
  };

  useEffect(() => {
    if (error) {
      setShowApiErrors(true);
    }
    if (status === "completed" && error === null) {
      localStorage.setItem('verify_email', enteredEmail);
      navigate("/signup/verification");
    }
    const err = JSON.parse(error);
    if (err?.message === 'not_verified') {
      localStorage.setItem('verify_email', enteredEmail);
      navigate("/signup/verification?status=unverified", { replace: true });
    }
  }, [status, error]);

  const toggleShowPasswordHandler = () => {
    setShowPassword((showPassword) => !showPassword);
  };
  const toggleShowConfirmPasswordHandler = () => {
    setShowConfirmPassword((prevState) => !prevState);
  };

  return (
    <>
      <div className="main-trail-head main-signup sign-in flex flex-wrap w-100 content-justify-center">
        <div className="container">
          <div className="signup-box">
            <Link to="/signup/email" className="logoInspire">
              <img src={inspireLogo} alt="logo" />
            </Link>
            <h1 className="trail-title">Sign Up With Email</h1>
            <form onSubmit={formSubmitHandler}>
              <div className="phone-numbers">
                <ul className="list-style-none">
                  <li>
                    <div className="form-group">
                      <i className="fa fa-user userIcon"></i>
                      <label>Full Name</label>
                      <div className="input-container">
                        <input
                          type="text"
                          id="name"
                          placeholder="Enter Full Name"
                          className="number-list"
                          value={enteredName}
                          onBlur={nameBlurHandler}
                          onChange={nameChangeHandler}
                        />
                      </div>
                    </div>
                    {nameHasError && (
                      <p className="error-msg">
                        Please enter name. (At least 3 characters)
                      </p>
                    )}
                  </li>
                  <li>
                    <div className="form-group">
                      <i className="fa fa-envelope emailIcon"></i>
                      <label>Email</label>
                      <div className="input-container">
                        <input
                          type="email"
                          placeholder="Enter Email Address"
                          className="number-list"
                          id="email"
                          value={enteredEmail}
                          onBlur={emailBlurHandler}
                          onChange={emailChangeHandler}
                        />
                      </div>
                    </div>
                    {emailHasError && (
                      <p className="error-msg">Please enter a valid Email.</p>
                    )}
                  </li>
                  <li>
                    <div className="form-group">
                      <i className="fa fa-phone phoneIcon"></i>
                      <label>Phone Number</label>
                      <div className="input-container">
                        <input
                          type="text"
                          placeholder="Enter Phone Number"
                          className="number-list"
                          id="phone"
                          value={enteredPhone}
                          onBlur={phoneBlurHandler}
                          onChange={phoneChangeHandler}
                        />
                      </div>
                    </div>
                  </li>
                  {phoneHasError && (
                    <p className="error-msg">
                      Please enter a valid phone number with 10 digits.
                    </p>
                  )}
                  <li>
                    <div className="form-group">
                      <i className="fa fa-lock keyIcon"></i>
                      <label>Password</label>
                      <div className="input-container">
                        <input
                          type={showPassword ? "text" : "password"}
                          placeholder="Enter Password"
                          className="number-list"
                          id="password"
                          value={enteredPassword}
                          onBlur={passwordBlurHandler}
                          onChange={passwordChangeHandler}
                        />
                        <i
                          className={`fa ${
                            showPassword ? "fa-eye-slash" : "fa-eye"
                          }`}
                          onClick={toggleShowPasswordHandler}
                        ></i>
                      </div>
                    </div>
                    {passwordHasError && (
                      <p className="error-msg">
                        Please enter a password with at least 8 characters, and
                        password must contain a number, a capital letter, a
                        small letter, and a special character.
                      </p>
                    )}
                  </li>
                  <li>
                    <div className="form-group">
                      <i className="fa fa-lock keyIcon"></i>
                      <label>Password</label>
                      <div className="input-container">
                        <input
                          type={showConfirmPassword ? "text" : "password"}
                          placeholder="Confirm Password"
                          className="number-list"
                          id="confirm-password"
                          value={enteredConfirmPassword}
                          onBlur={confirmPasswordBlurHandler}
                          onChange={confirmPasswordChangeHandler}
                        />
                        <i
                          className={`fa ${
                            showConfirmPassword ? "fa-eye-slash" : "fa-eye"
                          }`}
                          onClick={toggleShowConfirmPasswordHandler}
                        ></i>
                      </div>
                    </div>
                    {ConfirmPasswordHasError && (
                      <p className="error-msg">
                        Please enter a password with at least 8 characters, and
                        password must contain a number, a capital letter, a
                        small letter, and a special character. New Password and
                        Confirm Password must match.
                      </p>
                    )}
                  </li>
                  {showApiErrors && <ApiErrors>{error}</ApiErrors>}
                  <li>
                    <button
                      type="submit"
                      className={`signup-btn submit-btn ${
                        formIsValid ? "" : "disabled-link"
                      } ${status === "pending" ? "disabled-link" : ""}`}
                    >
                      Sign Up{status === "pending" && <SpinnerButton />}
                    </button>
                  </li>
                </ul>
              </div>
            </form>
            <small className="already-account">
              Already Have An Account? <Link to="/signin">Login</Link>
            </small>
          </div>
        </div>
      </div>
    </>
  );
};
