import userAvatar from "../../../assets/images/avatar-small.png";
import React from 'react'

export const TripUsers = (props) => {
  const attendingUsers = props.attendingUsers;
  //console.log(props.invitedUsers);
  let pendingInvites;
  let declinedInvites;
  if (props.invitedUsers) {
    pendingInvites = props.invitedUsers.pending_invites;
    declinedInvites = props.invitedUsers.declined_invites;
  }

  return (
    <>
      <div className="sidebar-wrapper flex flex-wrap">
        <h2 className="side-title">Attending Users</h2>
        <ul className="list-style-none flex column-direction flex-wrap">
          {attendingUsers.length > 0 ? (
            attendingUsers.map((user) => (
              <li key={user.id}>
                <div className="side-cards-users flex items-start">
                  <img
                    src={
                      user.attending_user_image !== null
                        ? user.attending_user_image
                        : userAvatar
                    }
                    alt="img"
                  />
                  <section>
                    <h6>{user.attending_user_name}</h6>
                    <a href={`tel:${user.attending_user_phone_number}`}>
                      {" "}
                      {user.attending_user_phone_number}
                    </a>
                  </section>
                </div>
              </li>
            ))
          ) : (
            <p className="no-user">
              Currently no users are attending this trip.
            </p>
          )}
          <li></li>
        </ul>
      </div>
      {/* Users viewing the trip as invited users will not receive the pending invites from API so this check is required */}

      {pendingInvites && (
        <div className="sidebar-wrapper flex flex-wrap">
          <h2 className="side-title">Pending Invites</h2>
          <ul className="list-style-none flex column-direction flex-wrap">
            {pendingInvites.length > 0 ? (
              pendingInvites.map((user) => (
                <li key={user.id}>
                  <div className="side-cards-users flex items-start">
                    <img
                      src={
                        user.invited_user_image !== null
                          ? user.invited_user_image
                          : userAvatar
                      }
                      alt="img"
                    />
                    {/* If the user does not have an account, he won't have a name, so display his number instead */}
                    <section>
                      {user.invited_user_name ? (
                        <>
                          <h6> {user.invited_user_name}</h6>
                          <a href={`tel:${user.invited_user_phone_number}`}>
                            {user.invited_user_phone_number}
                          </a>
                        </>
                      ) : (
                        <>
                          <h6>Account Not Created</h6>
                          <a href={`tel:${user.invited_user_phone_number}`}>
                            {user.invited_user_phone_number}
                          </a>
                        </>
                      )}
                    </section>
                  </div>
                </li>
              ))
            ) : (
              <p className="no-user">
                Currently there are no pending invites for this trip.
              </p>
            )}
            <li></li>
          </ul>
        </div>
      )}

      {/* Users viewing the trip as invited users will not receive the declined invites from API so this check is required */}

      {declinedInvites && (
        <div className="sidebar-wrapper flex flex-wrap">
          <h2 className="side-title">Declined Invites</h2>
          <ul className="list-style-none flex column-direction flex-wrap">
            {declinedInvites.length > 0 ? (
              declinedInvites.map((user) => (
                <li key={user.id}>
                  <div className="side-cards-users flex items-start">
                    <img
                      src={
                        user.invited_user_image !== null
                          ? user.invited_user_image
                          : userAvatar
                      }
                      alt="img"
                    />
                    {/* If the user does not have an account, he won't have a name, so display his number instead */}
                    <section>
                      {user.invited_user_name ? (
                        <>
                          <h6> {user.invited_user_name}</h6>
                          <a href={`tel:${user.invited_user_phone_number}`}>
                            {user.invited_user_phone_number}
                          </a>
                        </>
                      ) : (
                        <>
                          <h6>Account Not Created</h6>
                          <a href={`tel:${user.invited_user_phone_number}`}>
                            {user.invited_user_phone_number}
                          </a>
                        </>
                      )}
                    </section>
                  </div>
                </li>
              ))
            ) : (
              <p className="no-user">
                Currently there are no declined invites for this trip.
              </p>
            )}
            <li></li>
          </ul>
        </div>
      )}
    </>
  );
};
