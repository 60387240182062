import React, { useEffect } from "react";
import { SpinnerModal } from "../../../components/ui/SpinnerModal";
import useHttp from "../../../hooks/use-http";
import { getDashboardData } from "../../../lib/admin-api";
import { DashboarWidget } from "./dashboardWidget";
import AdminApiErrors from "../../../components/api-errors/AdminApiErrors";

export const AdminDashboard = () => {
    const { sendRequest, status, error, data } = useHttp(getDashboardData);
    useEffect(() => {
        sendRequest();
    }, [sendRequest]);
    return (
        <>
            <div className="admin-panel-wrap flex flex-wrap w-100 content-justify-center">
                <div className="container">
                    <h1>Dashboard</h1>
                    {!error ? (
                        <div className="table-responsive">
                            {status === "pending" || status === null ? (
                                <SpinnerModal />
                            ) : (
                                <DashboarWidget data={data?.data} />
                            )}
                        </div>
                    ) : (
                        <>
                            <AdminApiErrors>{error}</AdminApiErrors>
                            <p className="no-trip">
                                An error occured while fetching data from Backend. Please try
                                again later.
                            </p>
                        </>
                    )}
                </div>
            </div>
        </>
    );
};
