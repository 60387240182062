import spinnerGif from "../../assets/images/loader.gif";
import React from 'react'

export const SpinnerModal = () => {
  return (
    <div className="confirm-modal spinner-modal">
      <img src={spinnerGif} alt={spinnerGif} />
    </div>
  );
};
