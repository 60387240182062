import React, { useState, useEffect } from "react";
import useInput from "../../hooks/use-input";
import useHttp from "../../hooks/use-http";
import validator from "validator";
import { submitContactForm } from "../../lib/api";
import ApiErrors from "../../components/api-errors/ApiErrors";
import ModalWithInfo from "../../components/ui/ModalWithInfo";
import SpinnerButton from "../../components/ui/SpinnerButton";

export const ContactUs = () => {
  const { sendRequest, status, error } = useHttp(submitContactForm);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [showApiErrors, setShowApiErrors] = useState(false);

  const {
    value: enteredName,
    hasError: nameHasError,
    valueIsValid: nameIsValid,
    valueChangeHandler: nameChangeHandler,
    inputBlurHandler: nameBlurHandler,
    resetValue: resetName,
  } = useInput((value) => value.trim().length > 2 && value.trim().length < 51);

  const {
    value: enteredEmail,
    hasError: emailHasError,
    valueIsValid: emailIsValid,
    valueChangeHandler: emailChangeHandler,
    inputBlurHandler: emailBlurHandler,
    resetValue: resetEmail,
  } = useInput((value) => value.includes("@"));

  const {
    value: enteredPhone,
    hasError: phoneHasError,
    valueIsValid: phoneIsValid,
    valueChangeHandler: phoneChangeHandler,
    inputBlurHandler: phoneBlurHandler,
    resetValue: resetPhone,
  } = useInput((value) => validator.isMobilePhone(value + "", "en-US"));

  const {
    value: enteredMessage,
    hasError: messageHasError,
    valueIsValid: messageIsValid,
    valueChangeHandler: messageChangeHandler,
    inputBlurHandler: messageBlurHandler,
    resetValue: resetMessage,
  } = useInput((value) => value.trim().length > 9 && value.trim().length < 501);

  const formIsValid =
    nameIsValid && emailIsValid && phoneIsValid && messageIsValid;

  const formSubmitHandler = (event) => {
    event.preventDefault();
    if (formIsValid) {
      const formData = {
        full_name: enteredName,
        email: enteredEmail,
        phone_number: enteredPhone,
        message: enteredMessage,
      };
      sendRequest(formData);
    }
  };

  useEffect(() => {
    if (error) {
      setShowApiErrors(true);
    }
    if (status === "completed" && error === null) {
      setShowInfoModal(true);
    }
  }, [status, error]);

  const infoModalHandler = () => {
    setShowInfoModal(false);
    resetName();
    resetEmail();
    resetMessage();
    resetPhone();
  };

  return (
    <>
      <div className="main-trail-head sign-in flex flex-wrap w-100 content-justify-center">
        <div className="container">
          <div className="trail-head-wrapper">
            <h1 className="trail-title">Get In Touch</h1>
            <p>
              Please don't hesistate to reach out to us in case of any queries!
            </p>
            <form onSubmit={formSubmitHandler}>
              <div className="phone-numbers">
                <ul className="list-style-none">
                  <li>
                    <input
                      type="text"
                      placeholder="Full Name"
                      className="number-list"
                      value={enteredName}
                      onChange={nameChangeHandler}
                      onBlur={nameBlurHandler}
                    />
                  </li>
                  {nameHasError && (
                    <p className="error-msg">Please enter a valid name.</p>
                  )}
                  <li>
                    <input
                      type="email"
                      placeholder="Email"
                      className="number-list"
                      value={enteredEmail}
                      onChange={emailChangeHandler}
                      onBlur={emailBlurHandler}
                    />
                  </li>
                  {emailHasError && (
                    <p className="error-msg">Please enter a valid email.</p>
                  )}
                  <li>
                    <input
                      type="text"
                      placeholder="Phone Number"
                      className="number-list"
                      value={enteredPhone}
                      onChange={phoneChangeHandler}
                      onBlur={phoneBlurHandler}
                    />
                  </li>
                  {phoneHasError && (
                    <p className="error-msg">
                      Please enter a valid US Phone Number with 10 digits.
                    </p>
                  )}
                  <li>
                    <textarea
                      placeholder="Message"
                      className="number-list"
                      value={enteredMessage}
                      onChange={messageChangeHandler}
                      onBlur={messageBlurHandler}
                    ></textarea>
                  </li>
                  {messageHasError && (
                    <p className="error-msg">
                      Message must be within (10-500) characters.
                    </p>
                  )}
                </ul>
              </div>
              <button
                type="submit"
                className={`signup-btn submit-btn ${
                  formIsValid ? "" : "disabled-link"
                } ${status === "pending" ? "disabled-link" : ""}`}
              >
                Submit {status === "pending" && <SpinnerButton />}
              </button>
            </form>
            {showApiErrors && <ApiErrors>{error}</ApiErrors>}
            {showInfoModal && (
              <ModalWithInfo
                heading="Thank you for contacting us."
                message="We have received your request, and one of our representatives will get back to you shortly."
                showModal={infoModalHandler}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};
