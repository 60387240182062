import React from 'react'
import GoogleMapReact from "google-map-react";
import { Link } from "react-router-dom";
import { getDateToStringWithoutTime } from "../../lib/api";
import AttendingUsersAvatars from "./AttendingUsersAvatars";
import InvitedTripCardDropdown from "./InvitedTripCardDropdown";
import MyTripCardDropdown from "./MyTripCardDropdown";

const MapPin = () => <div className="map-pin-text" />;

const SingleTripCard = (props) => {
  const singleTripData = props.singleTripData;
  return (
    <li key={singleTripData.trip_id}>
      <div className="cards-wrap">
        <div className="card-map">
          <GoogleMapReact
            bootstrapURLKeys={{
              key: "AIzaSyCOkkRRCQ2CF7KvrvLLm9P4mc_hQEmHk8w",
            }}
            center={{
              lat: +singleTripData.latitude,
              lng: +singleTripData.longitude,
            }}
            defaultZoom={11}
          >
            <MapPin
              lat={+singleTripData.latitude}
              lng={+singleTripData.longitude}
              pinText="Map"
            />
          </GoogleMapReact>
        </div>
        <section>
          {singleTripData.invited_id ? (
            //It means the trip is an invited trip
            <InvitedTripCardDropdown
              tripId={singleTripData.trip_id}
              inviteId={
                singleTripData.invited_id ? singleTripData.invited_id : null
              }
              inviteToken={
                singleTripData.invite_status === "invited"
                  ? singleTripData.token
                  : null
              }
              refreshHandler={props.refreshHandler}
            />
          ) : (
            //It means the trip is not an invited trip, but user's own trip
            <MyTripCardDropdown
              tripId={singleTripData.trip_id}
              tripStatus={singleTripData.status}
              tripPublic={singleTripData.public}
              refreshHandler={props.refreshHandler}
            />
          )}
          <Link to={`/my-account/trip-details/${singleTripData.trip_id}`}>
            <h2>{singleTripData.activity}</h2>
            <p>
              <i className="fa fa-map-marker"></i>
              {singleTripData.location}
            </p>
            <p>
              <i className="fa fa-calendar-o"></i>
              {getDateToStringWithoutTime(
                new Date(singleTripData.start_date)
              )}{" "}
              {singleTripData.end_date
                ? `- ${getDateToStringWithoutTime(
                    new Date(singleTripData.end_date)
                  )}`
                : ""}
            </p>
            <p>
              {singleTripData.public === "true" ? (
                <span>
                  <i className="fa fa-eye"></i>Public
                </span>
              ) : (
                <span>
                  <i className="fa fa-eye-slash"></i>Private
                </span>
              )}
              {singleTripData.created_at && (
                <span className="created-date">
                  <i className="fa fa-calendar"></i>Created:{" "}
                  {getDateToStringWithoutTime(
                    new Date(singleTripData.created_at)
                  )}
                </span>
              )}
            </p>
            <div className="flex items-center">
              <span className={`status status-${singleTripData.status}`}>
                {singleTripData.status.charAt(0).toUpperCase() +
                  singleTripData.status.slice(1)}
              </span>
              {singleTripData.invite_status && (
                <span className="m-0 status invited">
                  Invited By: {singleTripData.invite_sender_name}
                </span>
              )}
            </div>
          </Link>
          <AttendingUsersAvatars
            attendingUsers={singleTripData.attending_user}
          />
        </section>
      </div>
    </li>
  );
};
export default SingleTripCard;
