// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/images/call-to-action.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cal-to-action {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  min-height: 100vh;
  text-align: center;
  background-position: center;
  background-size: cover;
}
.cal-to-action h1 {
  font-size: 40px;
  font-weight: 400;
}
.cal-to-action .signup-btn {
  margin: 0 auto;
  max-width: 300px;
}`, "",{"version":3,"sources":["webpack://./src/pages/splash-page/splashPage.scss"],"names":[],"mappings":"AAAA;EACE,yDAAA;EACA,iBAAA;EACA,kBAAA;EACA,2BAAA;EACA,sBAAA;AACF;AAAE;EACE,eAAA;EACA,gBAAA;AAEJ;AAAE;EACE,cAAA;EACA,gBAAA;AAEJ","sourcesContent":[".cal-to-action {\n  background-image: url(\"../../assets/images/call-to-action.jpg\");\n  min-height: 100vh;\n  text-align: center;\n  background-position: center;\n  background-size: cover;\n  h1 {\n    font-size: 40px;\n    font-weight: 400;\n  }\n  .signup-btn {\n    margin: 0 auto;\n    max-width: 300px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
