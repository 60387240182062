import React, { useEffect, useState } from "react";
import { useGoogleLogin } from "@react-oauth/google";
import GoogleIcon from "../../assets/images/google.svg";
import useHttp from "../../hooks/use-http";
import { getProfile, socialLogin } from "../../lib/api";
import ApiErrors from "../../components/api-errors/ApiErrors";
import { useNavigate } from "react-router-dom";
import { authActions } from "../../store/auth-slice";
import { useDispatch } from "react-redux";
import "./style.scss";

const LoginWithGoogle = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  // Hooks for getProfile request
  const {
    sendRequest: sendGetProfileRequest,
    status: profileStatus,
    error: profileError,
    data: profileData,
  } = useHttp(getProfile);

  // Hooks for socialLogin request
  const {
    sendRequest: sendSocialLoginRequest,
    status: socialLoginStatus,
    error: socialLoginError,
    data: socialLoginData,
  } = useHttp(socialLogin);

  const [showApiErrors, setShowApiErrors] = useState(false);

  // Effect for handling getProfile request
  useEffect(() => {
    if (profileError || socialLoginError) {
      setShowApiErrors(true);
    }

    if (profileStatus === "completed" && profileError === null) {
      // console.log("Profile data:", profileData);

      // Trigger the socialLogin request
      sendSocialLoginRequest({ provider: "google", ...profileData });
    }
  }, [profileStatus, profileError, profileData, sendSocialLoginRequest]);

  // Effect for handling socialLogin request
  useEffect(() => {
    if (socialLoginStatus === "completed" && socialLoginError === null) {
      // console.log("Social login data:", socialLoginData);
      dispatch(authActions.updateAuthToken(socialLoginData));
      if (!socialLoginData?.phone_number) {
        // console.log("Phone No");
        navigate("/phone-number-required", { replace: true });
      } else if (!!localStorage.getItem("location")) {
        navigate("/trip-plan/confirm-trip-details", { replace: true });
      } else {
        // console.log("Account");
        // console.log("3");
        navigate("/trip-plan", { replace: true });
        //navigate("/my-account", { replace: true });
      }
    }
  }, [socialLoginStatus, socialLoginError, socialLoginData]);

  const loginGoogleAction = useGoogleLogin({
    onSuccess: (codeResponse) => {
      sendGetProfileRequest(codeResponse);
    },
    onError: () => {
      setShowApiErrors(true);
    },
  });

  return (
    <>
      {showApiErrors && (
        <ApiErrors>{profileError || socialLoginError}</ApiErrors>
      )}
      <button className="googleLogin" onClick={() => loginGoogleAction()}>
        <div>
          <img src={GoogleIcon} alt="Google Icon" />
        </div>
        <span>Continue with Google</span>
      </button>
    </>
  );
};

export default LoginWithGoogle;
