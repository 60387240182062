import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./updateTrip.scss";
import { Link } from "react-router-dom";
import { Location } from "../create-trip/Location";
import { DateCalendar } from "../create-trip/DateCalendar";
import { AddActivity } from "../create-trip/AddActivity";
import useHttp from "../../hooks/use-http";
import {
  getDateToStringWithoutTime,
  getDateWithoutTime,
  getSingleTripDetails,
} from "../../lib/api";
import { SpinnerModal } from "../../components/ui/SpinnerModal";
import useInput from "../../hooks/use-input";
import { updateTripDetails } from "../../lib/api";
import SpinnerButton from "../../components/ui/SpinnerButton";
import ModalWithInfo from "../../components/ui/ModalWithInfo";
import ApiErrors from "../../components/api-errors/ApiErrors";

export const UpdateTrip = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { sendRequest, status, error, data } = useHttp(getSingleTripDetails);
  const {
    sendRequest: sendUpdateRequest,
    status: sendUpdateStatus,
    error: sendUpdateError,
  } = useHttp(updateTripDetails);
  const {
    value: enteredDescription,
    hasError: descriptionHasError,
    valueIsValid: descriptionIsValid,
    valueChangeHandler: descriptionChangeHandler,
    inputBlurHandler: descriptionBlurHandler,
    isTouched: descriptionIsTouched,
  } = useInput((value) => value.trim().length < 300);

  const [location, setLocation] = useState(null);
  const [locationHasError, setLocationHasError] = useState(false);
  const [locationIsTouched, setLocationIsTouched] = useState(false);

  const [dateRange, setDateRange] = useState([]);
  const [dateHasError, setDateHasError] = useState(false);

  const [activity, setActivity] = useState(null);
  const [emptyActivity, setEmptyActivity] = useState(false);

  const [tripDescription, setTripDescription] = useState("");

  const [tripIsPublic, setTripIsPublic] = useState(null);

  const [showActionInfoModal, setShowActionInfoModal] = useState(false);

  useEffect(() => {
    sendRequest(params.id);
  }, [sendRequest]);

  useEffect(() => {
    if (data) {
      const tripData = data.trip.trip;
      setLocation({
        lat: tripData.latitude,
        lng: tripData.longitude,
        placeName: tripData.location,
      });
      if (tripData.end_date) {
        setDateRange([
          new Date(tripData.start_date),
          new Date(tripData.end_date),
        ]);
      } else {
        setDateRange([new Date(tripData.start_date)]);
      }
      if (tripData.description) {
        setTripDescription(tripData.description);
      }
      setActivity(tripData.activity);
      setTripIsPublic(tripData.public);
    }
  }, [data]);

  const formIsValid =
    descriptionIsValid && !locationHasError && !dateHasError && !emptyActivity;

  const formSubmitHandler = () => {
    if (formIsValid) {
      let selectedDate = [];
      if (
        dateRange[1] &&
        getDateWithoutTime(dateRange[0]).getTime() ===
          getDateWithoutTime(dateRange[1]).getTime()
      ) {
        selectedDate = [getDateToStringWithoutTime(dateRange[0])];
      } else if (dateRange[1]) {
        selectedDate = [
          getDateToStringWithoutTime(dateRange[0]),
          getDateToStringWithoutTime(dateRange[1]),
        ];
      } else {
        selectedDate = [getDateToStringWithoutTime(dateRange[0])];
      }

      const updatedTripData = {
        tripId: params.id,
        data: {
          activity: activity,
          location: location.placeName,
          public: tripIsPublic,
          start_date: selectedDate[0],
          end_date: selectedDate[1] ? selectedDate[1] : null,
          description: descriptionIsTouched
            ? enteredDescription
            : tripDescription,
          latitude: location.lat,
          longitude: location.lng,
        },
      };
      sendUpdateRequest(updatedTripData);
    }
  };

  useEffect(() => {
    if (sendUpdateStatus === "completed" && sendUpdateError === null) {
      setShowActionInfoModal(true);
    }
  }, [sendUpdateStatus, sendUpdateError]);

  const infoModalConfirmationHandler = () => {
    //setShowActionInfoModal(false);
    navigate("/my-account");
  };

  return (
    <>
      {!error ? (
        <>
          {status === "pending" || status === null ? (
            <SpinnerModal />
          ) : (
            <div className="update-trip flex flex-wrap w-100 content-justify-center">
              <div className="container">
                <div className="trail-head-wrapper">
                  <h1 className="trail-title">Update "{activity}" Trip</h1>
                  <div className="phone-numbers">
                    <ul className="list-style-none">
                      <li>
                        <Location
                          isEditing={true}
                          location={location}
                          setLocation={setLocation}
                          setLocationHasError={setLocationHasError}
                          setLocationTouched={setLocationIsTouched}
                        />
                      </li>
                      {locationIsTouched && locationHasError && (
                        <p className="error-msg">Please select a location.</p>
                      )}
                      {dateRange.length > 0 && (
                        <li>
                          <DateCalendar
                            isEditing={true}
                            dateRange={dateRange}
                            dateHasError={dateHasError}
                            setDateHasError={setDateHasError}
                            setDateRange={setDateRange}
                          />
                        </li>
                      )}
                      {dateHasError && (
                        <p className="error-msg">Please select a valid date.</p>
                      )}
                      {activity && (
                        <li>
                          <AddActivity
                            isEditing={true}
                            setEmptyActivity={setEmptyActivity}
                            setActivity={setActivity}
                            activity={activity}
                          />
                        </li>
                      )}
                      {emptyActivity && (
                        <p className="error-msg">
                          Please select or enter an activity.
                        </p>
                      )}
                      {tripIsPublic && (
                        <li>
                          <strong>Private/Public</strong>
                          <select
                            className="number-list"
                            onChange={(event) => {
                              setTripIsPublic(event.target.value);
                            }}
                          >
                            {tripIsPublic === "true" ? (
                              <>
                                <option value="false">Private</option>
                                <option value="true" selected>
                                  Public
                                </option>
                              </>
                            ) : (
                              <>
                                <option value="false" selected>
                                  Private
                                </option>
                                <option value="true">Public</option>
                              </>
                            )}
                          </select>
                        </li>
                      )}
                      <li>
                        <strong>Trip Description (Optional)</strong>
                        <textarea
                          className="number-list"
                          id="tripDescription"
                          placeholder="Trip Description..."
                          defaultValue={tripDescription}
                          onChange={descriptionChangeHandler}
                          onBlur={descriptionBlurHandler}
                        />
                      </li>
                      {descriptionHasError && (
                        <p className="error-msg">
                          Description should be less than 300 characters.
                        </p>
                      )}
                      <li className="btn-flex-wrap">
                        <Link className="signup-btn" to="/my-account">
                          Cancel
                        </Link>
                        <button
                          type="submit"
                          onClick={formSubmitHandler}
                          className={`signup-btn ${
                            (!emptyActivity &&
                              !dateHasError &&
                              !locationHasError &&
                              !descriptionHasError) ||
                            sendUpdateStatus === "pending"
                              ? ""
                              : "disabled-link"
                          }`}
                        >
                          Update Trip{" "}
                          {sendUpdateStatus === "pending" ? (
                            <SpinnerButton />
                          ) : (
                            ""
                          )}
                        </button>
                      </li>
                      {sendUpdateError &&
                        "There has been an error while updating the trip. Please try again later."}
                    </ul>
                    {showActionInfoModal && (
                      <ModalWithInfo
                        heading="Success"
                        message="The trip has been successfully updated."
                        showModal={infoModalConfirmationHandler}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      ) : (
        <div className="main-profile-detail flex flex-wrap column-direction w-100">
          <ApiErrors>{error}</ApiErrors>
          <p className="no-trip">
            An error occured while fetching data from Backend. Please try again
            later.
          </p>
        </div>
      )}
    </>
  );
};
