import SingleTripCard from "./SingleTripCard";
import AddPagination from "../../components/ui/AddPagination";
import React, { useState } from "react";

const InvitedTrips = (props) => {
    // console.log('I am here in invited', props)
  const pendingInvitedTrips = props.invitedTrips.filter(
    (trip) => trip.invite_status === "invited"
  );

  const acceptedInvitedTrips = props.invitedTrips.filter(
    (trip) => trip.invite_status === "accepted"
  );

  const [paginatedPendingInvites, setPaginatedPendingInvites] =
    useState(pendingInvitedTrips);

  const [paginatedAcceptedInvites, setPaginatedAcceptedInvites] =
    useState(acceptedInvitedTrips);

  return (
    <>
      <h2 className="trail-title">Accepted Invites</h2>
      <ul className="list-style-none cards-wrapper flex flex-wrap">
        {acceptedInvitedTrips.length > 0 ? (
          paginatedAcceptedInvites.map((trip) => (
            <SingleTripCard
              key={trip.trip_id}
              singleTripData={trip}
              refreshHandler={props.refreshHandler}
            />
          ))
        ) : (
          <p className="no-trip">No Trips Found</p>
        )}
        <AddPagination
          allData={acceptedInvitedTrips}
          setPaginatedData={setPaginatedAcceptedInvites}
        />
      </ul>
      <h2 className="trail-title">Pending Invites</h2>
      <ul className="list-style-none cards-wrapper flex flex-wrap">
        {pendingInvitedTrips.length > 0 ? (
          paginatedPendingInvites.map((trip) => (
            <SingleTripCard
              key={trip.trip_id}
              singleTripData={trip}
              refreshHandler={props.refreshHandler}
            />
          ))
        ) : (
          <p className="no-trip">No Trips Found</p>
        )}
        <AddPagination
          allData={pendingInvitedTrips}
          setPaginatedData={setPaginatedPendingInvites}
        />
      </ul>
    </>
  );
};

export default InvitedTrips;
