import useHttp from "../../hooks/use-http";
import useInput from "../../hooks/use-input";
import validator from "validator";
import ApiErrors from "../../components/api-errors/ApiErrors";
import { updateSocialLoginPhone, logoutUser } from "../../lib/api";
import React, { useEffect, useState } from "react";
import {useDispatch, useSelector} from "react-redux";
import { authActions } from "../../store/auth-slice";
import { useNavigate } from "react-router-dom";
import ModalWithInfo from "../../components/ui/ModalWithInfo";
import SpinnerButton from "../../components/ui/SpinnerButton";
import ModalWithState from "../../components/ui/ModalWithState";
import "../../pages/signup/signUp.scss";

const SocialLoginPhoneUpdate = () => {
  const { sendRequest, status, error, data } = useHttp(updateSocialLoginPhone);
  const {
    sendRequest: sendLogoutRequest,
    status: logoutStatus,
    error: logoutError,
  } = useHttp(logoutUser);

  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [logoutConfirmation, setLogoutConfirmation] = useState(false);
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const isUserLoggedIn = useSelector((state) => state.auth.isUserLoggedIn);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    value: enteredPhone,
    hasError: phoneHasError,
    valueIsValid: phoneIsValid,
    valueChangeHandler: phoneChangeHandler,
    inputBlurHandler: phoneBlurHandler,
  } = useInput((value) => validator.isMobilePhone(value + "", "en-US"));

  const formSubmitHandler = (event) => {
    event.preventDefault();
    sendRequest({ phone_number: enteredPhone });
  };

  useEffect(() => {
    if (status === "completed" && !error) {
      //If phone is successfully set, update social login without phone status,
      //and show success modal.
      dispatch(authActions.updateSocialLoginWithoutPhone("true"));
      setShowSuccessModal(true);
    }
  }, [status, error, data, dispatch]);

  const infoModalConfirmationHandler = () => {
    //Redirect to Logged in Pages
    if (!!localStorage.getItem("location")) {
      navigate("/trip-plan/confirm-trip-details", { replace: true });
    } else {
      navigate("/trip-plan", { replace: true });
    }
  };

  const logoutHandler = (event) => {
    event.preventDefault();
    setShowLogoutModal(true);
  };

  useEffect(() => {
    if (logoutConfirmation) {
      sendLogoutRequest();
      dispatch(authActions.logoutUser());
    }
  }, [logoutConfirmation, dispatch, sendLogoutRequest]);

  useEffect(() => {
    if (logoutStatus === "completed" && logoutError === null) {
      navigate("/signin", { replace: true });
    }
  }, [logoutStatus, logoutError, navigate]);

  return (
    <div className="main-trail-head sign-in phone-required flex flex-wrap w-100 content-justify-center">
      <div className="container">
        <div className="trail-head-wrapper">
          <h1 className="trail-title">Phone Number Required</h1>
          <p>
            Please add your phone number to continue to your account. Phone
            numbers are required to receive trip invites, so that you can stay
            updated. Thank you!
            <br />
            <small>
              [Phone number will not be required next time you login with
              Google.]
            </small>
          </p>

          <form className="phone-numbers" onSubmit={formSubmitHandler}>
            <ul className="list-style-none">
              <li>
                <input
                  type="text"
                  placeholder="Phone Number"
                  className="number-list"
                  id="phone"
                  value={enteredPhone}
                  onBlur={phoneBlurHandler}
                  onChange={phoneChangeHandler}
                />
              </li>
              {phoneHasError && (
                <p className="error-msg">
                  Please enter a valid phone number with 10 digits.
                </p>
              )}
              {error && <ApiErrors>{error}</ApiErrors>}
              <li>
                <button
                  type="submit"
                  className={`signup-btn submit-btn ${
                    !phoneIsValid || status === "pending" ? "disabled-link" : ""
                  }`}
                >
                  Update Phone {status === "pending" && <SpinnerButton />}
                </button>
              </li>
              {!isUserLoggedIn && (
                  <>
                    <li>Want to signup with some other method?</li>
                    <li>
                      <button
                          onClick={logoutHandler}
                          className={`signup-btn submit-btn`}
                      >
                        Abort Sign Up with Google
                      </button>
                    </li>
                  </>
              )}
              {logoutError && <ApiErrors>{logoutError}</ApiErrors>}
            </ul>
          </form>
          {showSuccessModal && (
              <ModalWithInfo
                  showModal={infoModalConfirmationHandler}
                  heading="Thank you!"
                  message="Your phone number is successfully updated!"
            />
          )}
          {showLogoutModal && (
            <ModalWithState
              showModal={setShowLogoutModal}
              onConfirmModal={setLogoutConfirmation}
              message="Are you sure you would like to abort signup with Google?"
            />
          )}
        </div>
      </div>
    </div>
  );
};
export default SocialLoginPhoneUpdate;
