// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin-sidebar {
  width: 250px;
  background: rgba(40, 62, 50, 0.7);
  padding: 1rem;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  height: 100vh;
  position: relative;
  display: block;
}
.admin-sidebar .hamburger-menu {
  display: none;
  font-size: 2rem;
  color: #fff;
  cursor: pointer;
}
.admin-sidebar .sidebar-title {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: #fff;
}
.admin-sidebar .sidebar-menu {
  list-style: none;
  padding: 0;
}
.admin-sidebar .sidebar-menu li {
  margin-bottom: 1rem;
}
.admin-sidebar .sidebar-menu li a {
  display: block;
  padding: 0.5rem;
  text-decoration: none;
  text-transform: capitalize;
  color: #fff;
  font-size: 1rem;
  border-radius: 4px;
  transition: background-color 0.3s;
}
.admin-sidebar .sidebar-menu li a:hover {
  background-color: #ddd;
  color: #000;
}
.admin-sidebar .sidebar-menu.open {
  display: block;
}

@media (max-width: 800px) {
  .admin-sidebar {
    width: 100%;
    height: auto;
    display: none;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/admin/admin-sidebar/AdminSidebar.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,iCAAA;EACA,aAAA;EACA,wCAAA;EACA,aAAA;EACA,kBAAA;EACA,cAAA;AACJ;AACI;EACI,aAAA;EACA,eAAA;EACA,WAAA;EACA,eAAA;AACR;AAEI;EACI,iBAAA;EACA,mBAAA;EACA,WAAA;AAAR;AAGI;EACI,gBAAA;EACA,UAAA;AADR;AAGQ;EACI,mBAAA;AADZ;AAGY;EACI,cAAA;EACA,eAAA;EACA,qBAAA;EACA,0BAAA;EACA,WAAA;EACA,eAAA;EACA,kBAAA;EACA,iCAAA;AADhB;AAGgB;EACI,sBAAA;EACA,WAAA;AADpB;AAMQ;EACI,cAAA;AAJZ;;AASA;EACI;IACI,WAAA;IACA,YAAA;IACA,aAAA;EANN;AACF","sourcesContent":[".admin-sidebar {\n    width: 250px; \n    background: rgba(40, 62, 50, 0.7);\n    padding: 1rem; \n    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1); \n    height: 100vh; \n    position: relative;\n    display: block;\n\n    .hamburger-menu {\n        display: none;\n        font-size: 2rem;\n        color: #fff;\n        cursor: pointer;\n    }\n\n    .sidebar-title {\n        font-size: 1.5rem; \n        margin-bottom: 1rem; \n        color: #fff;\n    }\n\n    .sidebar-menu {\n        list-style: none; \n        padding: 0;\n\n        li {\n            margin-bottom: 1rem;\n\n            a {\n                display: block; \n                padding: 0.5rem;\n                text-decoration: none;\n                text-transform: capitalize;\n                color: #fff;\n                font-size: 1rem; \n                border-radius: 4px; \n                transition: background-color 0.3s;\n\n                &:hover {\n                    background-color: #ddd;\n                    color: #000;\n                }\n            }\n        }\n\n        &.open {\n            display: block;\n        }\n    }\n}\n\n@media (max-width: 800px) {\n    .admin-sidebar {\n        width: 100%;\n        height: auto;\n        display: none;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
