import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isUserLoggedIn: !!localStorage.getItem("user-token"),
  authToken: localStorage.getItem("user-token"),
  userId: localStorage.getItem("user-id"),
  userImage: localStorage.getItem("user-image"),
  socialLoginWithoutPhone:
    localStorage.getItem("social-no-phone") === "true" ? true : false,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    updateAuthToken(state, action) {
      localStorage.setItem("user-token", action.payload.token);
      localStorage.setItem("user-id", action.payload.user_id);
      localStorage.setItem("user-image", action.payload.image);
      if (action?.payload?.phone_number) {
        localStorage.setItem("social-no-phone", "true");
        state.socialLoginWithoutPhone = true;
      }
      // const {token, user_id}= action.payload;
      // state = {
      //   ...state,
      //   userId: user_id,
      //   authToken: token
      // }
      state.authToken = action.payload.token;
      state.userId = action.payload.user_id;
      state.isUserLoggedIn = true;
      state.userImage = action.payload.image;
    },
    updateSocialLoginWithoutPhone(state, action) {
      localStorage.setItem("social-no-phone", action.payload);
      state.socialLoginWithoutPhone = action.payload;
    },
    updateUserProfileImage(state, action) {
      state.userImage = action.payload;
      localStorage.setItem("user-image", action.payload);
    },
    logoutUser(state) {
      localStorage.removeItem("user-token");
      localStorage.removeItem("user-id");
      localStorage.removeItem("user-image");
      localStorage.removeItem("social-no-phone");
      state.authToken = null;
      state.isUserLoggedIn = false;
      state.userId = null;
      state.userImage = null;
      state.socialLoginWithoutPhone = null;
    },
  },
});

export const authActions = authSlice.actions;
export default authSlice.reducer;
