import React, { useState } from "react";
import NoGearImage from "../../../../assets/images/no-gear-img.webp";
import { getDateToStringWithoutTime } from "../../../../lib/api";
import AdminGearDropDownList from "./AdminGearDropDownList";
import AddPagination from "../../../../components/ui/AddPagination";

export const AdminGroupGear = (props) => {
  const groupGear = props.groupGear;
  const [paginatedData, setPaginatedData] = useState(groupGear);
  const handleError = (e) => {
    e.target.src = NoGearImage;
  };
  return (
    <>
      <div className="side-cards-wrapper">
        <ul className="list-style-none flex column-direction flex-wrap">
          {/* Printing My Group Gear */}
          {groupGear.length > 0 ? (
            <>
              {paginatedData.map((gearItem) => (
                <li key={gearItem.id}>
                  <div className="side-cards-wrap">
                    <img
                      src={gearItem.item_image || NoGearImage}
                      alt="img"
                      onError={handleError}
                      style={{
                        width: "80px",
                        height: "80px",
                        objectFit: "cover",
                      }}
                    />
                    <section>
                      <h3>{gearItem.item_name}</h3>
                      <p>{gearItem.item_description}</p>
                      <div className="flex gear-items">
                        <p>
                          <strong>
                            <i className="fa fa-bar-chart"></i> Quantity:
                          </strong>{" "}
                          {gearItem.item_quantity}
                        </p>
                        <p>
                          <i className="fa fa-user"></i> {gearItem.username}{" "}
                        </p>
                        <p>
                          <strong>
                            <i className="fa fa-clock-o"></i> Date:
                          </strong>{" "}
                          {getDateToStringWithoutTime(
                            new Date(gearItem.created_at)
                          )}{" "}
                        </p>
                      </div>
                    </section>
                  </div>
                  <AdminGearDropDownList
                    gearId={gearItem.id}
                    refreshHandler={props.refreshHandler}
                    isGroupGear={true}
                  />
                </li>
              ))}
            </>
          ) : (
            <li className="no-gear">No group gear is added.</li>
          )}
        </ul>
        <AddPagination
          allData={groupGear}
          setPaginatedData={setPaginatedData}
          pageSize={5}
        />
      </div>
    </>
  );
};
