import React, { useState, useEffect } from "react";
import useHttp from "../../../hooks/use-http";
import useInput from "../../../hooks/use-input";
import Calendar from "react-calendar";
import OutsideClickHandler from "react-outside-click-handler";
import SpinnerButton from "../../../components/ui/SpinnerButton";
import {
  getDateToStringWithoutTime,
  getDateWithoutTime,
} from "../../../lib/api";
import { searchTrips } from "../../../lib/admin-api";
import AdminApiErrors from "../../../components/api-errors/AdminApiErrors";

const AdminTripsSearch = (props) => {
  const [selectedTripStatus, setSelectedTripStatus] = useState("All");
  const [selectedTripVisibility, setSelectedTripVisibility] = useState("All");
  const [date, setDate] = useState([new Date()]);
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [isDateFilterActive, setIsDateFilterActive] = useState(false);
  const [showApiErrors, setShowApiErrors] = useState(false);
  const { sendRequest, status, error, data } = useHttp(searchTrips);
  const {
    value: enteredName,
    valueChangeHandler: nameChangeHandler,
    inputBlurHandler: nameBlurHandler,
    resetValue: resetName,
  } = useInput((value) => value.trim().length > 0 && value.trim().length < 99);

  const {
    value: enteredActivity,
    valueChangeHandler: activityChangeHandler,
    inputBlurHandler: activityBlurHandler,
    resetValue: resetActivity,
  } = useInput((value) => value.trim().length > 0 && value.trim().length < 99);

  const {
    value: enteredLocation,
    valueChangeHandler: locationChangeHandler,
    inputBlurHandler: locationBlurHandler,
    resetValue: resetLocation,
  } = useInput((value) => value.trim().length > 0 && value.trim().length < 99);

  const formIsInvalid = false;

  const formSubmitHandler = (event) => {
    event.preventDefault();
    if (!formIsInvalid) {
      //Constructing the search parameters string.
      let searchParams =
        `${enteredName === "" ? "" : `username=${enteredName}`}` +
        `${enteredActivity === "" ? "" : `&activity=${enteredActivity}`}` +
        `${enteredLocation === "" ? "" : `&location=${enteredLocation}`}` +
        `${
          selectedTripStatus === "All"
            ? ""
            : `&trip_status=${selectedTripStatus}`
        }` +
        `${
          selectedTripVisibility === "All"
            ? ""
            : `&public=${selectedTripVisibility}`
        }` +
        `${
          isDateFilterActive && date[1]
            ? `&start_date=${getDateToStringWithoutTime(
                date[0]
              )}&end_date=${getDateToStringWithoutTime(date[1])}`
            : ""
        }` +
        `${
          isDateFilterActive && !date[1]
            ? `&start_date=${getDateToStringWithoutTime(date[0])}`
            : ""
        }`;

      // If there is & in the start of parameters, then remove that. It will happen when username is empty.
      if (searchParams.charAt(0) === "&") {
        searchParams = searchParams.slice(1);
      }
      sendRequest(searchParams);
    }
  };

  useEffect(() => {
    if (status === "completed" && error === null) {
      props.setSearchResults(data);
      props.setIsSearching(true);
    }
    if (status === "completed" && error) {
      setShowApiErrors(true);
    }
  }, [status, data, error]);

  const toggleCalendar = () => {
    setIsCalendarOpen(!isCalendarOpen);
  };

  const dateChangeHandler = (selectedDate) => {
    setDate(selectedDate);
    setIsCalendarOpen(false);
  };

  const displayDate = () => {
    if (date[1]) {
      if (
        getDateWithoutTime(date[0]).getTime() ===
        getDateWithoutTime(date[1]).getTime()
      ) {
        setDate([date[0]]);
        return getDateToStringWithoutTime(date[0]);
      } else {
        return (
          getDateToStringWithoutTime(date[0]) +
          " - " +
          getDateToStringWithoutTime(date[1])
        );
      }
    }
    return getDateToStringWithoutTime(date[0]);
  };

  return (
    <div className="admin-search-wrapper">
      <h2 className="search-title">Search</h2>
      <form onSubmit={formSubmitHandler}>
        <ul className="list-style-none flex flex-wrap items-start">
          <li>
            <input
              type="text"
              className="number-list"
              placeholder="User Name"
              value={enteredName}
              onChange={nameChangeHandler}
              onBlur={nameBlurHandler}
            />
          </li>
          <li>
            <input
              type="text"
              className="number-list"
              placeholder="Activity"
              value={enteredActivity}
              onChange={activityChangeHandler}
              onBlur={activityBlurHandler}
            />
          </li>
          <li>
            <input
              type="text"
              className="number-list"
              placeholder="Location"
              value={enteredLocation}
              onChange={locationChangeHandler}
              onBlur={locationBlurHandler}
            />
          </li>
          <OutsideClickHandler
            onOutsideClick={() => {
              setIsCalendarOpen(false);
            }}
          >
            <li>
              <input
                type="checkbox"
                checked={isDateFilterActive}
                onChange={() => {
                  setIsDateFilterActive(!isDateFilterActive);
                }}
              />
              Date Filter
              <button
                type="button"
                className={`number-list ${
                  !isDateFilterActive && "disabled-link"
                } ${isCalendarOpen ? "expanded" : ""}`}
                onClick={toggleCalendar}
              >
                <strong>Date / Date Range: </strong>
                <span>{displayDate()}</span>
              </button>
              <div className={`calender-wrap ${isCalendarOpen ? "show" : ""}`}>
                <Calendar
                  onChange={dateChangeHandler}
                  closeCalendar={true}
                  selectRange={true}
                />
              </div>
            </li>
          </OutsideClickHandler>
          <li>
            <label>Trip Status</label>
            <select
              onChange={(event) => {
                setSelectedTripStatus(event.target.value);
              }}
              className="number-list"
              value={selectedTripStatus}
            >
              <option value="All">All</option>
              <option value="active">Active</option>
              <option value="completed">Completed</option>
              <option value="canceled">Canceled</option>
            </select>
          </li>
          <li>
            <label>Trip Visibility</label>
            <select
              onChange={(event) => {
                setSelectedTripVisibility(event.target.value);
              }}
              className="number-list"
              value={selectedTripVisibility}
            >
              <option value="All">All</option>
              <option value="true">Public</option>
              <option value="false">Private</option>
            </select>
          </li>
          <li>
            <button
              type="submit"
              className={`signup-btn submit-btn  ${
                status === "pending" ? "disabled-link" : ""
              }`}
            >
              Search{status === "pending" && <SpinnerButton />}
            </button>
          </li>

          <li>
            <button
              onClick={(event) => {
                event.preventDefault();
                resetName();
                resetActivity();
                resetLocation();
                setDate([new Date()]);
                setSelectedTripStatus("All");
                setSelectedTripVisibility("All");
                setIsDateFilterActive(false);
                props.setIsSearching(false);
              }}
              className={"signup-btn submit-btn"}
            >
              Reset
            </button>
          </li>
        </ul>
      </form>
      {showApiErrors && <AdminApiErrors>{error}</AdminApiErrors>}
    </div>
  );
};

export default AdminTripsSearch;
