import ReactDOM from "react-dom/client";
import { Main } from "./layout/Main";
import { Provider } from "react-redux";
import store from "./store/index";
import React, { useEffect } from 'react';
import { GoogleOAuthProvider } from "@react-oauth/google";
import { initializeAnalytics, logPageView } from './analytics'; // Import the analytics setup

import "./assets/scss/global.scss";
import "./assets/scss/font-awesome.css";

// Initialize Google Analytics
initializeAnalytics();

const App = () => {
    useEffect(() => {
        logPageView(); // Log the page view on initial render
    }, []);

    return (
        <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
            <Provider store={store}>
                <Main />
            </Provider>
        </GoogleOAuthProvider>
    );
};

ReactDOM.createRoot(document.getElementById("root")).render(<App />);
