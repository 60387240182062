import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { getDateToStringWithoutTime } from "../../../lib/api";
import AddPagination from "../../../components/ui/AddPagination";

export const AdminTripsList = (props) => {
  const trips = props.tripData;
  const [paginatedData, setPaginatedData] = useState(trips);
  const [showPagination, setShowPagination] = useState(true);

  //As the paginated data is a state, it did not update when the component was re-rendered
  //with updated trip data for search results. Due to this, this useEffect is added, to
  //refresh the AddPagination component so that it uses the latest user data
  useEffect(() => {
    setShowPagination(false);
    setTimeout(() => {
      setShowPagination(true);
    }, 50);
  }, [trips]);

  return (
    <>
      <table className="table">
        <thead>
          <tr>
            <th>Trip Id</th>
            <th>Created</th>
            <th>Trip Date</th>
            <th>Activity</th>
            <th>Location</th>
            <th>Trip Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        {trips.length > 0 ? (
          <tbody>
            {paginatedData.map((trip) => (
              <tr key={`trip-${trip.trip_id}`}>
                <td>{trip.trip_id}</td>
                <td>{getDateToStringWithoutTime(new Date(trip.created_at))}</td>
                <td>
                  {getDateToStringWithoutTime(new Date(trip.start_date))}
                  {trip.end_date &&
                    ` - ${getDateToStringWithoutTime(new Date(trip.end_date))}`}
                </td>
                <td>{trip.activity}</td>
                <td>{trip.location}</td>
                <td>
                  {trip.status.charAt(0).toUpperCase() + trip.status.slice(1)}
                </td>
                <td>
                  <div className="flex content-justify-center items-center">
                    <Link
                      className="table-btn"
                      to={`/admin-32423QeCFer/trip-details/${trip.trip_id}`}
                    >
                      View Details
                    </Link>
                    <Link
                      className="table-btn"
                      to={`/admin-32423QeCFer/user-details/${trip.user_id}`}
                    >
                      User Details
                    </Link>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        ) : (
          <tbody>
            <tr>
              <td colSpan={6}>No Data Found</td>
            </tr>
          </tbody>
        )}
      </table>
      {showPagination && (
        <AddPagination
          allData={trips}
          setPaginatedData={setPaginatedData}
          pageSize={5}
        />
      )}
    </>
  );
};
