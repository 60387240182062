import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import React from 'react'

const CheckSocialLoginWithoutPhone = ({ children }) => {
  if (children) {
    const isUserLoggedIn = useSelector((state) => state.auth.isUserLoggedIn);
    const isSocialLoginWithoutPhone = useSelector(
        (state) => state.auth.socialLoginWithoutPhone
    );
    if (isUserLoggedIn && !isSocialLoginWithoutPhone) {
      return <Navigate to="/phone-number-required" replace />;
    }
  }
  return children;
};

export default CheckSocialLoginWithoutPhone;
