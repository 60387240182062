import InvitedTrips from "./InvitedTrips";
import MyTrips from "./MyTrips";
import { getAllUserTrips } from "../../lib/api";
import useHttp from "../../hooks/use-http";
import "./profile.scss";
import React, { useEffect, useState } from "react";
import { SpinnerModal } from "../../components/ui/SpinnerModal";
import ApiErrors from "../../components/api-errors/ApiErrors";

const UserDashboard = () => {
  const { sendRequest, status, error, data } = useHttp(getAllUserTrips);

  //When the status of a trip changes in a child component, we need to refetch and re-render all the cards.
  const [refreshComponent, setRefreshComponent] = useState(false);
  const refreshHandler = () => {
    setRefreshComponent(!refreshComponent);
  };

  useEffect(() => {
    sendRequest();
  }, [sendRequest, refreshComponent]);

  // Handle Errors

  return (
    <>
      <div className="main-profile-list flex flex-wrap w-100 content-justify-center">
        {!error ? (
          <div className="container">
            {status === "pending" || status === null ? (
              <SpinnerModal />
            ) : (
              <>
                <MyTrips
                  myTrips={data.allTrips}
                  refreshHandler={refreshHandler}
                />
                <InvitedTrips
                  invitedTrips={data.invitedTrips}
                  refreshHandler={refreshHandler}
                />
              </>
            )}
            {status === "completed" && error && <p>An error occured.</p>}
          </div>
        ) : (
          <p>
            <ApiErrors>{error}</ApiErrors>
            An error occured while fetching data from Backend. Please try again
            later.
          </p>
        )}
      </div>
    </>
  );
};

export default UserDashboard;
