import React from 'react'

const ModalWithState = (props) => {
  return (
    <div className="confirm-modal">
      <div className="confirm-modal-content">
        <h2>{props.message}</h2>
        <section>
          <button
            className="signup-btn hover-btn"
            onClick={() => {
              props.showModal(false);
            }}
          >
            No
          </button>
          <button
            className="signup-btn"
            onClick={() => {
              props.onConfirmModal(true);
              props.showModal(false);
            }}
          >
            YES
          </button>
        </section>
      </div>
    </div>
  );
};

export default ModalWithState;
