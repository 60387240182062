// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashboard-widget {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  justify-content: start;
}
.dashboard-widget .widget-item {
  display: flex;
  align-items: center;
  gap: 30px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  color: #000;
  width: 275px;
  justify-content: space-between;
}
.dashboard-widget .widget-item .widget-icon {
  font-size: 2rem;
  color: #e5b021;
}
.dashboard-widget .widget-item .textArea h2 {
  font-size: 0.875rem;
  color: #000000;
  font-weight: 500;
  margin: 0;
}
.dashboard-widget .widget-item .textArea p {
  font-size: 1.5rem;
  font-weight: bold;
  color: #000000;
  margin: 0;
}
.dashboard-widget .widget-item .iconArea {
  padding: 13px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: rgb(40, 62, 50);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

@media (max-width: 991px) {
  .dashboard-widget {
    justify-content: center;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/admin/admin-dashboard/style.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,eAAA;EACA,SAAA;EACA,sBAAA;AACF;AAAE;EACE,aAAA;EACA,mBAAA;EACA,SAAA;EACA,gBAAA;EACA,mBAAA;EACA,wCAAA;EACA,aAAA;EACA,WAAA;EACA,YAAA;EACA,8BAAA;AAEJ;AADI;EACE,eAAA;EACA,cAAA;AAGN;AAAM;EACE,mBAAA;EACA,cAAA;EACA,gBAAA;EACA,SAAA;AAER;AACM;EACE,iBAAA;EACA,iBAAA;EACA,cAAA;EACA,SAAA;AACR;AAEI;EACE,aAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;EACA,2BAAA;EACA,wCAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,kBAAA;AAAN;;AAKA;EACI;IACE,uBAAA;EAFJ;AACF","sourcesContent":[".dashboard-widget {\n  display: flex;\n  flex-wrap: wrap;\n  gap: 15px;\n  justify-content: start;\n  .widget-item {\n    display: flex;\n    align-items: center;\n    gap: 30px;\n    background: #fff;\n    border-radius: 10px;\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n    padding: 20px;\n    color: #000;\n    width: 275px;\n    justify-content: space-between;\n    .widget-icon {\n      font-size: 2rem;\n      color: #e5b021;\n    }\n    .textArea {\n      h2 {\n        font-size: 0.875rem;\n        color: #000000;\n        font-weight: 500;\n        margin: 0;\n      }\n\n      p {\n        font-size: 1.5rem;\n        font-weight: bold;\n        color: #000000;\n        margin: 0;\n      }\n    }\n    .iconArea {\n      padding: 13px;\n      width: 50px;\n      height: 50px;\n      border-radius: 50%;\n      background: rgba(40, 62, 50);\n      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);\n      display: flex;\n      align-items: center;\n      justify-content: center;\n      text-align: center;\n    }\n  }\n}\n\n@media (max-width:991px) {\n    .dashboard-widget{\n      justify-content: center;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
