import React from "react";
import {
  FaSuitcase,
  FaUserPlus,
  FaCalendarDay,
  FaCalendarWeek,
  FaSignInAlt,
  FaEnvelope,
  FaTshirt,
  FaUsers,
  FaRedoAlt,
  FaCheckCircle,
  FaTimesCircle,
} from "react-icons/fa";
import "./style.scss";

export const DashboarWidget = ({ data }) => {
  return (
      <div className="dashboard-widget">
          <div className="widget-item">
              <div className="textArea">
                  <h2>Created Trips</h2>
                  <p>{data.totalCreatedTrips}</p>
              </div>
              <div className="iconArea">
                  <FaSuitcase className="widget-icon"/>
              </div>
          </div>
          <div className="widget-item">
              <div className="textArea">
                  <h2>Total Signups</h2>
                  <p>{data.allTimeSignups}</p>
              </div>
              <div className="iconArea">
                  <FaUserPlus className="widget-icon"/>
              </div>
          </div>
          <div className="widget-item">
              <div className="textArea">
                  <h2>Daily Signups</h2>
                  <p>{data.dailySignups}</p>
              </div>
              <div className="iconArea">
                  <FaCalendarDay className="widget-icon"/>
              </div>
          </div>
          <div className="widget-item">
              <div className="textArea">
                  <h2>Weekly Signups</h2>
                  <p>{data.weeklySignups}</p>
              </div>
              <div className="iconArea">
                  <FaCalendarWeek className="widget-icon"/>
              </div>
          </div>
          <div className="widget-item">
              <div className="textArea">
                  <h2>7-Day Logins</h2>
                  <p>{data.last7DaysLogins}</p>
              </div>
              <div className="iconArea">
                  <FaSignInAlt className="widget-icon"/>
              </div>
          </div>
          <div className="widget-item">
              <div className="textArea">
                  <h2>Invites</h2>
                  <p>{data.invites}</p>
              </div>
              <div className="iconArea">
                  <FaEnvelope className="widget-icon"/>
              </div>
          </div>
          <div className="widget-item">
              <div className="textArea">
                  <h2>Personal Gear</h2>
                  <p>{data.personalGear}</p>
              </div>
              <div className="iconArea">
                  <FaTshirt className="widget-icon"/>
              </div>
          </div>
          <div className="widget-item">
              <div className="textArea">
                  <h2>Group Gear</h2>
                  <p>{data.groupGear}</p>
              </div>
              <div className="iconArea">
                  <FaUsers className="widget-icon"/>
              </div>
          </div>
          <div className="widget-item">
              <div className="textArea">
                  <h2>User Return Rate</h2>
                  <p>{data.userReturnRate} %</p>
              </div>
              <div className="iconArea">
                  <FaRedoAlt className="widget-icon"/>
              </div>
          </div>
          <div className="widget-item">
              <div className="textArea">
                  <h2>Trip Completion Rate</h2>
                  <p>{data.tripCompletionRate} %</p>
              </div>
              <div className="iconArea">
                  <FaCheckCircle className="widget-icon"/>
              </div>
          </div>
          <div className="widget-item">
              <div className="textArea">
                  <h2>Trip Canceled Rate</h2>
                  <p>{data.tripCanceledRate} %</p>
              </div>
              <div className="iconArea">
                  <FaTimesCircle className="widget-icon"/>
              </div>
          </div>
      </div>
  );
};
