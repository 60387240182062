import { configureStore } from "@reduxjs/toolkit";
import newTripReducer from "./new-trip-slice";
import authReducer from "./auth-slice";
import adminAuthReducer from "./admin-auth-slice";

const store = configureStore({
  reducer: {
    newTrip: newTripReducer,
    auth: authReducer,
    adminAuth: adminAuthReducer,
  },
});

export default store;
