import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AddPagination from "../../../components/ui/AddPagination";

export const AdminUsersList = (props) => {
  const allUsers = props.data;
  const [paginatedData, setPaginatedData] = useState(allUsers);
  const [showPagination, setShowPagination] = useState(true);

  //As the paginated data is a state, it did not update when the component was re-rendered
  //with updated user data for search results. Due to this, this useEffect is added, to
  //refresh the AddPagination component so that it uses the latest user data
  useEffect(() => {
    setShowPagination(false);
    setTimeout(() => {
      setShowPagination(true);
    }, 50);
  }, [allUsers]);

  return (
    <>
      <table className="table">
        <thead>
          <tr>
            <th>User Id</th>
            <th>Name</th>
            <th>Email</th>
            <th>Phone</th>
            <th>Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {allUsers &&
            paginatedData.map((user) => (
              <tr key={`user-${user.id}`}>
                <td>{user.id}</td>
                <td>
                  <Link to={`/admin-32423QeCFer/user-details/${user.id}`}>
                    {user.name}
                  </Link>
                </td>
                <td>{user.email}</td>
                <td>{user.phone_number}</td>
                <td>
                  {user.status.charAt(0).toUpperCase() +
                    user.status.slice(1) +
                    `${user.status === "block" ? "ed" : ""}`}
                </td>
                <td>
                  <div className="flex content-justify-center items-center">
                    <Link
                      className="table-btn signup-btn submit-btn"
                      to={`/admin-32423QeCFer/user-details/${user.id}`}
                    >
                      View Details
                    </Link>
                  </div>
                </td>
              </tr>
            ))}
          {(allUsers === null || (allUsers && allUsers.length === 0)) && (
            <tr>
              <td colSpan={6}>No Records Found.</td>
            </tr>
          )}
        </tbody>
      </table>
      {showPagination && (
        <AddPagination
          allData={allUsers}
          setPaginatedData={setPaginatedData}
          pageSize={10}
        />
      )}
    </>
  );
};
