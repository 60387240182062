import React, { useEffect, useState } from "react";

import { Link, useNavigate } from "react-router-dom";
import headerLogo from "../../assets/images/header-logo.svg";
import avatarSmall from "../../assets/images/avatar-small.png";
import OutsideClickHandler from "react-outside-click-handler";
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "../../store/auth-slice";
import { logoutUser } from "../../lib/api";
import useHttp from "../../hooks/use-http";
import "./header.scss";

export const UserHeader = () => {
  const [isProfileDropdownOpen, setIsProfileDropdownOpen] = useState(false);
  const [isLoggingOut, setIsLoggingOut] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { sendRequest, status, error } = useHttp(logoutUser);

  const isLoggedIn = useSelector((state) => state.auth.isUserLoggedIn);
  const userImage = useSelector((state) => state.auth.userImage);
  const toggleProfileDropdown = () => {
    setIsProfileDropdownOpen(!isProfileDropdownOpen);
  };

  const logOutHandler = () => {
    setIsLoggingOut(true);
    sendRequest();
    dispatch(authActions.logoutUser());
  };

  useEffect(() => {
    if (isLoggingOut && status === "completed" && error === null) {
      setIsLoggingOut(false);
      navigate("/signin", { replace: true });
    }
  }, [status, error, dispatch, navigate]);

  const handleError = (e) => {
    e.target.src = avatarSmall;
  };

  return (
    <>
      <header className="header-wrapper">
        <div className="container flex items-center">
          <div className="logo">
            <Link to="/trip-plan">
              <img src={headerLogo} alt="logo" />
            </Link>
          </div>
          <div className="navigation-wrap ml-auto flex">
            <ul className="list-style-none flex items-center navigation">
              <li>
                <Link to="/trip-plan">Create Trip</Link>
              </li>
              <li>
                <Link to="/trip-plan">About</Link>
              </li>
              {isLoggedIn ? (
                <li>
                  <Link to="/my-account">My trips</Link>
                </li>
              ) : (
                ""
              )}
            </ul>
            {isLoggedIn ? (
              <OutsideClickHandler
                onOutsideClick={() => {
                  setIsProfileDropdownOpen(false);
                }}
              >
                <figure onClick={toggleProfileDropdown} className="HeaderPImage">
                  <img
                    className="head-profile"
                    src={
                      userImage === null || userImage === "null"
                        ? avatarSmall
                        : userImage
                    }
                    alt="profile"
                    onError={handleError}
                      style={{
                        width: "45px",
                        height: "45px",
                        objectFit: "cover",
                      }}
                  />
                  <i className="fa fa-angle-down"></i>
                  <figcaption
                    className={`dropdown ${
                      isProfileDropdownOpen ? "show" : ""
                    }`}
                  >
                    <Link to="/trip-plan" onClick={toggleProfileDropdown}>
                      Create New Trip
                    </Link>
                    <Link to="/my-account" onClick={toggleProfileDropdown}>
                      My trips
                    </Link>
                    <Link
                      to="/my-account/settings"
                      onClick={toggleProfileDropdown}
                    >
                      Settings
                    </Link>
                    <Link to="/signin" onClick={logOutHandler}>
                      Logout
                    </Link>
                  </figcaption>
                </figure>
              </OutsideClickHandler>
            ) : (
              <Link className="header-btn" to="/signin">
                Login
              </Link>
            )}
          </div>
        </div>
      </header>
    </>
  );
};
