import React, { useState, useRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import { newTripActions } from "../../store/new-trip-slice";
import OutsideClickHandler from "react-outside-click-handler";

let activityTypes = [
  "Hiking",
  "Fishing",
  "Bicycling",
  "Camping",
  "Backpacking",
  "Day hiking",
  "Skiing",
  "Snowboarding",
  "Kayaking",
];

export const AddActivity = (props) => {
  const dispatch = useDispatch();

  let initialActivityIndex = 0;

  //Only if editing a trip, initialize the index with activity type, or manually entered activity.
  if (props.isEditing) {
    if (activityTypes.indexOf(props.activity) > -1) {
      initialActivityIndex = activityTypes.indexOf(props.activity);
    } else {
      activityTypes.push(props.activity);
      initialActivityIndex = activityTypes.indexOf(props.activity);
    }
  }

  const [isListOpen, setIsListOpen] = useState(false);
  const [selectedActivity, setSelectedActivity] =
    useState(initialActivityIndex);
  const [activityList, setActivityList] = useState(activityTypes);
  const activityRef = useRef();

  //For updating the activity first time only - and when not editing.
  useEffect(() => {
    if (!props.isEditing) {
      dispatch(newTripActions.updateActivity(activityTypes[0]));
      //storing in local storage also in case of page reload
      localStorage.setItem("activity", activityTypes[0]);
    }
  }, [dispatch]);

  const toggleActivityList = () => {
    setIsListOpen(!isListOpen);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      if (activityRef.current.value.trim() === "") {
        props.setEmptyActivity(true);
        return;
      }
      const newActivityValue = activityRef.current.value;
      activityRef.current.value = "";
      setActivityList((currentList) => {
        return [newActivityValue, ...currentList];
      });
      setIsListOpen(false);
      setSelectedActivity(0);
      if (props.isEditing) {
        props.setActivity(newActivityValue);
      } else {
        dispatch(newTripActions.updateActivity(newActivityValue));
        //storing in local storage also in case of page reload
        localStorage.setItem("activity", newActivityValue);
      }
      props.setEmptyActivity(false);
    }
  };

  return (
    <>
      <OutsideClickHandler
        onOutsideClick={() => {
          setIsListOpen(false);
        }}
      >
        <div className="field-wrapper">
          <button
            type="button"
            className={isListOpen ? "expanded" : ""}
            onClick={toggleActivityList}
          >
            <strong>Activity Type</strong>
            <span>{activityList[selectedActivity]}</span>
          </button>
          <div className={`items-wrapper ${isListOpen ? "show" : ""}`}>
            <AddItem
              handleKeyDown={handleKeyDown}
              addActivityRef={activityRef}
            />
            <ul
              className="list-style-none search-activity-drop"
              role="listbox"
              aria-activedescendant={activityList[selectedActivity]}
              tabIndex={-1}
            >
              {activityList.map((activityType, index) => {
                return (
                  <li
                    key={index}
                    id={index}
                    role="option"
                    aria-selected={selectedActivity === index}
                    tabIndex={0}
                    onClick={() => {
                      setSelectedActivity(index);
                      if (props.isEditing) {
                        props.setActivity(activityType);
                      } else {
                        dispatch(newTripActions.updateActivity(activityType));
                        localStorage.setItem("activity", activityType);
                      }
                      setIsListOpen(false);
                      props.setEmptyActivity(false);
                    }}
                  >
                    {activityType}
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </OutsideClickHandler>
    </>
  );
};

const AddItem = ({ addActivityRef, handleKeyDown }) => {
  return (
    <input
      type="text"
      className="form-control"
      placeholder="Add Your Own"
      ref={addActivityRef}
      onKeyDown={handleKeyDown}
    />
  );
};
