import React from 'react'

const ModalWithInfo = (props) => {
  return (
    <div className="confirm-modal">
      <div className="confirm-modal-content">
        <h2>{props.heading}</h2>
        <p>{props.message}</p>
        <section>
          <button
            className="signup-btn"
            onClick={() => {
              props.showModal(false);
            }}
          >
            Ok
          </button>
        </section>
      </div>
    </div>
  );
};

export default ModalWithInfo;
