import React, {useState} from 'react';
import useInput from '../../../hooks/use-input';
import './Modal.css';
import SpinnerButton from "../../../components/ui/SpinnerButton";

const SubscribeModal = ({isOpen, onClose, data, onSave, loading}) => {
    if (!isOpen) return null;

    const [planInfo, setPlanInfo] = useState(null);

    const {
        value: duration,
        valueChangeHandler: durationChangeHandler,
        inputBlurHandler: durationBlurHandler,
    } = useInput((value) => value.trim().length > 0);

    const showPlanInfo = (event) => {
        setPlanInfo(data?.variations?.find((x) => x.id === parseInt(event.target.value)));
    }

    return (
        <div className="confirm-modal">
            <div className="confirm-modal-content select-variation-modal">
                <div className="modal-header">
                    <h2>{data?.name}</h2>
                </div>
                <div className="modal-body sv-modal-body">
                    
                    <ul className="list-style-none flex flex-wrap items-start">
                        <li style={{width:'100%', textAlign:'start'}}>
                            <label style={{marginBottom:'5px', paddingLeft:'3px'}}><b>Select Variation</b></label>
                            <select className="number-list sv-number-list"
                                    value={duration}
                                    onChange={(event) => {
                                        durationChangeHandler(event)
                                        showPlanInfo(event)
                                    }}
                                    onBlur={durationBlurHandler}
                                    disabled={loading === 'pending'}
                            >
                                <option value=''>---Select---</option>
                                {data?.variations.map((v, i) => (
                                    <option value={v?.id} key={i}>{v?.duration} Month</option>
                                ))}
                            </select>
                        </li>
                    </ul>
                    {planInfo && (
                        <div className="select-variation-planInfo">
                            {/* <p>Duration: {planInfo?.duration}</p> */}
                            <span><b>Price:</b> ${planInfo?.price}</span>
                            <span><b>Discount (%):</b> {planInfo?.discount}%</span>
                            <span><b>Offer Price:</b> ${planInfo?.offer_price}</span>
                        </div>
                    )}
                    <hr/>
                    
                    <ul className="list-style-none flex flex-wrap items-start content-justify-end">
                        <li>
                            <button type="submit"
                                    className={`signup-btn submit-btn sv-btn ${(!duration || loading === 'pending') ? 'disabled-link' : ''}`}
                                    onClick={() => {
                                        onSave({
                                            subscription_plan_id: data?.id,
                                            variation_id: duration
                                        });
                                    }}
                            >
                                Subscribe{loading === "pending" && <SpinnerButton/>}
                            </button>
                        </li>
                    </ul>
                </div>
                {/* Footer */}
                <div className="modal-footer modal-footer-variation">
                    <button className={`signup-btn sv-btn-close ${(loading === 'pending') ? 'disabled-link' : ''}`}
                            onClick={onClose}
                    >
                        Close
                    </button>
                </div>
            </div>
        </div>
    );
};

export default SubscribeModal;
