import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import OutsideClickHandler from "react-outside-click-handler";
import info from "../../assets/images/dots.png";
import checkIcon from "../../assets/images/checked.png";
import cancelIcon from "../../assets/images/cancel.png";
import privateIcon from "../../assets/images/private.png";
import editIcon from "../../assets/images/edit.png";
import ModalWithState from "../../components/ui/ModalWithState";
import ModalWithInfo from "../../components/ui/ModalWithInfo";
import { changeTripStatus } from "../../lib/api";
import useHttp from "../../hooks/use-http";
import { deleteTrip } from "../../lib/api";
import { changeTripPublicStatus } from "../../lib/api";
import ApiErrors from "../../components/api-errors/ApiErrors";

const MyTripCardDropdown = (props) => {
  const navigate = useNavigate();
  const tripId = props.tripId;
  const tripStatus = props.tripStatus;
  const tripPublic = props.tripPublic;

  const { sendRequest, status, error } = useHttp(changeTripStatus);
  const {
    sendRequest: sendDeleteRequest,
    status: deleteStatus,
    error: deleteError,
  } = useHttp(deleteTrip);

  const {
    sendRequest: sendVisibilityRequest,
    status: visibilityStatus,
    error: visibilityError,
  } = useHttp(changeTripPublicStatus);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const [actionType, setActionType] = useState("");
  const [showActionModal, setShowActionModal] = useState(false);
  const [actionConfirmation, setActionConfirmation] = useState(false);
  const [showActionInfoModal, setShowActionInfoModal] = useState(false);

  const dropdownHandler = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  //This effect handles actions related to trips.
  useEffect(() => {
    //Because effect runs for the first time also - maybe a solution can be found for this?
    if (actionConfirmation) {
      if (
        actionType === "completed" ||
        actionType === "active" ||
        actionType === "canceled"
      ) {
        const requestData = {
          tripId,
          status: {
            status: actionType,
          },
        };
        sendRequest(requestData);
      } else if (actionType === "delete") {
        sendDeleteRequest(tripId);
      } else if (actionType === "makePrivate") {
        const requestData = {
          tripId,
          status: {
            public: "false",
          },
        };
        sendVisibilityRequest(requestData);
      } else if (actionType === "makePublic") {
        const requestData = {
          tripId,
          status: {
            public: "true",
          },
        };
        sendVisibilityRequest(requestData);
      }
    }
    setActionConfirmation(false);
  }, [
    actionConfirmation,
    actionType,
    sendDeleteRequest,
    sendVisibilityRequest,
    sendRequest,
    tripId,
  ]);

  useEffect(() => {
    // Errors can be handled here by ORing error, deleteError, visibilityError, and showing APIErrors.
    if (status === "completed" && error === null) {
      setShowActionInfoModal(true);
    }
    if (deleteStatus === "completed" && deleteError === null) {
      setShowActionInfoModal(true);
    }
    if (visibilityStatus === "completed" && visibilityError === null) {
      setShowActionInfoModal(true);
    }
  }, [
    status,
    error,
    deleteStatus,
    deleteError,
    visibilityStatus,
    visibilityError,
  ]);

  //Hide the info modal and re-render the listing.
  const infoModalConfirmationHandler = () => {
    setShowActionInfoModal(false);
    if (actionType === "delete") {
      navigate("/my-account", { replace: true });
    }
    props.refreshHandler();
  };

  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        setIsDropdownOpen(false);
      }}
    >
      <div className="info" onClick={dropdownHandler}>
        <img src={info} alt="info" />
        <div className={`info-detail ${isDropdownOpen ? "show" : ""}`}>
          <ul className="list-style-none">
            {/* Handle trip stauts check on trip edit page also */}
            {tripStatus === "active" && (
              <li>
                <Link to={`/my-account/update-trip/${tripId}`}>
                  <img src={editIcon} alt="" />
                  Edit trip
                </Link>
              </li>
            )}
            {tripStatus === "active" && (
              <li
                onClick={() => {
                  setActionType("completed");
                  setShowActionModal(true);
                }}
              >
                <img src={checkIcon} alt="" />
                Mark trip as completed
              </li>
            )}
            {tripStatus === "active" && (
              <li
                onClick={() => {
                  setActionType("canceled");
                  setShowActionModal(true);
                }}
              >
                <img src={cancelIcon} alt="" />
                Cancel trip
              </li>
            )}
            {(tripStatus === "canceled" || tripStatus === "completed") && (
              <li
                onClick={() => {
                  setActionType("active");
                  setShowActionModal(true);
                }}
              >
                <img src={cancelIcon} alt="" />
                Activate Trip
              </li>
            )}
            {tripStatus === "active" && tripPublic === "true" && (
              <li
                onClick={() => {
                  setActionType("makePrivate");
                  setShowActionModal(true);
                }}
              >
                <img src={privateIcon} alt="" />
                Make Trip Private
              </li>
            )}
            {tripStatus === "active" && tripPublic === "false" && (
              <li
                onClick={() => {
                  setActionType("makePublic");
                  setShowActionModal(true);
                }}
              >
                <img src={privateIcon} alt="" />
                Make Trip Public
              </li>
            )}

            <li
              onClick={() => {
                setActionType("delete");
                setShowActionModal(true);
              }}
            >
              <i className="fa fa-trash"></i>
              Delete Trip
            </li>
          </ul>
        </div>
      </div>
      {showActionModal && (
        <ModalWithState
          showModal={setShowActionModal}
          onConfirmModal={setActionConfirmation}
          message={
            (actionType === "completed" &&
              "Are you sure you would like to mark this trip as completed?") ||
            (actionType === "delete" &&
              "WARNING! Are you sure you would like to delete this trip?") ||
            (actionType === "active" &&
              "Are you sure you would like to activate this trip?") ||
            (actionType === "canceled" &&
              "Are you sure you would like to cancel this trip?") ||
            (actionType === "makePublic" &&
              "Are you sure you would like to make this trip public?") ||
            (actionType === "makePrivate" &&
              "Are you sure you would like to make this trip private?")
          }
        />
      )}
      {showActionInfoModal && (
        <ModalWithInfo
          showModal={infoModalConfirmationHandler}
          heading={"Success"}
          message={
            (actionType === "completed" &&
              "The trip is marked as completed.") ||
            (actionType === "delete" && "The trip is successfully deleted.") ||
            (actionType === "active" &&
              "The trip is successfully activated.") ||
            (actionType === "canceled" && "The trip is marked as canceled.") ||
            (actionType === "makePublic" &&
              "The trip's visibility is successfully changed to public. Anyone on the platform can access this trip by URL.") ||
            (actionType === "makePrivate" &&
              "The trip's visibility is now changed to private. Only you and the invited people can view this trip.")
          }
        />
      )}
      {/* Handling Errors */}
      {visibilityError && <ApiErrors>{visibilityError}</ApiErrors>}
      {deleteError && <ApiErrors>{deleteError}</ApiErrors>}
      {error && <ApiErrors>{error}</ApiErrors>}
    </OutsideClickHandler>
  );
};

export default MyTripCardDropdown;
