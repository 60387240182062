import React, { useEffect, useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import checkIcon from "../../../../assets/images/checked.png";
import info from "../../../../assets/images/dots.png";
import {
  adminDeleteGroupGearItem,
  adminDeletePersonalGearItem,
} from "../../../../lib/admin-api";
import useHttp from "../../../../hooks/use-http";
import ModalWithState from "../../../../components/ui/ModalWithState";
import AdminApiErrors from "../../../../components/api-errors/AdminApiErrors";

const AdminGearDropDownList = (props) => {
  const gearId = props.gearId;
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [actionConfirmation, setActionConfirmation] = useState(false);

  const { sendRequest, status, error } = useHttp(adminDeletePersonalGearItem);
  const {
    sendRequest: sendDeleteGroupGearRequest,
    status: deleteGroupGearStatus,
    error: deleteGroupGearError,
  } = useHttp(adminDeleteGroupGearItem);

  const dropdownHandler = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  useEffect(() => {
    if (actionConfirmation) {
      if (props.isGroupGear) {
        sendDeleteGroupGearRequest(gearId);
      } else {
        sendRequest(gearId);
      }
    }
  }, [actionConfirmation]);

  useEffect(() => {
    if (status === "completed" && error === null) {
      props.refreshHandler();
    }
    if (
      deleteGroupGearStatus === "completed" &&
      deleteGroupGearError === null
    ) {
      props.refreshHandler();
    }
  }, [status, error, deleteGroupGearStatus, deleteGroupGearError]);

  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        setIsDropdownOpen(false);
      }}
    >
      <div className="info" onClick={dropdownHandler}>
        <img src={info} alt="info" />
        <div className={`info-detail ${isDropdownOpen ? "show" : ""}`}>
          <span
            onClick={() => {
              setShowDeleteModal(true);
            }}
          >
            <img src={checkIcon} alt="" />
            Delete Item
          </span>
        </div>
      </div>
      {showDeleteModal && (
        <ModalWithState
          message={"Are you sure you would like to delete this Gear Item?"}
          onConfirmModal={setActionConfirmation}
          showModal={setShowDeleteModal}
        />
      )}
      {error && <AdminApiErrors>{error}</AdminApiErrors>}
      {deleteGroupGearError && (
        <AdminApiErrors>{deleteGroupGearError}</AdminApiErrors>
      )}
    </OutsideClickHandler>
  );
};

export default AdminGearDropDownList;
