import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./signIn.scss";
import useInput from "../../hooks/use-input";
import useHttp from "../../hooks/use-http";
import { loginUser } from "../../lib/api";
import SpinnerButton from "../../components/ui/SpinnerButton";
import { useDispatch } from "react-redux";
import { authActions } from "../../store/auth-slice";
import ApiErrors from "../../components/api-errors/ApiErrors";
import LoginWithGoogle from "../google/LoginWithGoogle";
import inspireLogo from "../../assets/images/inspirelogo.svg";
import "../../../src/pages/create-trip/tripInvitees.scss";

const SignIn = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [showApiErrors, setShowApiErrors] = useState(false);
  const { sendRequest, status, error, data } = useHttp(loginUser);

  const {
    value: enteredEmail,
    hasError: emailHasError,
    valueIsValid: emailIsValid,
    valueChangeHandler: emailChangeHandler,
    inputBlurHandler: emailBlurHandler,
  } = useInput((value) => value.includes("@"));

  const {
    value: enteredPassword,
    hasError: passwordHasError,
    valueIsValid: passwordIsValid,
    valueChangeHandler: passwordChangeHandler,
    inputBlurHandler: passwordBlurHandler,
  } = useInput((value) => value.trim().length > 7);

  const formIsValid = emailIsValid && passwordIsValid;

  const formSubmitHandler = (event) => {
    event.preventDefault();
    setShowApiErrors(false);

    if (formIsValid) {
      const userData = {
        email: enteredEmail,
        password: enteredPassword,
      };
      sendRequest(userData, true);
    } else {
      return;
    }
  };

  useEffect(() => {
    if (error) {
      setShowApiErrors(true);
    }

    if (status === "completed" && error === null) {
      dispatch(authActions.updateAuthToken(data));
      if (!!localStorage.getItem("location")) {
        navigate("/trip-plan/confirm-trip-details", { replace: true });
      } else {
        navigate("/trip-plan", { replace: true });
        //navigate("/my-account", { replace: true });
      }
    }

    const err = JSON.parse(error);
    if (err?.message === 'not_verified') {
      localStorage.setItem('verify_email', enteredEmail);
      navigate("/signup/verification", { replace: true });
    }
  }, [status, error, data, dispatch, navigate]);

  const toggleShowPasswordHandler = () => {
    setShowPassword((showPassword) => !showPassword);
  };

  return (
    <>
      <div className="main-trail-head sign-in signin-wrapper flex flex-wrap w-100 content-justify-center">
        <div className="container">
          <div className="signin-box">
            <Link to="/signin">
              <img src={inspireLogo} alt="logo" />
            </Link>
            <LoginWithGoogle />
            <span className="sign-divider">
              <small>OR</small>
            </span>
            <form onSubmit={formSubmitHandler}>
              <div className="phone-numbers">
                <ul className="list-style-none">
                  <li>
                    <div className="form-group">
                      <i className="fa fa-envelope emailIcon"></i>
                      <label>Email</label>
                      <input
                        type="email"
                        placeholder="Enter Email Address"
                        className="email-list"
                        id="email"
                        value={enteredEmail}
                        onBlur={emailBlurHandler}
                        onChange={emailChangeHandler}
                      />
                    </div>
                    {emailHasError && (
                      <p className="error-msg">Please enter a valid Email.</p>
                    )}
                  </li>
                  <li>
                    <div className="form-group">
                      <i className="fa fa-key keyIcon"></i>
                      <label>Password</label>
                      <div className="input-container">
                        <input
                          type={showPassword ? "text" : "password"}
                          placeholder="Password"
                          className="number-list"
                          id="password"
                          value={enteredPassword}
                          onBlur={passwordBlurHandler}
                          onChange={passwordChangeHandler}
                        />
                        <i
                          className={`fa ${
                            showPassword ? "fa-eye-slash" : "fa-eye"
                          }`}
                          onClick={toggleShowPasswordHandler}
                        ></i>
                      </div>
                    </div>
                    {passwordHasError && (
                      <p className="error-msg">
                        Please enter a password with at least 8 characters.
                      </p>
                    )}
                    <Link className="forgot" to="/forgot-password">
                      Forgot Password?
                    </Link>
                    <div className="clearfix"></div>
                  </li>
                  {showApiErrors && <ApiErrors>{error}</ApiErrors>}
                  <li>
                    <button
                      type="submit"
                      className={`signup-btn submit-btn ${
                        formIsValid ? "" : "disabled-link"
                      } ${status === "pending" ? "disabled-link" : ""}`}
                    >
                      Sign In {status === "pending" && <SpinnerButton />}
                    </button>
                  </li>
                </ul>
              </div>
            </form>
            <small className="already-account content-justify-center flex">
              <span>
                Don't have an account? <Link to="/signup">Sign Up</Link>
              </span>
            </small>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignIn;
