import { Outlet } from "react-router-dom";
import { UserHeader } from "../../components/header/UserHeader";
import { Footer } from "../../components/footer/Footer";
import React from 'react'

export const UserLayout = () => {
  return (
    <>
      <div className="user-layout">
        <UserHeader />
        <Outlet />
        <Footer />
      </div>
    </>
  );
};
